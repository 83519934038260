import {
    useState, useEffect
} from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import firebase from 'firebase'
import { Link } from 'react-router-dom'
import moment from 'moment';
import Swal from 'sweetalert2'
// import { getPagamento, getPagamentos } from '../service/MercadoPago'
// import { buscaArquivos } from '../service/WsConect'
import 'moment/locale/pt-br'
import Carousel from '../componentes/Carousel'
// import base64 from 'react-native-base64'
moment.locale('pt-br')

const mediaQuery = window.matchMedia('(max-width: 450px)');

export default function Home() {
    const [servicos] = useState({
        eventos: firebase.firestore().collection("eventos"),
        inscricoes: firebase.firestore().collection("inscricoes"),
        configuracoes: firebase.firestore().collection("configuracoes")
    })
    const [eventos, setEventos] = useState([])
    // const [configuracoes, setConfiguracoes] = useState({});

    useEffect(() => {
        // buscaArquivos().then(async(res)=>{
            
        //     const decoder = new TextDecoder('utf8');
        //     const text = decoder.decode(res);
        //     console.log(text)
        // })

        
        servicos.eventos
            .where('status', '==', 1)
            .get().then((snap) => {

                const date = new Date();
                const hoje = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
                const eventosFiltrados = [];
                const data = snap.docs.map(e => e.data());
                
                data.forEach((evento) => {
                    
                    const [ano, mes, dia] = evento.inicioEvento.split('-').map(Number);                            
                    const inicioEvento = Date.UTC(ano, mes - 1, dia);

                    if(hoje <= inicioEvento){
                        eventosFiltrados.push(evento);
                    }
                });
            
                setEventos(eventosFiltrados.sort((a,b) =>{
                    const [anoA, mesA, diaA] = a.inicioEvento.split('-').map(Number);                            
                    const inicioEventoA = Date.UTC(anoA, mesA - 1, diaA);

                    const [anoB, mesB, diaB] = b.inicioEvento.split('-').map(Number);                            
                    const inicioEventoB = Date.UTC(anoB, mesB - 1, diaB);
                    
                    return (hoje - inicioEventoB) - (hoje - inicioEventoA)
                } ));                

            })

            servicos.configuracoes.get().then((snap =>{
                const config = snap.docs.map(c => c.data())
                if(config[0]){
                    if(config[0]?.mensagemInfo?.exibir === 1){
                        setTimeout(() => {
                            mensagemInfo(config[0].mensagemInfo.mensagem);                       
                        }, 5000);

                    }
                }
            }))
    }, [])


    function mensagemInfo(msg) {
        Swal.fire({    title: 'Info',
        text: msg,
        icon: 'info',
        customClass: {
          content: 'swal-message' // Apply custom class to the content
        }})
    }

    function detalheEvento(id) {
        window.location.href = 'evento/' + id
    }

    // function resultados(id) {
    //     window.location.href = 'resultados/' + id
    // }

    return (
        <>
        <div className="body-wrapper blank-loder">
            <Header />
            <Carousel/>

            <div className="section_fullwidth">
                <div className="header-slider">
                    <div id="pnchr-rev-slider">
                        {/* <link
                            href="https://fonts.googleapis.com/css?family=Montserrat:800%2C600%7COpen+Sans:300%2C500%2C400%2C600"
                            rel="stylesheet" property="stylesheet" type="text/css" media="all">
                        </link> */}
                        <div id="rev_slider_1_1_wrapper" class="rev_slider_wrapper fullwidthbanner-container"
                            data-source="gallery"
                            style={{ margin: 0, backgroundColor: "#111111", padding: 0, marginTop: 0, marginBottom: 0 }}>
                            <div id="rev_slider_1_1" class="rev_slider fullwidthabanner" style={{ display: "none" }} data-version="5.4.8.1">
                                <ul>
                                    <li data-index="rs-1" data-transition="slideoverhorizontal" data-slotamount="default"
                                        data-hideafterloop="0" data-hideslideonmobile="off" data-easein="Power4.easeInOut"
                                        data-easeout="Power4.easeInOut" data-masterspeed="1000" data-rotate="0"
                                        data-fstransition="fade" data-fsmasterspeed="1500" data-fsslotamount="7"
                                        data-saveperformance="off" data-title="silde-1" data-param1="" data-param2=""
                                        data-param3="" data-param4="" data-param5="" data-param6="" data-param7=""
                                        data-param8="" data-param9="" data-param10="" data-description="">
                                        <img src="http://bagrov-dev.com/puncher-demo-1/wp-content/uploads/revslider/Home-1/pexels-photo-238636.jpeg"
                                            alt="" title="Home 1" data-bgposition="center center" data-bgfit="cover"
                                            data-bgrepeat="no-repeat" class="rev-slidebg" data-no-retina />

                                        <div class="tp-caption BigBold-Title   tp-resizeme" id="slide-1-layer-1"
                                            data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                            data-y="['middle','bottom','middle','bottom']"
                                            data-voffset="['-169','581','-179','355']" data-fontsize="['60','60','70','40']"
                                            data-lineheight="['70','70','70','40']" data-letterspacing="['0','','','']"
                                            data-width="['none','none','none','400']" data-height="none"
                                            data-whitespace="['nowrap','nowrap','nowrap','normal']" data-type="text"
                                            data-basealign="slide" data-responsive_offset="off"
                                            data-frames='[{"delay":500,"speed":1500,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"frame":"999","to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
                                            data-textAlign="['center','center','center','center']"
                                            data-paddingtop="[10,10,10,10]" data-paddingright="[0,0,0,0]"
                                            data-paddingbottom="[10,10,10,10]" data-paddingleft="[0,0,0,0]"
                                            style={{ zIndex: 5, whiteSpace: "nowrap", fontSize: 60, lineHeight: 70, color: "rgba(255,255,255,1)", letterSpacing: 0, fontFamily: "Montserrat" }}>
                                            PROCOMPETIDOR <br />seu parceiro de<br /> competição </div>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <main className="section_default">
                <div className="vc_row wpb_row vc_row-fluid">
                    <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                                <div className="js-carusel-wrapp clearfix">
                                    <div className="carusel-empty-space" style={{ height: 24 }}></div>
                                    <div className="pnchrOwlCarousel_5ff0a67aea27d  row"
                                        data-settings="pnchrOwlCarousel_5ff0a67aea27d">
                                                                            <div className="wpb_text_column wpb_content_element ">
                                    <div className="wpb_wrapper" style={{marginBottom:"15px"}}>
                                        <h3 style={{fontWeight:"bold"}}>PRÓXIMOS EVENTOS</h3>
                                    </div>
                                </div>
                                        {eventos.slice(0, 4).map((evento) =>

                                            <div id='card-destaque' key={evento.id} className="vc_col-sm-3 ">
                                                    <Link to="#" onClick={() => detalheEvento(evento.id)}>

                                                <div className="vizit-cart-item ">
                                                        <div className="vizit-cart-item__img">
                                                            <div className="vizit-cart-item__img-overlay"></div>
                                                            <div className="vizit-cart-item__img-overlay-share">
                                                                <p style={{ color: "#ffffff" }}>Clique aqui para saber mais, se cadastrar no evento e ver suas incrições</p>
                                                            </div><img
                                                                src={evento.avatar}
                                                                alt="" title="image-here" />
                                                            {/* <p>Clique na imagem para acessar o evento</p> */}
                                                        </div>
                                                    
                                                    <div className="vizit-cart-item__descr">
                                                        <div 
                                                        title={evento.nome}className="vizit-cart-item__title" style={{ color: "#121212" }}>
                                                            <i className="fa  fa-bookmark" aria-hidden="true"></i>
                                                                {mediaQuery.matches ?
                                                                evento.nome
                                                                :
                                                                `${evento.nome.substr(0, 46)}${evento.nome.substr(0, 46).length < 46 ? '' : '...'}`
                                                                }
                                                                </div>
                                                        <p><i className="fa fa-calendar" aria-hidden="true">
                                                            </i>
                                                            {evento.inicioEvento !== evento.fimEvento ? 
                                                            `De ${moment(evento.inicioEvento).format("DD/MM/YYYY")} a ${moment(evento.fimEvento).format("DD/MM/YYYY")}` : `Dia ${moment(evento.inicioEvento).format("DD/MM/YYYY")}` }
                                                            </p>
                                                        <p><i className="fa fa-map-marker" aria-hidden="true"></i>{evento.endereco.cidade} / {evento.endereco.uf}</p>

                                                    </div>
                                                </div>

                                                </Link>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="vc_row wpb_row vc_row-fluid vc_column-gap-30">
                    <div className="wpb_column vc_column_container vc_col-sm-4 vc_col-lg-offset-0 vc_col-lg-12 vc_col-md-offset-2 vc_col-md-12 vc_col-sm-offset-2 vc_col-xs-12">
                        <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                                <div className="wpb_text_column wpb_content_element ">
                                    <div className="wpb_wrapper" style={{marginTop:"10px"}}>
                                        <h3 style={{fontWeight:"bold"}}>EVENTOS FUTUROS</h3>
                                    </div>
                                </div>
                                <div className="vc_empty_space" style={{ height: 24 }}>
                                    <span className="vc_empty_space_inner"></span>
                                </div>
                                <div className="row" id='card-mini'>
                                    {/* {eventos.slice(offset, offset + perPage).map((evento) => */}
                                    {eventos.slice(4).map((evento) =>
                                    <>
                                            <Link to="#" onClick={() => detalheEvento(evento.id)}>

                                        <div key={evento.id} className="ratings-widget__item  vc_col-lg-4">
                                                <div className="ratings-widget__img js-hover-veil"><img
                                                    src={evento.avatar}
                                                    alt="" title="image here (2)" />
                                                </div>
                                         
                                            <div className="ratings-widget__details ">
                                                <div className="ratings-widget__details-title" title={evento.nome}><i className="fa  fa-bookmark" aria-hidden="true"></i> 
                                                    {mediaQuery.matches ?
                                                    evento.nome 
                                                    : 
                                                    `${evento.nome.substr(0, 45)} ${evento.nome.substr(0, 45).length < 45 ? '' : '...'}`
                                                }


                                                    </div>
                                                <div className="ratings-widget__details-cat"><i className="fa  fa-bookmark" aria-hidden="true"
                                                    // style={{ color: "#c21111" }}
                                                    ></i> {evento.modalidade}</div>
                                                <div className="ratings-widget__details-cat"><i className="fa  fa-calendar" aria-hidden="true"
                                                    // style={{ color: "#c21111" }}
                                                    ></i> 
                                                    { evento.inicioEvento !== evento.fimEvento ? 
                                                            ` De ${moment(evento.inicioEvento).format("DD/MM/YYYY")} a ${moment(evento.fimEvento).format("DD/MM/YYYY")}` : ` Dia ${moment(evento.inicioEvento).format("DD/MM/YYYY")}` }
                                                    </div>
                                                <div className="ratings-widget__details-cat"><i className="fa  fa-map-marker" aria-hidden="true"
                                                    // style={{ color: "#c21111" }}
                                                    ></i> 
                                                    {`${evento.endereco.cidade} / ${evento.endereco.uf}`.substr(0, 20)}{`${evento.endereco.cidade} / ${evento.endereco.uf}`.substr(0, 18).length < 18 ? '' : '...'}

                                                    </div>
                                            </div>
                                        </div>
                                        </Link>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* <div className="vc_column-inner " style={{marginTop:20}}>
                            <div className="wpb_wrapper">
                                <div className="wpb_text_column wpb_content_element ">
                                    <div className="wpb_wrapper">
                                        <h3>Eventos Realizados</h3>
                                    </div>
                                </div>
                                <div className="vc_empty_space" style={{ height: 24 }}>
                                    <span className="vc_empty_space_inner"></span>
                                </div>
                                <div className="row">
                                    {eventosOld.map((evento) =>
                                        <div key={evento.id} className="ratings-widget__item  vc_col-lg-6" style={{ marginTop: 10 }}>
                                            <Link to="#" onClick={() => detalheEvento(evento.id)}>
                                                <div className="ratings-widget__img js-hover-veil"><img
                                                    src={evento.avatar}
                                                    style={{ height: 120 }}
                                                    alt="" title="image here (2)" />
                                                </div>
                                            </Link>
                                            <div className="ratings-widget__details ">
                                                <div className="ratings-widget__details-title"><i className="fa  fa-bookmark" aria-hidden="true"
                                                    style={{ color: "#c21111" }}></i> {evento.nome.substr(0, 20)}...</div>
                                                <div className="ratings-widget__details-cat"><i className="fa  fa-bookmark" aria-hidden="true"
                                                    style={{ color: "#c21111" }}></i> {evento.modalidade}</div>
                                                <div className="ratings-widget__details-cat"><i className="fa  fa-calendar" aria-hidden="true"
                                                    style={{ color: "#c21111" }}></i> Dia {moment(evento.inicioEvento).format("DD/MM/YYYY")}</div>
                                                <div className="ratings-widget__details-cat"><i className="fa  fa-map-marker" aria-hidden="true"
                                                    style={{ color: "#c21111" }}></i> {evento.endereco.cidade} / {evento.endereco.uf}</div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div> */}
                    </div>
                    {/* <div className="wpb_column vc_column_container vc_col-sm-4 vc_col-lg-offset-0 vc_col-lg-4 vc_col-md-offset-6 vc_col-md-4 vc_hidden-md vc_hidden-sm vc_hidden-xs">
                        <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                                <div className="wpb_widgetised_column wpb_content_element">
                                    <div className="wpb_wrapper">
                                        <div id="ratings-widget-25" className="widget widget_ratings-widget clearfix">
                                            <h3 className="widget__title">Resultados</h3>
                                            {eventosOld.filter((d) => d.resultados === 1).map((evento) =>
                                                <div className="ratings-widget__item widget-item">
                                                    <div className="ratings-widget__img js-hover-veil"><a
                                                        href={evento.avatar}><img
                                                            src={evento.avatar}
                                                            alt="" title="image here (2)" />
                                                        <div className="js-veil veil" ></div>
                                                    </a></div>
                                                    <div className="ratings-widget__details ">
                                                        <div className="ratings-widget__details-title">
                                                            <h5>{evento.nome.substr(0, 40)}...</h5>
                                                        </div>
                                                        <div className="ratings-widget__details-cat">
                                                            <a onClick={() => resultados(evento.id)} href='javascript:void(0)' >Ver Resultados</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {eventos.filter((d) => d.resultados === 1).length === 0 ?
                                                <h5>Nenhum resultado disponível ainda</h5>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </main>
            <Footer />
        </div>
        </>
    );
}
