import {
    useState, useEffect
} from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import moment from 'moment'
import firebase from 'firebase'
import Swal from 'sweetalert2'
import ModalEquipes from '../componentes/ModalEquipes'

export default function EditaInscricaoAluno() {
    const [servicos] = useState({
        eventos: firebase.firestore().collection("eventos"),
        equipes: firebase.firestore().collection("equipes"),
        usuarios: firebase.firestore().collection("usuarios"),
        inscricoes: firebase.firestore().collection('inscricoes'),
        auth: firebase.auth()
    })
    const [id] = useState(window.location.pathname.replace('/editaInscricaoAluno/', ''))
    const [validaLote, setValidaLote] = useState(false)
    const [equipe, setEquipe] = useState({})
    const [professor, setProfessor] = useState({})
    const [inputProfessor, setInputProfessor] = useState('')
    const [dataNascimento, setDataNascimento] = useState('')
    const [evento, setEvento] = useState({})
    const [usuario, setUsuario] = useState({})
    const [usuarioProfessor, setUsuarioProfessor] = useState({})
    const [competidores, setCompetidores] = useState([])
    const [inscricao, setInscricao] = useState({})

    const [categorias, setCategorias] = useState([])
    const [listaAbsolutos, setListaAbsolutos] = useState([])
    const [validaAbsolutos, setValidaAbsolutos] = useState(false)
    const [absolutos, setAbsolutos] = useState([])
    const [absoluto, setAbsoluto] = useState([])
    const [pesos, setPesos] = useState([])
    const [faixas, setFaixas] = useState([])

    const [categoria, setCategoria] = useState({})
    const [peso, setPeso] = useState({})
    const [faixa, setFaixa] = useState({})

    const [escolheAbsoluto, setEscolheAbsoluto] = useState('')
    const [voucher, setVoucher] = useState('')
    const [itemVoucher, setItemVoucher] = useState({})
    const [loading, setLoading] = useState(true)

    const [valorCategoria, setValorCategoria] = useState(0)
    const [valorAbsoluto, setValorAbsoluto] = useState(0)

    // const [equipes, setEquipes] = useState([])
    const [professores, setProfessores] = useState([])

    const [inscricoes, setInscricoes] = useState([]);

    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        servicos.auth.onAuthStateChanged((user) => {
            let idUser = JSON.parse(localStorage.getItem('usuarioId'))
            if (user) {

                servicos.usuarios.doc(user.uid).get().then(snap => setUsuarioProfessor(snap.data()));

                servicos.inscricoes.doc(id).get().then((inc) => {

                    servicos.inscricoes
                    .where('evento.id', '==', inc.data().evento.id)
                    .where('usuario.id', '==', inc.data().usuario.id)
                    .get().then(snap =>{
                        const data = snap.docs.map(d => d.data());
                        setInscricoes(data);
                    })

                    setInscricao(inc.data())
                    if (inc.data().usuario.id === user.uid) {
                        servicos.usuarios.doc(user.uid).get().then((d) => {
                            setUsuario(d.data())
                            servicos.eventos.doc(inc.data().evento.id).get().then((ev)=>{
                                var date2 = moment(d.data().dataNascimento).format('YYYY')
                                var date1 = moment(ev.data().inicioEvento).format('YYYY')
    
                                var data1 = moment(date1, 'YYYY');
                                var data2 = moment(date2, 'YYYY');
                                setDataNascimento(data1.diff(data2, 'years'))
    
                                // Carrega Equipe
                                if (d.data().equipe) {
                                    servicos.equipes.doc(d.data().equipe).get().then((e) => {
                                        setEquipe(e.data())
                                    })
    
                                    // Carrega Professor
                                    if(d.data().professor){
                                        servicos.equipes.doc(d.data().equipe).collection('professores').doc(d.data().professor).get().then((e) => {
                                            setProfessor(e.data())
                                        })
                                    }

                                     servicos.equipes.doc(d.data().equipe).collection('professores').get().then(snapProfessores =>{
                                        const dataProfessores = snapProfessores.docs.map((p) => p.data())
                                        setProfessores(dataProfessores)
                                     })

                                }
    
                                // Carrega Categoria
                                const lista = [];
                                servicos.eventos.doc(inc.data().evento.id).collection('categorias').where('sexo', '==', d.data().sexo).onSnapshot((snap) => {
                                    const data = snap.docs.map(d => d.data())
                                    data.forEach((c) => {
                                        if (data1.diff(data2, 'years') >= 18) {
                                            if (c.tipoIdade === 0) {
                                                if (data1.diff(data2, 'years') <= c.idade) {
                                                    lista.push(c)
                                                }
                                            } else if (c.tipoIdade === 1) {
                                                if (c.minIdade >= 18 && data1.diff(data2, 'years') >= c.minIdade) {
                                                    console.log(c.minIdade)
                                                    lista.push(c)
                                                }
                                            } else {
                                                if (data1.diff(data2, 'years') > c.idade) {
                                                    lista.push(c)
                                                }
                                            }
                                        } else {
                                            if (c.tipoIdade === 0) {
                                                if (data1.diff(data2, 'years') <= c.idade) {
                                                    lista.push(c)
                                                }
                                            } else if (c.tipoIdade === 1) {
                                                if (data1.diff(data2, 'years') >= c.minIdade && data1.diff(data2, 'years') <= c.maxIdade) {
                                                    lista.push(c)
                                                }
                                            } else {
                                                if (data1.diff(data2, 'years') > c.idade) {
                                                    lista.push(c)
                                                }
                                            }
                                        }
                                    })
                                    setCategorias(lista);
                                })
                            })
                            

                            // Carrega Absoluto
                            servicos.eventos.doc(inc.data().evento.id)
                                .collection('absolutos')
                                .where('sexo', '==', d.data().sexo)
                                .onSnapshot((snap) => {
                                    const data = snap.docs.map(d => d.data())
                                    setListaAbsolutos(data);
                                })
                            setLoading(false)
                            // servicos.usuarios.doc(user.uid).collection('competidores').doc(inc.data().usuario.id).get().then((comp) => {


                            // })
                            servicos.eventos.doc(inc.data().evento.id).get().then((snap) => {
                                setEvento(snap.data())
                            })
                        })
                    } else {
                        servicos.usuarios.doc(user.uid).collection('competidores').doc(inc.data().usuario.id).get().then((comp) => {
                            setUsuario(comp.data())
                            servicos.eventos.doc(inc.data().evento.id).get().then((ev)=>{
                                var date2 = moment(comp.data().dataNascimento).format('YYYY')
                                var date1 = moment().format('YYYY')
    
                                var data1 = moment(date1, 'YYYY');
                                var data2 = moment(date2, 'YYYY');
                                setDataNascimento(data1.diff(data2, 'years'))
    
                                // Carrega Equipe
                                if(comp.data().equipe){
                                    servicos.equipes.doc(comp.data().equipe).get().then((e) => {
                                        setEquipe(e.data())
                                    })
        
                                    if(comp.data().professor){
                                    // Carrega Professor
                                        servicos.equipes.doc(comp.data().equipe).collection('professores').doc(comp.data().professor).get().then((e) => {
                                            setProfessor(e.data())
                                        })
                                            }

                                    servicos.equipes.doc(comp.data().equipe).collection('professores').get().then(snapProfessores =>{
                                        const dataProfessores = snapProfessores.docs.map((p) => p.data())
                                        setProfessores(dataProfessores)
                                        })
                                }

    
                                // Carrega Categoria
                                const lista = [];
                                servicos.eventos.doc(inc.data().evento.id).collection('categorias').where('sexo', '==', comp.data().sexo).onSnapshot((snap) => {
                                    const data = snap.docs.map(d => d.data())
                                    data.forEach((c) => {
                                        if (data1.diff(data2, 'years') >= 18) {
                                            if (c.tipoIdade === 0) {
                                                if (data1.diff(data2, 'years') <= c.idade) {
                                                    lista.push(c)
                                                }
                                            } else if (c.tipoIdade === 1) {
                                                if (c.minIdade >= 18 && data1.diff(data2, 'years') >= c.minIdade) {
                                                    console.log(c.minIdade)
                                                    lista.push(c)
                                                }
                                            } else {
                                                if (data1.diff(data2, 'years') > c.idade) {
                                                    lista.push(c)
                                                }
                                            }
                                        } else {
                                            if (c.tipoIdade === 0) {
                                                if (data1.diff(data2, 'years') <= c.idade) {
                                                    lista.push(c)
                                                }
                                            } else if (c.tipoIdade === 1) {
                                                if (data1.diff(data2, 'years') >= c.minIdade && data1.diff(data2, 'years') <= c.maxIdade) {
                                                    lista.push(c)
                                                }
                                            } else {
                                                if (data1.diff(data2, 'years') > c.idade) {
                                                    lista.push(c)
                                                }
                                            }
                                        }
                                    })
                                    setCategorias(lista);
                                })
                            })
                            

                            // Carrega Absoluto
                            servicos.eventos.doc(inc.data().evento.id)
                                .collection('absolutos')
                                .where('sexo', '==', comp.data().sexo)
                                .onSnapshot((snap) => {
                                    const data = snap.docs.map(d => d.data())
                                    setListaAbsolutos(data);
                                })
                            setLoading(false)
                        })
                        servicos.eventos.doc(inc.data().evento.id).get().then((snap) => {
                            setEvento(snap.data())
                        })
                    }

                })
            } else if (idUser) {

                servicos.usuarios.doc(idUser).get().then(snap => setUsuarioProfessor(snap.data()));

                servicos.inscricoes.doc(id).get().then((inc) => {
                    setInscricao(inc.data())
                    if (inc.data().usuario.id === idUser) {
                        servicos.usuarios.doc(idUser).get().then((d) => {
                            setUsuario(d.data())
                            var date2 = moment(d.data().dataNascimento).format('YYYY')
                            var date1 = moment().format('YYYY')

                            var data1 = moment(date1, 'YYYY');
                            var data2 = moment(date2, 'YYYY');
                            setDataNascimento(data1.diff(data2, 'years'))

                            // Carrega Equipe
                            if (d.data().equipe) {
                                servicos.equipes.doc(d.data().equipe).get().then((e) => {
                                    setEquipe(e.data())
                                })

                                if(d.data().professor){
                                    // Carrega Professor
                                    servicos.equipes.doc(d.data().equipe).collection('professores').doc(d.data().professor).get().then((e) => {
                                        setProfessor(e.data())
                                    })
                                }

                                servicos.equipes.doc(d.data().equipe).collection('professores').get().then(snapProfessores =>{
                                    const dataProfessores = snapProfessores.docs.map((p) => p.data())
                                    setProfessores(dataProfessores)
                                 })

                            }

                            // Carrega Categoria
                            const lista = [];
                            servicos.eventos.doc(inc.data().evento.id).collection('categorias').where('sexo', '==', d.data().sexo).onSnapshot((snap) => {
                                const data = snap.docs.map(d => d.data())
                                data.forEach((c) => {
                                    if (data1.diff(data2, 'years') >= 18) {
                                        if (c.tipoIdade === 0) {
                                            if (data1.diff(data2, 'years') <= c.idade) {
                                                lista.push(c)
                                            }
                                        } else if (c.tipoIdade === 1) {
                                            if (c.minIdade >= 18 && data1.diff(data2, 'years') >= c.minIdade) {
                                                console.log(c.minIdade)
                                                lista.push(c)
                                            }
                                        } else {
                                            if (data1.diff(data2, 'years') > c.idade) {
                                                lista.push(c)
                                            }
                                        }
                                    } else {
                                        if (c.tipoIdade === 0) {
                                            if (data1.diff(data2, 'years') <= c.idade) {
                                                lista.push(c)
                                            }
                                        } else if (c.tipoIdade === 1) {
                                            if (data1.diff(data2, 'years') >= c.minIdade && data1.diff(data2, 'years') <= c.maxIdade) {
                                                lista.push(c)
                                            }
                                        } else {
                                            if (data1.diff(data2, 'years') > c.idade) {
                                                lista.push(c)
                                            }
                                        }
                                    }
                                })
                                setCategorias(lista);
                            })

                            // Carrega Absoluto
                            servicos.eventos.doc(inc.data().evento.id)
                                .collection('absolutos')
                                .where('sexo', '==', d.data().sexo)
                                .onSnapshot((snap) => {
                                    const data = snap.docs.map(d => d.data())
                                    setListaAbsolutos(data);
                                })
                            setLoading(false)
                            // servicos.usuarios.doc(idUser).collection('competidores').doc(inc.data().usuario.id).get().then((comp) => {


                            // })
                            servicos.eventos.doc(inc.data().evento.id).get().then((snap) => {
                                setEvento(snap.data())
                            })
                        })
                    } else {
                        servicos.usuarios.doc(idUser).collection('competidores').doc(inc.data().usuario.id).get().then((comp) => {
                            setUsuario(comp.data())
                            var date2 = moment(comp.data().dataNascimento).format('YYYY')
                            var date1 = moment().format('YYYY')

                            var data1 = moment(date1, 'YYYY');
                            var data2 = moment(date2, 'YYYY');
                            setDataNascimento(data1.diff(data2, 'years'))

                            // Carrega Equipe
                            if(comp.data().equipe){
                                servicos.equipes.doc(comp.data().equipe).get().then((e) => {
                                    setEquipe(e.data())
                                })
                                // Carrega Professor
                                if(comp.data().professor){
                                    servicos.equipes.doc(comp.data().equipe).collection('professores').doc(comp.data().professor).get().then((e) => {
                                        setProfessor(e.data())
                                    })  
                                }

                                // carregar lista de professores
                                servicos.equipes.doc(comp.data().equipe).collection('professores').get().then(snapProfessores =>{
                                    const dataProfessores = snapProfessores.docs.map((p) => p.data())
                                    setProfessores(dataProfessores)
                                 })
                              
                            }


                            // Carrega Categoria
                            const lista = [];
                            servicos.eventos.doc(inc.data().evento.id).collection('categorias').where('sexo', '==', comp.data().sexo).onSnapshot((snap) => {
                                const data = snap.docs.map(d => d.data())
                                data.forEach((c) => {
                                    if (data1.diff(data2, 'years') >= 18) {
                                        if (c.tipoIdade === 0) {
                                            if (data1.diff(data2, 'years') <= c.idade) {
                                                lista.push(c)
                                            }
                                        } else if (c.tipoIdade === 1) {
                                            if (c.minIdade >= 18 && data1.diff(data2, 'years') >= c.minIdade) {
                                                console.log(c.minIdade)
                                                lista.push(c)
                                            }
                                        } else {
                                            if (data1.diff(data2, 'years') > c.idade) {
                                                lista.push(c)
                                            }
                                        }
                                    } else {
                                        if (c.tipoIdade === 0) {
                                            if (data1.diff(data2, 'years') <= c.idade) {
                                                lista.push(c)
                                            }
                                        } else if (c.tipoIdade === 1) {
                                            if (data1.diff(data2, 'years') >= c.minIdade && data1.diff(data2, 'years') <= c.maxIdade) {
                                                lista.push(c)
                                            }
                                        } else {
                                            if (data1.diff(data2, 'years') > c.idade) {
                                                lista.push(c)
                                            }
                                        }
                                    }
                                })
                                setCategorias(lista);
                            })

                            // Carrega Absoluto
                            servicos.eventos.doc(inc.data().evento.id)
                                .collection('absolutos')
                                .where('sexo', '==', comp.data().sexo)
                                .onSnapshot((snap) => {
                                    const data = snap.docs.map(d => d.data())
                                    setListaAbsolutos(data);
                                })
                            setLoading(false)
                        })
                        servicos.eventos.doc(inc.data().evento.id).get().then((snap) => {
                            setEvento(snap.data())
                        })
                    }

                })
            }
        })
    }, [])

    const handleCategoria = (event) => {
        console.log(usuario)
        setCategoria({})
        setAbsoluto({})
        setPeso({})
        setFaixa({})
        setAbsolutos([])
        setPesos([])
        setFaixas([])
        setEscolheAbsoluto("")
        event.persist();
        if (event.target.value !== "") {
            mensagemLoading("Carregando informações")
            servicos.eventos.doc(evento.id).collection('lotes')
                .where('tipoCategoria', '==', 'categoria')
                .where('sexo', '==', usuario.sexo)
                .where('categoria.id', '==', event.target.value)
                .onSnapshot((snap) => {
                    if (snap.size > 0) {
                        const data = snap.docs.map(doc => doc.data())
                        const listaValor = [];
                        data.forEach((doc) => {
                            var dt1 = new Date(moment().format('YYYY-MM-DD'));
                            var dt2 = new Date(doc.vencimento);
                            var total = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));

                            if (total > -1) {
                                listaValor.push(doc.valor)
                            }
                        })
                        if (listaValor.length > 0) {
                            setValorAbsoluto(0)
                            setValorCategoria(Math.min(...listaValor))
                            setValidaLote(true)
                        } else {
                            mensagemErro("Lote vencido! Entre em contato com a administração")
                        }
                    } else {
                        servicos.eventos.doc(evento.id).collection('lotes')
                            .where('tipoCategoria', '==', 'categoria')
                            .where('sexo', '==', usuario.sexo)
                            .where('geral', '==', true)
                            .onSnapshot((snap) => {
                                if (snap.size > 0) {
                                    const data = snap.docs.map(doc => doc.data())
                                    const listaValor = [];
                                    data.forEach((doc) => {
                                        var dt1 = new Date(moment().format('YYYY-MM-DD'));
                                        var dt2 = new Date(doc.vencimento);
                                        var total = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
                                        if (total > -1) {
                                            listaValor.push(doc.valor)
                                        }
                                    })
                                    if (listaValor.length > 0) {
                                        setValorAbsoluto(0)
                                        setValorCategoria(Math.min(...listaValor))
                                        setValidaLote(true)
                                    } else {
                                        mensagemErro("Lote vencido! Entre em contato com a administração")
                                    }
                                }
                            })
                    }
                })
            servicos.eventos.doc(evento.id).collection("categorias").doc(event.target.value).get().then((snap) => {
                Swal.close()
                setCategoria(snap.data())
            })
            servicos.eventos.doc(evento.id).collection("categorias").doc(event.target.value).collection("pesos").onSnapshot((snap) => {
                const data = snap.docs.map(doc => doc.data())
                setPesos(data)
            })
            servicos.eventos.doc(evento.id).collection("categorias").doc(event.target.value).collection("faixas").onSnapshot((snap) => {
                const data = snap.docs.map(doc => doc.data())
                setFaixas(data)
            })
        }
    }

    const handleCompetidor = (event) => {
        setLoading(true)
        setUsuario({})
        event.persist();
        if (event.target.value !== "") {
            servicos.inscricoes
                .where("usuario.id", "==", competidores[event.target.value].id)
                .where("evento.id", "==", id)
                .get().then((snap) => {
                    if (snap.size > 0) {
                        mensagemAlerta("Este competidor já possui cadastro no Evento.")
                    } else {
                        setUsuario(competidores[event.target.value])

                        var date2 = moment(competidores[event.target.value].dataNascimento).format('YYYY')
                        var date1 = moment().format('YYYY')

                        var data1 = moment(date1, 'YYYY');
                        var data2 = moment(date2, 'YYYY');
                        setDataNascimento(data1.diff(data2, 'years'))

                        // Carrega Equipe
                        servicos.equipes.doc(competidores[event.target.value].equipe).get().then((e) => {
                            setEquipe(e.data())
                        })

                        // Carrega Professor
                        servicos.equipes.doc(competidores[event.target.value].equipe).collection('professores').doc(competidores[event.target.value].professor).get().then((e) => {
                            setProfessor(e.data())
                        })

                        // Carrega Categoria
                        const lista = [];
                        servicos.eventos.doc(evento.id).collection('categorias').where('sexo', '==', competidores[event.target.value].sexo).onSnapshot((snap) => {
                            const data = snap.docs.map(d => d.data())
                            data.forEach((c) => {
                                if (data1.diff(data2, 'years') >= 18) {
                                    if (c.tipoIdade === 0) {
                                        if (data1.diff(data2, 'years') <= c.idade) {
                                            lista.push(c)
                                        }
                                    } else if (c.tipoIdade === 1) {
                                        if (c.minIdade >= 18 && data1.diff(data2, 'years') >= c.minIdade) {
                                            console.log(c.minIdade)
                                            lista.push(c)
                                        }
                                    } else {
                                        if (data1.diff(data2, 'years') > c.idade) {
                                            lista.push(c)
                                        }
                                    }
                                } else {
                                    if (c.tipoIdade === 0) {
                                        if (data1.diff(data2, 'years') <= c.idade) {
                                            lista.push(c)
                                        }
                                    } else if (c.tipoIdade === 1) {
                                        if (data1.diff(data2, 'years') >= c.minIdade && data1.diff(data2, 'years') <= c.maxIdade) {
                                            lista.push(c)
                                        }
                                    } else {
                                        if (data1.diff(data2, 'years') > c.idade) {
                                            lista.push(c)
                                        }
                                    }
                                }
                            })
                            setCategorias(lista);
                        })

                        // Carrega Absoluto
                        servicos.eventos.doc(evento.id)
                            .collection('absolutos')
                            .where('sexo', '==', competidores[event.target.value].sexo)
                            .onSnapshot((snap) => {
                                const data = snap.docs.map(d => d.data())
                                setListaAbsolutos(data);
                            })
                        setLoading(false)
                    }
                })
        }
    }

    // const handleEquipe = (event) => {
    //     setProfessor('')
    //     event.persist();
    //     setEquipe(equipes.filter((d) => d.id === event.target.value)[0]);
    //     if (event.target.value !== '') {
    //         mensagemLoading('Carregando professores..')
    //         servicos.equipes.doc(event.target.value).collection('professores').onSnapshot((snap) => {
    //             const data = snap.docs.map((doc) => doc.data());
    //             setProfessores(data)
    //             Swal.close();
    //         })
    //     }
    // }

    const handleProfessor = (event) => {
        setInputProfessor("")
        event.persist();
        if (event.target.value === "nao") {
            setProfessor({
                id: "nao"
            })
        } else {
            setProfessor(professores[event.target.value])
        }

    }

    const handleInputProfessor = (event) => {
        event.persist();
        setInputProfessor(event.target.value)
    }

    const handlePeso = (event) => {
        setPeso({})
        setEscolheAbsoluto("")
        event.persist();
        if (event.target.value !== "") {
            servicos.eventos.doc(evento.id).collection("categorias").doc(categoria.id).collection("pesos").doc(event.target.value).get().then((snap) => {
                setPeso(snap.data())
            })
        }
    }

    const handleFaixa = (event) => {
        mensagemLoading("Carregando informações")
        setFaixa({})
        setEscolheAbsoluto("")
        event.persist();
        if (event.target.value !== "") {
            servicos.eventos.doc(evento.id).collection("categorias").doc(categoria.id).collection("faixas").doc(event.target.value).get().then((snap) => {
                Swal.close()
                setFaixa(snap.data())
            })

            const lista = []
            listaAbsolutos.forEach((a) => {
                
                servicos.eventos.doc(evento.id).collection("absolutos").doc(a.id)
                    .collection("idades")
                    .where("id", "==", categoria.id)
                    .get().then((i) => {
                        
                        if (i.size > 0) {
                            
                            servicos.eventos.doc(evento.id).collection("absolutos").doc(a.id)
                                .collection("faixas")
                                .where("idCategoria", "==", categoria.id)
                                .where("idFaixa", "==", event.target.value)
                                .get().then((i) => {
                                    console.log(i.size)
                                    if (i.size > 0) {
                                        lista.push(a)
                                        setValidaAbsolutos(true)
                                    }
                                })
                        }
                    })
            })
            setAbsolutos(lista)
        }
    }

    const handleEscolheAbsoluto = (event) => {
        event.persist();
        if (event.target.value !== "") {
            setEscolheAbsoluto(event.target.value)
            if (event.target.value === "0") {
                setValorAbsoluto(0)
                setAbsoluto({})
            }
        }
    }

    const handleAbsoluto = (event) => {
        event.persist();
        if (event.target.value !== "") {
            servicos.eventos.doc(evento.id).collection('lotes')
                .where('tipoCategoria', '==', 'absoluto')
                .where('sexo', '==', usuario.sexo)
                .where('categoria.id', '==', event.target.value)
                .onSnapshot((snap) => {
                    if (snap.size > 0) {
                        const data = snap.docs.map(doc => doc.data())
                        const listaValor = [];
                        data.forEach((doc) => {
                            var dt1 = new Date(moment().format('YYYY-MM-DD'));
                            var dt2 = new Date(doc.vencimento);
                            var total = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
                            if (total > -1) {
                                listaValor.push(doc.valor)
                            }
                        })
                        if (listaValor.length > 0) {
                            setValorAbsoluto(Math.min(...listaValor))
                        } else {
                            mensagemErro("Lote vencido! Entre em contato com a administração")
                        }
                    } else {
                        servicos.eventos.doc(evento.id).collection('lotes')
                            .where('tipoCategoria', '==', 'absoluto')
                            .where('sexo', '==', usuario.sexo)
                            .where('geral', '==', true)
                            .onSnapshot((snap) => {
                                if (snap.size > 0) {
                                    const data = snap.docs.map(doc => doc.data())
                                    const listaValor = [];
                                    data.forEach((doc) => {
                                        var dt1 = new Date(moment().format('YYYY-MM-DD'));
                                        var dt2 = new Date(doc.vencimento);
                                        var total = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
                                        if (total > -1) {
                                            listaValor.push(doc.valor)
                                        }
                                    })
                                    if (listaValor.length > 0) {
                                        setValorAbsoluto(Math.min(...listaValor))
                                    } else {
                                        mensagemErro("Lote vencido! Entre em contato com a administração")
                                    }
                                }
                            })
                    }
                })
            servicos.eventos.doc(evento.id).collection("absolutos").doc(event.target.value).get().then((doc) => {
                setAbsoluto(doc.data())
            })
        }
    }

    const handleVoucher = (event) => {
        event.persist();
        setVoucher(event.target.value)
        if (event.target.value.length === 9) {
            mensagemLoading("Buscando Voucher...")
            servicos.eventos.doc(evento.id).collection("vouches")
                .where("evento.id", "==", evento.id)
                .get().then((snap) => {
                    if (snap.size > 0) {
                        const data = snap.docs.map((d) => d.data())
                        data.forEach((c) => {
                            servicos.eventos.doc(evento.id).collection("vouches")
                                .doc(c.id)
                                .collection("cupons")
                                .where("status", "==", parseInt(0))
                                .where("voucher", "==", event.target.value)
                                .get().then((snapCupom) => {
                                    if (snapCupom.size > 0) {
                                        Swal.close();
                                        setItemVoucher(snapCupom.docs.map((d) => d.data())[0])
                                    } else {
                                        Swal.close();
                                        mensagemAlerta("Este Voucher não é válido!")
                                    }
                                })
                        })
                    } else {
                        Swal.close();
                        mensagemAlerta("Este Voucher não é válido!")
                    }
                })
        }
    }

    function verificaInscricao() {


        // Verificar Equipe e Professor
        if (!equipe.id) {
            mensagemAlerta('Por favor escolha a equipe que você irá lutar neste evento')
            return;
        }
        
        if (equipe.id && !professor.id && inputProfessor.trim() === '') {
                mensagemAlerta('Por favor informe seu professor')
                return
        }

        if (equipe.id && professores.length === 0) {
            if (!inputProfessor) {
                mensagemAlerta('Por favor digite o nome do seu professor')
                return
            }
        }
        if (equipe.id && professores.length > 0) {
            if (!professor.id) {
                mensagemAlerta('Por favor informe seu professor')
                return
            } else if (professor.id === "nao" && !inputProfessor) {
                mensagemAlerta('Por favor digite o nome do seu professor')
                return
            }
        }

        // * evitar que as alterações gerem duplicação de inscrição em caso de inscrições multiplas.
 
        if (inscricoes.length > 1) {

            const outrosPesosInscritos = inscricoes.filter(insc => insc.categoria.peso.id !== inscricao.categoria.peso.id);

            const pesoInscrito = outrosPesosInscritos.some(insc => insc.categoria.peso.id === peso.id);

            if (pesoInscrito) {
                mensagemAlerta('Você já possui uma inscrição para esta categoria de peso.');
                return;
            }

            const outrosAbsolutosInscritos = inscricoes
                .filter(insc =>
                    Object.keys(insc.categoria.absoluto).length > 0
                    && insc.categoria.absoluto.id !== inscricao.categoria.absoluto.id)
                .map(insc => insc.categoria.absoluto);

            if (outrosAbsolutosInscritos.some(abs => abs.id === absoluto.id)) {
                mensagemAlerta('Você já possui uma inscrição para esta categoria Absoluto');
                return;
            }

        }

        if (!categoria.id) {
            mensagemAlerta('Favor escolha sua categoria')
        } else if (!peso.id) {
            mensagemAlerta('Favor escolha a categoria de pesos')
        } else if (!faixa.id) {
            mensagemAlerta('Favor escolha a categoria de faixas')
        } else {
            if (escolheAbsoluto === '1') {
                if (!absoluto.id) {
                    mensagemAlerta('Favor escolha a categoria absoluto')
                } else {
                    Swal.fire({
                        title: 'Deseja gravar seus dados?',
                        text: 'Ao confirmar você estará ciente que sua inscrição será alterada e você terá que realizar o pagagmento para o ABSOLUTO',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não'
                    }).then((result) => {
                        if (result.value) {
                            cadastraInscricao()
                        }
                    })
                }
            } else {
                Swal.fire({
                    title: 'Deseja gravar seus dados?',
                    text: 'Ao confirmar você estará ciente que sua inscrição será alterada ',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Continuar Editando'
                }).then((result) => {
                    if (result.value) {
                        cadastraInscricao()
                    }
                })
            }
        }

    }

    async function cadastraInscricao() {
        const idChecagem = categoria.sexo + categoria.id + peso.id + faixa.id
        const idAbsoluto = absoluto.id ? absoluto.id + categoria.sexo : null
        mensagemLoading('Aguarde salvando dados da inscrição...')

        let professorIncluido = {}

        if(professor.id === 'nao' && inputProfessor.trim() === ''){
            mensagemAlerta('Por favor, informe seu professor');
            return;            
        }else if(equipe.id && professor.id === 'nao' && inputProfessor.trim().length > 0){

            const snapProfessor = await  servicos.equipes.doc(equipe.id).collection('professores').add({
                professor: inputProfessor
            });
           await servicos.equipes.doc(equipe.id).collection('professores').doc(snapProfessor.id).update({id:snapProfessor.id});
           
           professorIncluido = {id: snapProfessor.id}
        }

        console.log('Object.keys(professorIncluido).length > 0: ' , Object.keys(professorIncluido).length > 0);

        
        if(usuario.equipe !== equipe.id || usuario.professor !== professor.id){
                    
            let update = {}

            if(usuario.equipe !== equipe.id){
                update = {...update, equipe: equipe.id}
            }
            if(usuario.professor !== professor.id){
                update = {...update, professor: professor.id}
            }
            if(Object.keys(professorIncluido).length > 0){
                update = {...update, professor: professorIncluido.id}
            }

            if(usuarioProfessor.id === usuario.id){
                await servicos.usuarios.doc(usuario.id).update(update);
            } else if(usuarioProfessor.id !== usuario.id){
               await servicos.usuarios.doc(usuarioProfessor.id).collection('competidores').doc(usuario.id).update(update);
            }
        }
        
        servicos.inscricoes.doc(id).update({
            categoria: {
                categoria: categoria,
                peso: peso,
                faixa: faixa,
                idade: categoria,
                absoluto: absoluto.id ? absoluto : inscricao.categoria.absoluto,
            },
            equipe: {
                equipe: equipe.id ? equipe.id : inscricao.equipe.equipe,
                professor: Object.keys(professorIncluido).length > 0 ? professorIncluido.id : professor.id,
                nomeEquipe: equipe.equipe ? equipe.equipe : inscricao.equipe.equipe,
                nomeProfessor: Object.keys(professorIncluido).length > 0 ? inputProfessor : professor.professor,
                avatar: 'https://i.pinimg.com/736x/f2/53/b7/f253b7780d674ab21e4c94e83198dd67.jpg'
            },
            dataAtualizacao: moment().format('DD/MM/YYYY HH:mm'),
            posicaoChave: null,
            checagem: idChecagem,
            idAbsoluto: absoluto.id ? idAbsoluto : inscricao.idAbsoluto ? inscricao.idAbsoluto : null,
            pagamentoAbsoluto: {
                pedido: null,
                pagamento: null,
                status: absoluto.id && !inscricao.categoria.absoluto.id ? 'pending' : null,
                tipoPagamento: null,
                desconto: 0,
            },
            valorAbsoluto: absoluto.id ? valorAbsoluto : inscricao.valorAbsoluto,
            statusAbsoluto: absoluto.id && !inscricao.categoria.absoluto.id ? parseInt(1) : inscricao.statusAbsoluto,
        }).then(() => {
            mensagemSucesso('Inscrição atualizada com sucesso!!')
        }).catch(() => {
            mensagemErro('Erro ao atualizar a inscrição! Tente novamente mais tarde')
        })
    }

    function calculaValorTotal() {
        return valorCategoria + valorAbsoluto
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            if (itemVoucher.id && itemVoucher.tipo === 'cortesia') {
                window.location = window.location.href.replace('/editaInscricaoAluno/' + id, '/listaCompetidores/' + evento.id)
            } else {
                window.location = window.location.href.replace('/editaInscricaoAluno/' + id, '/listaCompetidores/' + evento.id)
            }

        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div className="body-wrapper blank-loder">
            <Header />
            <div class="section_fullwidth">
                <div class="headline headline_img">
                    <div class="headline__inner">
                        <div class="headline__title">
                            <h1>Inscrição de competidores no Evento</h1>
                        </div>
                        <div class="headline__description">
                            <span>Abaixo preencha o formulário para inscrever seus alunos ou filho(s)</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section_default section-single-class-layout clearfix">
                <main class="section_default">
                    <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
                        <div class="wpb_column vc_column_container vc_col-sm-12">
                            <div class="vc_column-inner ">
                                <div class="wpb_wrapper">
                                    <div class="wpb_text_column wpb_content_element ">
                                        <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                        </div>
                                        <div class=" vc_col-sm-4">
                                            <div class="wpb_single_image wpb_content_element vc_align_left">
                                                <figure class="wpb_wrapper vc_figure">
                                                    <div class="vc_single_image-wrapper   vc_box_border_grey">
                                                        <img width="1920" height="1401"
                                                            src={evento.avatar}
                                                            class="vc_single_image-img attachment-full" alt=""
                                                            srcset={evento.avatar}
                                                            sizes="(max-width: 1920px) 100vw, 1920px" /></div>
                                                </figure>
                                                <figure class="wpb_wrapper vc_figure">
                                                    <div class="package-style-1__img">
                                                        <img src={usuario.avatar}
                                                            alt="" title="price-img" /></div>
                                                    <div class="package-style-1__descr  package-style-1_with-img">
                                                        <h3 class="package-style-1__title" style={{ color: "#121212" }}>{usuario.nome} {usuario.sobrenome}</h3>
                                                        <div class="package-style-1__text">
                                                            <div class="package-style-1__text"><i class="fa  fa-file" aria-hidden="true"
                                                                style={{ color: "#c21111" }}></i> CPF {usuario.cpf} </div>
                                                            <div class="package-style-1__text"><i class="fa  fa-user" aria-hidden="true"
                                                                style={{ color: "#c21111" }}></i> {usuario.sexo}</div>
                                                        </div>
                                                    </div>
                                                </figure>
                                                <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix" style={{ marginTop: 20 }}>
                                                    <h3>{evento.nome}</h3>
                                                    <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix" style={{ marginTop: 20 }}>
                                                        <h3>Inscrição atual</h3>
                                                        {inscricao.categoria ?
                                                            <table style={{ width: "100%" }}>
                                                                <thead>
                                                                    <tr >
                                                                        <th class="schedule-table__day">Categoria</th>
                                                                        <th class="schedule-table__day">Peso</th>
                                                                        <th class="schedule-table__day">Faixa</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ height: 50 }}><h6>{inscricao.categoria.categoria.categoria}</h6></td>
                                                                        <td><h6>{inscricao.categoria.peso.tipoPeso === 0 ? "Até " + inscricao.categoria.peso.peso + " Kg" : "Acima " + inscricao.categoria.peso.peso + " Kg"}</h6></td>
                                                                        <td><h6>{inscricao.categoria.faixa.faixa}</h6></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            :
                                                            null
                                                        }
                                                        {inscricao.categoria && inscricao.categoria.absoluto.id ?
                                                            <table style={{ width: "100%", marginTop: 20 }}>
                                                                <thead>
                                                                    <tr >
                                                                        <th class="schedule-table__day">Absoluto</th>
                                                                        <th class="schedule-table__day">Idade</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ height: 50 }}><h6>{inscricao.categoria.absoluto.absoluto}</h6></td>
                                                                        <td><h6>{inscricao.categoria.categoria.tipoIdade === 0 ? "Até " + inscricao.categoria.categoria.idade + " anos" : inscricao.categoria.categoria.tipoIdade === 1 ? "De " + inscricao.categoria.categoria.minIdade + " até " + inscricao.categoria.categoria.maxIdade + " anos" : "Acima de " + inscricao.categoria.categoria.idade}</h6></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            :
                                                            null
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="wpb_column vc_column_container vc_col-sm-8">
                                            <div class="vc_column-inner ">
                                                <div class="wpb_wrapper">
                                                    <div class="wpb_widgetised_column wpb_content_element">
                                                        <div class="wpb_wrapper">
                                                            <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                                                <h3>Dados Pessoais</h3>
                                                            </div>
                                                            {usuarioProfessor.id ?
                                                                <p class="contact-form-name vc_col-sm-4">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Escolha seu competidor</h6>
                                                                        <select name="select" id="select" class="form-control" onChange={handleCompetidor} >
                                                                            <option value="">Escolha seu aluno </option>
                                                                            {competidores.map((c, index) =>
                                                                                <option value={index} >{c.nome} {c.sobrenome} - {c.cpf}</option>
                                                                            )}
                                                                        </select>
                                                                    </span>
                                                                </p>
                                                                :
                                                                null
                                                            }
                                                            <div role="form" class="row " >
                                                                <p class="contact-form-name vc_col-sm-4">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Nome</h6>
                                                                        <input
                                                                            type="text" name="your-name" size="40"
                                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                            aria-required="true" aria-invalid="false"
                                                                            value={usuario.id ? usuario.nome + " " + usuario.sobrenome : ""}
                                                                            disabled
                                                                            placeholder="Nome *" />
                                                                    </span>
                                                                </p>
                                                                <p class="contact-form-name vc_col-sm-3">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>CPF</h6>
                                                                        <input
                                                                            type="text" name="your-name" size="40"
                                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                            aria-required="true" aria-invalid="false"
                                                                            value={usuario.cpf}
                                                                            disabled
                                                                            placeholder="CPF *" />
                                                                    </span>
                                                                </p>
                                                                <p class="contact-form-name vc_col-sm-2">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Sexo</h6>
                                                                        <input
                                                                            type="text" name="your-name" size="40"
                                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                            aria-required="true" aria-invalid="false"
                                                                            value={usuario.sexo}
                                                                            disabled
                                                                            placeholder="Sexo *" />
                                                                    </span>
                                                                </p>
                                                                <p class="contact-form-name vc_col-sm-2">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Idade</h6>
                                                                        <input
                                                                            type="text" name="your-name" size="40"
                                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                            aria-required="true" aria-invalid="false"
                                                                            value={dataNascimento + " anos"}
                                                                            disabled
                                                                            placeholder="Idadde *" />
                                                                    </span>
                                                                </p>
                                                                {usuario.ddd ?
                                                                    <p class="contact-form-name vc_col-sm-2">
                                                                        <span class="wpcf7-form-control-wrap your-name">
                                                                            <h6>DDD</h6>
                                                                            <input
                                                                                type="text" name="your-name" size="40"
                                                                                class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                                aria-required="true" aria-invalid="false"
                                                                                value={usuario.ddd}
                                                                                disabled
                                                                                placeholder="DDD *" />
                                                                        </span>
                                                                    </p>
                                                                    :
                                                                    null
                                                                }
                                                                {/* <p class="contact-form-name vc_col-sm-3">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Sua Equipe</h6>
                                                                        <input
                                                                            type="text" name="your-name" size="40"
                                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                            aria-required="true" aria-invalid="false"
                                                                            value={equipe.equipe ? equipe.equipe.toUpperCase() : null}
                                                                            disabled
                                                                            placeholder="Equipe *" />
                                                                    </span>
                                                                </p>
                                                                <p class="contact-form-name vc_col-sm-4">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Seu Professor</h6>
                                                                        <input
                                                                            type="text" name="your-name" size="40"
                                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                            aria-required="true" aria-invalid="false"
                                                                            value={professor.professor ? professor.professor.toUpperCase() : null}
                                                                            disabled
                                                                            placeholder="Professor *" />
                                                                    </span>
                                                                </p> */}
                                                                <p class="contact-form-name vc_col-sm-3">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Sua Equipe</h6>
                                                                        <span class="wpcf7-form-control-wrap your-name">
                                                                        <input type='text' name="select" 
                                class="form-control"
                                value={equipe?.equipe ? equipe.equipe : ''}
                                placeholder='Selecione uma equipe'
                                autoComplete='no-fill'
                                style={{cursor:"pointer"}}
                                    onClick={() => setModalShow(true)}
                                />                                                                                   

                                                                            </span>

                                                                    </span>
                                                                </p>
                                                                <p class="contact-form-name vc_col-sm-4">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Seu Professor</h6>
                                                                        <span class="wpcf7-form-control-wrap your-name">
                            <select name="select" id="select" class="form-control" onChange={handleProfessor}>
                                <option value="">Escolha o Professor </option>
                                <option value="nao">Não está na lista </option>
                                {professores.sort((a, b) => {
                                    if (a.professor < b.professor)
                                        return -1;
                                    if (a.professor > b.professor)
                                        return 1;
                                }).map((lProfessor, index) =>
                                    <option value={index} selected={ professor?.id === lProfessor?.id } >{lProfessor.professor.toUpperCase()}</option>
                                )}
                            </select>
                        </span>
                                                                    </span>
                                                                </p>
                                                                {professor?.id === "nao" &&
                                                                    (<p class="contact-form-name vc_col-sm-6">
                                                                        <span class="wpcf7-form-control-wrap your-name">
                                                                            <h6>Nome Professor</h6>
                                                                            <input
                                                                                type="text" name="your-name" size="40"
                                                                                class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                                aria-required="true" aria-invalid="false"
                                                                                value={inputProfessor}
                                                                                onChange={handleInputProfessor}
                                                                                placeholder="Nome do Professor *" />
                                                                        </span>
                                                                    </p>)

                                                                }                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="vc_column-inner">
                                                <div class="wpb_wrapper">
                                                    <div class="wpb_widgetised_column wpb_content_element">
                                                        <div class="wpb_wrapper">
                                                            <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                                                <h3>Dados da Inscrição</h3>
                                                                <p><b>Selecione abaixo a Categoria, Peso, Faixa ou absoluto para realizar sua inscrição</b></p>
                                                            </div>
                                                            <div role="form" class="row " >
                                                                <p class="contact-form-name vc_col-sm-12">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Sua Categoria</h6>
                                                                        <select name="select" id="select" class="form-control" onChange={handleCategoria} >
                                                                            <option value="">Escolha a categoria </option>
                                                                            {categorias.sort((a, b) => {
                                                                                return parseInt(a.posicao) - parseInt(b.posicao)
                                                                            }).map((c) =>
                                                                                <option value={c.id} >CATEGORIA {c.categoria.toUpperCase()} - {c.tipoIdade === 0 ? "ATÉ " + c.idade + " ANOS" : c.tipoIdade === 1 ? "DE " + c.minIdade + " ATÉ " + c.maxIdade + " ANOS" : "ACIMA DE " + c.idade}</option>
                                                                            )}
                                                                        </select>
                                                                    </span>
                                                                </p>
                                                                <p class="contact-form-name vc_col-sm-6">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Peso</h6>
                                                                        <select name="select" id="select" class="form-control" onChange={handlePeso} >
                                                                            <option value="">Escolha o peso </option>
                                                                            {pesos.sort((a, b) => {
                                                                                return a.peso - b.peso
                                                                            }).map((c) =>
                                                                                <option value={c.id} >{c.nome.toUpperCase()} - {c.tipoPeso === 0 ? "ATÉ " + c.peso + " KG" : "ACIMA " + c.peso + " KG"}</option>
                                                                            )}
                                                                        </select>
                                                                    </span>
                                                                </p>
                                                                <p class="contact-form-name vc_col-sm-6">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Faixa</h6>
                                                                        <select name="select" id="select" class="form-control" onChange={handleFaixa} >
                                                                            <option value="">Escolha a faixa </option>
                                                                            {faixas.sort((a, b) => {
                                                                                return parseInt(a.posicao) - parseInt(b.posicao)
                                                                            }).map((f) =>
                                                                                <option value={f.id} >{f.faixa.toUpperCase()}</option>
                                                                            )}
                                                                        </select>
                                                                    </span>
                                                                </p>
                                                                {faixa.id && validaAbsolutos ?
                                                                    <p class="contact-form-name vc_col-sm-2">
                                                                        <span class="wpcf7-form-control-wrap your-name">
                                                                            <h6>Absoluto?</h6>
                                                                            <select name="select" id="select" class="form-control" onChange={handleEscolheAbsoluto} >
                                                                                <option value="">Escolha abaixo </option>
                                                                                <option value="1">SIM </option>
                                                                                <option value="0">NÃO </option>
                                                                            </select>
                                                                        </span>
                                                                    </p>
                                                                    :
                                                                    null
                                                                }
                                                                {escolheAbsoluto === "1" ?
                                                                    <p class="contact-form-name vc_col-sm-7">
                                                                        <span class="wpcf7-form-control-wrap your-name">
                                                                            <h6>Escolha o Absoluto</h6>
                                                                            <select name="select" id="select" class="form-control" onChange={handleAbsoluto} >
                                                                                <option value="">Escolha abaixo </option>
                                                                                {absolutos.map((f) =>
                                                                                    <option value={f.id} >{f.absoluto.toUpperCase()}</option>
                                                                                )}
                                                                            </select>
                                                                        </span>
                                                                    </p>
                                                                    :
                                                                    null
                                                                }
                                                                {categoria.id && peso.id && faixa.id ?
                                                                    <p class="contact-form-name vc_col-sm-3" >
                                                                        <span class="wpcf7-form-control-wrap your-name">
                                                                            <h6>Voucher</h6>
                                                                            <input
                                                                                type="text" name="your-name" size="40"
                                                                                class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                                aria-required="true" aria-invalid="false"
                                                                                onChange={handleVoucher}
                                                                                value={voucher}
                                                                                placeholder="Você possui Voucher? Digite aqui" />
                                                                        </span>
                                                                    </p>
                                                                    :
                                                                    null
                                                                }
                                                                {peso.id && faixa.id ?
                                                                    <table style={{ width: "100%" }}>
                                                                        <thead>
                                                                            <tr >
                                                                                <th class="schedule-table__day">Categoria</th>
                                                                                <th class="schedule-table__day">Peso</th>
                                                                                <th class="schedule-table__day">Faixa</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td style={{ height: 50 }}><h6>{categoria.categoria}</h6></td>
                                                                                <td><h6>{peso.tipoPeso === 0 ? "ATÉ " + peso.peso + " KG" : "ACIMA " + peso.peso + " KG"}</h6></td>
                                                                                <td><h6>{faixa.faixa.toUpperCase()}</h6></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    :
                                                                    null
                                                                }

                                                                {absoluto.id ?
                                                                    <table style={{ width: "100%", marginTop: 20 }}>
                                                                        <thead>
                                                                            <tr >
                                                                                <th class="schedule-table__day">Absoluto</th>
                                                                                <th class="schedule-table__day">Idade</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td style={{ height: 50 }}><h6>{absoluto.absoluto}</h6></td>
                                                                                <td><h6>{categoria.tipoIdade === 0 ? "ATÉ " + categoria.idade + " ANOS" : categoria.tipoIdade === 1 ? "DE " + categoria.minIdade + " ATÉ " + categoria.maxIdade + " ANOS" : "ACIMA DE " + categoria.idade}</h6></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    :
                                                                    null
                                                                }
                                                                {inscricao.id && !inscricao.categoria.absoluto.id && absoluto.id ?
                                                                    <>
                                                                        <p style={{ marginTop: 20 }}><b>Você está adicionando uma categoria absoluto. Com isso será cobrado o valor referente ao absoluto. </b></p>
                                                                        <table style={{ width: "100%", marginTop: 20 }}>
                                                                            <thead>
                                                                                <tr >
                                                                                    <th class="schedule-table__day">Extrato</th>
                                                                                </tr>
                                                                            </thead>
                                                                        </table>
                                                                        <table style={{ width: "100%" }}>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td style={{ height: 50 }}>Valor Absoluto</td>
                                                                                    <td>R$ {valorAbsoluto}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        {validaLote ?
                                                                            <div onClick={() => verificaInscricao()} style={{ width: "100%", marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill ">
                                                                                <input type="submit" value="Salvar e ir para pagamento" class="wpcf7-form-control wpcf7-pnchr_submit" />
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {categoria.id && peso.id && faixa.id ?
                                                                            <div onClick={() => verificaInscricao()} style={{ width: "100%", marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill ">
                                                                                <input type="submit" value="Atualizar Inscrição" class="wpcf7-form-control wpcf7-pnchr_submit" />
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                    </>
                                                                }
                                                                <div onClick={() => window.location = window.location.href.replace('/editaInscricaoAluno/' + inscricao.id, '/listaCompetidores/' + evento.id)} style={{ width: "100%", marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill ">
                                                                    <input type="submit" value="Sua lista de Competidores" class="wpcf7-form-control wpcf7-pnchr_submit" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>
            </div>
            <Footer />
            {modalShow && (
                <ModalEquipes isOpen={modalShow} setIsOpen={setModalShow} setEquipe={()=>{}} setDadosEquipe={setEquipe} setProfessores={setProfessores}
                setDadosProfessor={setProfessor}
                />
            )}  
        </div>
    );
}
