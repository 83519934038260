import {
    useState, useEffect
} from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import moment from 'moment'
import firebase from 'firebase'
import { getPagamento, getPagamentos, atualizaPagamento, criaPagamento } from '../service/MercadoPago'
import Swal from 'sweetalert2'

export default function Evento() {
    const [servicos] = useState({
        eventos: firebase.firestore().collection("eventos"),
        federacoes: firebase.firestore().collection('federacoes'),
        usuarios: firebase.firestore().collection("usuarios"),
        inscricoes: firebase.firestore().collection("inscricoes"),
        auth: firebase.auth()
    })
    const [usuario, setUsuario] = useState({})
    const queryParams = new URLSearchParams(window.location.search);
    const [idExt] = useState(queryParams.get('external_reference'))

    const [id] = useState(window.location.pathname.replace('/minhaInscricao/', ''))
    const [evento, setEvento] = useState({})
    // const [federacao, setFederacao] = useState({})
    const [dadosInscricao, setInscricao] = useState({})

    useEffect(() => {

        if (idExt) {
            servicos.inscricoes.doc(idExt).get().then((snap) => {
                if (snap.data().responsavel.id) {
                    servicos.usuarios.doc(snap.data().responsavel.id).get().then((u) => {
                        setUsuario(u.data())
                    })
                } else {
                    servicos.usuarios.doc(snap.data().usuario.id).get().then((u) => {
                        setUsuario(u.data())
                    })
                }
                mensagemLoading('Carregando Pagamentos..')
                servicos.eventos.doc(snap.data().evento.id).get().then((d) => {
                    setEvento(d.data())

                    // Carregar Federação Vinculada (se houver)
                    // if (d.data().federacaoVinculada) {
                    //     servicos.federacoes.doc(d.data().federacaoVinculada).get().then(snap => {
                    //         if (snap.exists) {
                    //             setFederacao(snap.data())
                    //         }
                    //     })
                    // }

                    if (snap.data().status !== 2) {
                        if (snap.data().voucher.id && snap.data().voucher.tipo === 'valor' && snap.data().valorTotal === 0) {
                            servicos.inscricoes.doc(snap.data().id).update({
                                status: parseInt(2)
                            })
                            servicos.inscricoes.doc(snap.data().id).get().then((d) => {
                                setInscricao(d.data())
                                Swal.close();
                            })
                        } else {
                            getPagamentos(idExt, d.data().chavePagamento).then((res) => {
                                if (res.results && res.results.length > 0) {
                                    res.results.forEach((r) => {
                                        if (r.status !== "cancelled" || r.status !== "rejected") {
                                            if (r.description.includes("ABSOLUTO")) {
                                                verificaCategoriaAbsoluto(r, snap.data())
                                            } else {
                                                verificaCategoria(r, snap.data())
                                            }
                                        } else {
                                            if (r.description.includes("ABSOLUTO")) {
                                                verificaCategoriaAbsoluto(r, snap.data())
                                            } else {
                                                verificaCategoria(r, snap.data())
                                            }
                                        }
                                    })

                                } else {
                                    Swal.close();
                                }
                            })
                        }

                    } else {
                        Swal.close()
                    }
                })


            })
        } else {
            servicos.inscricoes.doc(id).get().then((snap) => {
                if (snap.data().responsavel.id) {
                    servicos.usuarios.doc(snap.data().responsavel.id).get().then((u) => {
                        setUsuario(u.data())
                    })
                } else {
                    servicos.usuarios.doc(snap.data().usuario.id).get().then((u) => {
                        setUsuario(u.data())
                    })
                }
                mensagemLoading('Carregando Pagamentos ..')
                servicos.eventos.doc(snap.data().evento.id).get().then((d) => {
                    setEvento(d.data())
                    if (snap.data().status !== 2) {
                        if (snap.data().voucher.id && snap.data().voucher.tipo === 'valor' && snap.data().valorTotal === 0) {
                            servicos.inscricoes.doc(snap.data().id).update({
                                status: parseInt(2)
                            })
                            servicos.inscricoes.doc(snap.data().id).get().then((d) => {
                                setInscricao(d.data())
                                Swal.close();
                            })
                        } else {
                            getPagamentos(id, d.data().chavePagamento).then((res) => {
                                console.log(res)
                                if (res.results && res.results.length > 0) {
                                    res.results.forEach((r) => {
                                        if (r.status !== "cancelled" || r.status !== "rejected") {
                                            if (r.description.includes("ABSOLUTO")) {
                                                verificaCategoriaAbsoluto(r, snap.data())
                                            } else {
                                                verificaCategoria(r, snap.data())
                                            }
                                        } else {
                                            if (r.description.includes("ABSOLUTO")) {
                                                verificaCategoriaAbsoluto(r, snap.data())
                                            } else {
                                                verificaCategoria(r, snap.data())
                                            }
                                        }
                                    })

                                } else {
                                    Swal.close()
                                }
                            })
                        }

                    } else {
                        Swal.close()
                    }
                    // Verifica Absoluto

                    if (snap.data()?.statusAbsoluto && snap.data()?.statusAbsoluto !== 2 && snap.data()?.status !== 3 && snap.data()?.statusAbsoluto !== 3) {
                        getPagamentos(id, d.data().chavePagamento).then((res) => {
                            console.log(res)
                            if (res.results && res.results.length > 0) {
                                res.results.forEach((r) => {
                                    if (r.status !== "cancelled" || r.status !== "rejected") {
                                        if (r.description.includes("ABSOLUTO")) {
                                            verificaCategoriaAbsoluto(r, snap.data())
                                        }
                                    } else {
                                        if (r.description.includes("ABSOLUTO")) {
                                            verificaCategoriaAbsoluto(r, snap.data())
                                        }
                                    }
                                })
                                Swal.close()

                            } else {
                                Swal.close()
                            }
                        })
                    }

                })

                setInscricao(snap.data())
            })
        }
    }, [])

    function verificaCategoria(list, insc) {
        if (list.status === 'approved') {
            getPagamento(list.id, evento.chavePagamento).then((pag) => {
                servicos.inscricoes.doc(insc.id).update({
                    pagamento: {
                        status: pag.status,
                        barcode: pag.barcode ? pag.barcode.content : null,
                        pagamento: pag.id,
                        tipoPagamento: pag.payment_type_id,
                        vencimento: pag.date_of_expiration ? pag.date_of_expiration : '',
                    },
                    dataPagamento: pag.status === 'approved' ? moment().format('YYYY-MM-DD HH:mm') : '',
                    status: pag.status === 'approved' ? parseInt(2) : insc.status
                })
                servicos.inscricoes.doc(insc.id).get().then((d) => {
                    setInscricao(d.data())
                    Swal.close();
                })
            })
        } else {
            if (!insc.pagamento.pagamento && insc.statusAbsoluto < 2) {
                getPagamento(list.id, evento.chavePagamento).then((pag) => {
                    servicos.inscricoes.doc(insc.id).update({
                        pagamento: {
                            status: pag.status,
                            barcode: pag.barcode ? pag.barcode.content : null,
                            pagamento: pag.id,
                            tipoPagamento: pag.payment_type_id,
                            vencimento: pag.date_of_expiration ? pag.date_of_expiration : '',
                        },
                        dataPagamento: pag.status === 'approved' ? moment().format('YYYY-MM-DD HH:mm') : '',
                        status: pag.status === 'approved' ? parseInt(2) : pag.status === 'rejected' || pag.status === 'recused' || pag.status === 'cancelled' ? parseInt(3) : insc.status
                    })
                    servicos.inscricoes.doc(insc.id).get().then((d) => {
                        setInscricao(d.data())
                    })
                    Swal.close();
                })
            } else {
                servicos.inscricoes.doc(insc.id).get().then((d) => {
                    setInscricao(d.data())
                })
                Swal.close();
            }
        }
    }
    function verificaCategoriaAbsoluto(list, insc) {
        if(list.description.includes("ABSOLUTO")){
            if (list.status === 'approved') {
                getPagamento(list.id, evento.chavePagamento).then((pag) => {
                    servicos.inscricoes.doc(insc.id).update({
                        pagamentoAbsoluto: {
                            status: pag.status,
                            barcode: pag.barcode ? pag.barcode.content : null,
                            pagamento: pag.id,
                            tipoPagamento: pag.payment_type_id,
                            vencimento: pag.date_of_expiration ? pag.date_of_expiration : '',
                        },
                        dataPagamentoAbsoluto: pag.status === 'approved' ? moment().format('YYYY-MM-DD HH:mm') : '',
                        statusAbsoluto: pag.status === 'approved' ? parseInt(2) : insc.statusAbsoluto,
                        valorTotal: (parseFloat(insc.valorAbsoluto) + parseFloat(insc.valorTotal))
                    })
                    servicos.inscricoes.doc(insc.id).get().then((d) => {
                        setInscricao(d.data())
                    })
                    Swal.close();
                })
            } else {
                if (!insc.pagamentoAbsoluto.pagamento && insc.statusAbsoluto < 2) {
                    getPagamento(list.id, evento.chavePagamento).then((pag) => {
                        servicos.inscricoes.doc(insc.id).update({
                            pagamentoAbsoluto: {
                                status: pag.status,
                                barcode: pag.barcode ? pag.barcode.content : null,
                                pagamento: pag.id,
                                tipoPagamento: pag.payment_type_id,
                                vencimento: pag.date_of_expiration ? pag.date_of_expiration : '',
                            },
                            dataPagamentoAbsoluto: pag.status === 'approved' ? moment().format('YYYY-MM-DD HH:mm') : '',
                            statusAbsoluto: pag.status === 'approved' ? parseInt(2) : pag.status === 'rejected' || pag.status === 'recused' || pag.status === 'cancelled' ? parseInt(3) : insc.status
                        })
                        servicos.inscricoes.doc(insc.id).get().then((d) => {
                            setInscricao(d.data())
                        })
                        Swal.close();
                    })
                } else {
                    servicos.inscricoes.doc(insc.id).get().then((d) => {
                        setInscricao(d.data())
                    })
                    Swal.close();
                }
            }
        }

        Swal.close();

    }

    // Categoria
    function cancelaInscricaoCategoria() {
        Swal.fire({
            title: 'Cancelar Inscrição?',
            text: 'Ao confirmar, sua inscrição será excluída. Porém, é possível se inscrever no mesmo evento após cancelamento',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                if (dadosInscricao.status === 2 && dadosInscricao.valorTotal !== 0) {
                    mensagemAlerta('Entre em contato a administração para cancelar inscrição')
                } else {
                    mensagemLoading('Cancelando sua inscrição...')
                    servicos.inscricoes.doc(dadosInscricao.id).delete().then(() => {
                        Swal.close();
                        mensagemSucessoCancelamento('Inscrição Cancelada com sucesso!')
                    })
                }
            }
        })
    }

    function geraPagamento() {
        if (dadosInscricao.pagamento.pagamento) {
            if (dadosInscricao.pagamento.tipoPagamento === 'bank_transfer') {
                Swal.fire({
                    title: 'Pagamento Pendente',
                    text: 'Você possui um pagamento em aberto PIX',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Trocar Pagamento',
                    cancelButtonText: 'Não'
                }).then((result) => {
                    if (result.value) {
                        buscaLote()
                    }
                })
            } else if (dadosInscricao.pagamento.tipoPagamento === 'ticket') {
                Swal.fire({
                    title: 'Pagamento Pendente',
                    text: 'Você possui um pagamento em aberto Boleto',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Trocar Pagamento',
                    cancelButtonText: 'Não'
                }).then((result) => {
                    if (result.value) {
                        buscaLote()
                    }
                })
            } else if (dadosInscricao.pagamento.tipoPagamento === 'credit_card') {
                if (dadosInscricao.status === 3) {
                    servicos.inscricoes.doc(dadosInscricao.id).update({
                        pagamento: {
                            barcode: null,
                            pagamento: null,
                            status: null,
                            tipoPagamento: null,
                            vencimento: null
                        },
                        status: parseInt(1)
                    }).then(() => {
                        buscaLote()
                    })
                } else {
                    buscaLote()
                }
            }
        } else {
            buscaLote()
        }
    }

    function buscaLote(boleto) {
    
        const valorTotalNovoLoteCategoria = (valorNovoLoteCategoria) =>{
            
            let valor = valorNovoLoteCategoria + dadosInscricao.valorAbsoluto;

            if (dadosInscricao?.voucher.id) {
                if (dadosInscricao?.voucher?.porcentagem) {
                    valor = valor - (valor * parseFloat(dadosInscricao?.voucher?.porcentagem) / 100)
                }
                if (dadosInscricao?.voucher?.valor) {
                    valor = valor - parseFloat(dadosInscricao?.voucher?.valor)
                }
            }
            if (dadosInscricao?.descontoFiliacao?.descontoAplicado
            ) {
                if (dadosInscricao?.descontoFiliacao?.tipoDesconto === '1') {
                    valor -= (valor * (parseFloat(dadosInscricao?.descontoFiliacao?.valorDesconto) / 100))
                }
                if (dadosInscricao?.descontoFiliacao?.tipoDesconto === '2') {
                    valor -= parseFloat(dadosInscricao?.descontoFiliacao?.valorDesconto)
                }
            }
    
            if (dadosInscricao?.voucher?.tipo === 'cortesia' || valor <= 0) {
                valor = 0
            }
    
            return valor;
    }


        mensagemLoading('Recalculando valor...')
        if (dadosInscricao.categoria.absoluto.id) {
            let valorCat = 0
            let valorAbs = 0
            servicos.eventos.doc(evento.id).collection('lotes')
                .where('tipoCategoria', '==', 'categoria')
                .where('sexo', '==', dadosInscricao.usuario.sexo)
                .where('categoria.id', '==', dadosInscricao.categoria.categoria.id)
                .get().then((snap) => {
                    if (snap.size > 0) {
                        const data = snap.docs.map(doc => doc.data())
                        const listaValor = [];
                        data.forEach((doc) => {
                            var dt1 = new Date(moment().format('YYYY-MM-DD'));
                            var dt2 = new Date(doc.vencimento);
                            var total = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
                            console.log(total)
                            if (total > -1) {
                                listaValor.push(doc.valor)
                            }
                        })
                        if (listaValor.length > 0) {
                            if (Math.min(...listaValor) === dadosInscricao.valorCategoria) {
                                if (boleto) {
                                    geraBoleto(dadosInscricao.valorTotal)
                                } else {
                                    valorCat = Math.min(...listaValor)
                                    servicos.eventos.doc(evento.id).collection('lotes')
                                        .where('tipoCategoria', '==', 'absoluto')
                                        .where('sexo', '==', dadosInscricao.usuario.sexo)
                                        .where('geral', '==', true)
                                        .get().then((snap) => {
                                            if (snap.size > 0) {
                                                const data = snap.docs.map(doc => doc.data())
                                                const listaValorAbs = [];
                                                data.forEach((doc) => {
                                                    var dt1 = new Date(moment().format('YYYY-MM-DD'));
                                                    var dt2 = new Date(doc.vencimento);
                                                    var total = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));

                                                    if (total > -1) {
                                                        listaValorAbs.push(doc.valor)
                                                    }
                                                })
                                                if (listaValorAbs.length > 0) {
                                                    if (Math.min(...listaValorAbs) === dadosInscricao.valorAbsoluto && valorCat === dadosInscricao.valorCategoria) {
                                                        if (dadosInscricao.responsavel.id) {
                                                            window.location = window.location.href.replace('/minhaInscricao/', '/pagamentoAluno/')
                                                        } else {
                                                            window.location.href = `/pagamento?e=${evento.id}&i=${dadosInscricao.id}`
                                                        }
                                                    } else {
                                                        valorAbs = Math.min(...listaValorAbs)
                                                        servicos.inscricoes.doc(dadosInscricao.id).update({
                                                            valorCategoria: parseFloat(valorCat),
                                                            valorAbsoluto: parseFloat(valorAbs),
                                                            valorCategoriaOriginal: parseFloat(valorCat),
                                                            valorTotal: valorTotalNovoLoteCategoria(parseFloat(valorCat)),
                                                            valorTotalOriginal: valorTotalNovoLoteCategoria(parseFloat(valorCat))
                                                        }).then(() => {
                                                            if (boleto) {
                                                                geraBoleto(parseFloat(valorCat + valorAbs))
                                                            } else {
                                                                if (dadosInscricao.responsavel.id) {
                                                                    window.location = window.location.href.replace('/minhaInscricao/', '/pagamentoAluno/')
                                                                } else {
                                                                    window.location.href = `/pagamento?e=${evento.id}&i=${dadosInscricao.id}`
                                                                }
                                                            }
                                                        })
                                                    }
                                                } else {
                                                    mensagemAlerta("Lote vencido! Entre em contato com a administração")
                                                }
                                            }
                                        })
                                    // if (dadosInscricao.responsavel.id) {
                                    //     window.location = window.location.href.replace('/minhaInscricao/', '/pagamentoAluno/')
                                    // } else {
                                    //     window.location = window.location.href.replace('/minhaInscricao/' + dadosInscricao.id, '/pagamento/' + evento.id)
                                    // }
                                }
                            } else {
                                valorCat = Math.min(...listaValor)
                                servicos.eventos.doc(evento.id).collection('lotes')
                                    .where('tipoCategoria', '==', 'absoluto')
                                    .where('sexo', '==', dadosInscricao.usuario.sexo)
                                    .where('geral', '==', true)
                                    .get().then((snap) => {
                                        if (snap.size > 0) {
                                            const data = snap.docs.map(doc => doc.data())
                                            const listaValorAbs = [];
                                            data.forEach((doc) => {
                                                var dt1 = new Date(moment().format('YYYY-MM-DD'));
                                                var dt2 = new Date(doc.vencimento);
                                                var total = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
                                                if (total > -1) {
                                                    listaValorAbs.push(doc.valor)
                                                }
                                            })
                                            if (listaValorAbs.length > 0) {
                                                if (Math.min(...listaValorAbs) === dadosInscricao.valorAbsoluto && valorCat === dadosInscricao.valorCategoria) {
                                                    if (dadosInscricao.responsavel.id) {
                                                        window.location = window.location.href.replace('/minhaInscricao/', '/pagamentoAluno/')
                                                    } else {
                                                        window.location.href = `/pagamento?e=${evento.id}&i=${dadosInscricao.id}`
                                                    }
                                                } else {
                                                    valorAbs = Math.min(...listaValorAbs)
                                                    servicos.inscricoes.doc(dadosInscricao.id).update({
                                                        valorCategoria: parseFloat(valorCat),
                                                        valorAbsoluto: parseFloat(valorAbs),
                                                        valorCategoriaOriginal: parseFloat(valorCat),
                                                        valorTotal: valorTotalNovoLoteCategoria(parseFloat(valorCat)),
                                                        valorTotalOriginal: valorTotalNovoLoteCategoria(parseFloat(valorCat))
                                                    }).then(() => {
                                                        if (boleto) {
                                                            geraBoleto(parseFloat(valorCat + valorAbs))
                                                        } else {
                                                            if (dadosInscricao.responsavel.id) {
                                                                window.location = window.location.href.replace('/minhaInscricao/', '/pagamentoAluno/')
                                                            } else {
                                                                window.location.href = `/pagamento?e=${evento.id}&i=${dadosInscricao.id}`
                                                            }
                                                        }
                                                    })
                                                }
                                            } else {
                                                mensagemAlerta("Lote vencido! Entre em contato com a administração")
                                            }
                                        }
                                    })
                            }
                        } else {
                            servicos.eventos.doc(evento.id).collection('lotes')
                                .where('tipoCategoria', '==', 'categoria')
                                .where('sexo', '==', dadosInscricao.usuario.sexo)
                                .where('geral', '==', true)
                                .get().then((snap) => {
                                    if (snap.size > 0) {
                                        const data = snap.docs.map(doc => doc.data())
                                        const listaValor = [];
                                        data.forEach((doc) => {
                                            var dt1 = new Date(moment().format('YYYY-MM-DD'));
                                            var dt2 = new Date(doc.vencimento);
                                            var total = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
                                            if (total > -1) {
                                                listaValor.push(doc.valor)
                                            }

                                        })

                                        if (listaValor.length > 0) {
                                            if (Math.min(...listaValor) === dadosInscricao.valorCategoria) {
                                                if (boleto) {
                                                    geraBoleto(dadosInscricao.valorTotal)
                                                } else {
                                                    if (dadosInscricao.responsavel.id) {
                                                        window.location = window.location.href.replace('/minhaInscricao/', '/pagamentoAluno/')
                                                    } else {
                                                        window.location.href = `/pagamento?e=${evento.id}&i=${dadosInscricao.id}`
                                                    }
                                                }
                                            } else {
                                                valorCat = Math.min(...listaValor)
                                                servicos.eventos.doc(evento.id).collection('lotes')
                                                    .where('tipoCategoria', '==', 'absoluto')
                                                    .where('sexo', '==', dadosInscricao.usuario.sexo)
                                                    .where('geral', '==', true)
                                                    .get().then((snap) => {
                                                        if (snap.size > 0) {
                                                            const data = snap.docs.map(doc => doc.data())
                                                            const listaValorAbs = [];
                                                            data.forEach((doc) => {
                                                                var dt1 = new Date(moment().format('YYYY-MM-DD'));
                                                                var dt2 = new Date(doc.vencimento);
                                                                var total = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
                                                                if (total > -1) {
                                                                    listaValorAbs.push(doc.valor)
                                                                }
                                                            })

                                                            if (listaValorAbs.length > 0) {
                                                                if (Math.min(...listaValorAbs) === dadosInscricao.valorAbsoluto && valorCat === dadosInscricao.valorCategoria) {
                                                                    if (dadosInscricao.responsavel.id) {
                                                                        window.location = window.location.href.replace('/minhaInscricao/', '/pagamentoAluno/')
                                                                    } else {
                                                                        window.location.href = `/pagamento?e=${evento.id}&i=${dadosInscricao.id}`
                                                                    }
                                                                } else {
                                                                    valorAbs = Math.min(...listaValorAbs)
                                                                    servicos.inscricoes.doc(dadosInscricao.id).update({
                                                                        valorCategoria: parseFloat(valorCat),
                                                                        valorAbsoluto: parseFloat(valorAbs),
                                                                        valorCategoriaOriginal: parseFloat(valorCat),
                                                                        valorTotal: valorTotalNovoLoteCategoria(parseFloat(valorCat)),
                                                                        valorTotalOriginal: valorTotalNovoLoteCategoria(parseFloat(valorCat))
                                                                    }).then(() => {
                                                                        if (boleto) {
                                                                            geraBoleto(parseFloat(valorCat + valorAbs))
                                                                        } else {
                                                                            if (dadosInscricao.responsavel.id) {
                                                                                window.location = window.location.href.replace('/minhaInscricao/', '/pagamentoAluno/')
                                                                            } else {
                                                                                window.location.href = `/pagamento?e=${evento.id}&i=${dadosInscricao.id}`
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            } else {
                                                                mensagemAlerta("Lote vencido! Entre em contato com a administração")
                                                            }
                                                        }
                                                    })
                                            }
                                        } else {
                                            mensagemAlerta("Lote vencido! Entre em contato com a Guilherme")
                                        }
                                    }

                                })
                        }
                    } else {
                        servicos.eventos.doc(evento.id).collection('lotes')
                            .where('tipoCategoria', '==', 'categoria')
                            .where('sexo', '==', dadosInscricao.usuario.sexo)
                            .where('geral', '==', true)
                            .get().then((snap) => {
                                if (snap.size > 0) {
                                    const data = snap.docs.map(doc => doc.data())
                                    const listaValor = [];
                                    data.forEach((doc) => {
                                        var dt1 = new Date(moment().format('YYYY-MM-DD'));
                                        var dt2 = new Date(doc.vencimento);
                                        var total = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
                                        if (total > -1) {
                                            listaValor.push(doc.valor)
                                        }

                                    })

                                    if (listaValor.length > 0) {
                                        if (Math.min(...listaValor) === dadosInscricao.valorCategoria) {
                                            if (boleto) {
                                                geraBoleto(dadosInscricao.valorTotal)
                                            } else {
                                                if (dadosInscricao.responsavel.id) {
                                                    window.location = window.location.href.replace('/minhaInscricao/', '/pagamentoAluno/')
                                                } else {
                                                    window.location.href = `/pagamento?e=${evento.id}&i=${dadosInscricao.id}`
                                                }
                                            }
                                        } else {
                                            valorCat = Math.min(...listaValor)
                                            servicos.eventos.doc(evento.id).collection('lotes')
                                                .where('tipoCategoria', '==', 'absoluto')
                                                .where('sexo', '==', dadosInscricao.usuario.sexo)
                                                .where('geral', '==', true)
                                                .get().then((snap) => {
                                                    if (snap.size > 0) {
                                                        const data = snap.docs.map(doc => doc.data())
                                                        const listaValorAbs = [];
                                                        data.forEach((doc) => {
                                                            var dt1 = new Date(moment().format('YYYY-MM-DD'));
                                                            var dt2 = new Date(doc.vencimento);
                                                            var total = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
                                                            if (total > -1) {
                                                                listaValorAbs.push(doc.valor)
                                                            }
                                                        })

                                                        if (listaValorAbs.length > 0) {
                                                            if (Math.min(...listaValorAbs) === dadosInscricao.valorAbsoluto && valorCat === dadosInscricao.valorCategoria) {
                                                                if (dadosInscricao.responsavel.id) {
                                                                    window.location = window.location.href.replace('/minhaInscricao/', '/pagamentoAluno/')
                                                                } else {
                                                                    window.location.href = `/pagamento?e=${evento.id}&i=${dadosInscricao.id}`
                                                                }
                                                            } else {
                                                                valorAbs = Math.min(...listaValorAbs)
                                                                servicos.inscricoes.doc(dadosInscricao.id).update({
                                                                    valorCategoria: parseFloat(valorCat),
                                                                    valorAbsoluto: parseFloat(valorAbs),
                                                                    valorCategoriaOriginal: parseFloat(valorCat),
                                                                    valorTotal: valorTotalNovoLoteCategoria(parseFloat(valorCat)),
                                                                    valorTotalOriginal: valorTotalNovoLoteCategoria(parseFloat(valorCat))
                                                                }).then(() => {
                                                                    if (boleto) {
                                                                        geraBoleto(parseFloat(valorCat + valorAbs))
                                                                    } else {
                                                                        if (dadosInscricao.responsavel.id) {
                                                                            window.location = window.location.href.replace('/minhaInscricao/', '/pagamentoAluno/')
                                                                        } else {
                                                                            window.location.href = `/pagamento?e=${evento.id}&i=${dadosInscricao.id}`;
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                        } else {
                                                            mensagemAlerta("Lote vencido! Entre em contato com a administração")
                                                        }
                                                    }
                                                })
                                        }
                                    } else {
                                        mensagemAlerta("Lote vencido! Entre em contato com a Guilherme")
                                    }
                                }

                            })
                    }
                })
        } else {
            let valorCat = 0
            servicos.eventos.doc(evento.id).collection('lotes')
                .where('tipoCategoria', '==', 'categoria')
                .where('sexo', '==', dadosInscricao.usuario.sexo)
                .where('categoria.id', '==', dadosInscricao.categoria.categoria.id)
                .get().then((snap) => {
                    if (snap.size > 0) {
                        const data = snap.docs.map(doc => doc.data())
                        const listaValor = [];
                        data.forEach((doc) => {
                            var dt1 = new Date(moment().format('YYYY-MM-DD'));
                            var dt2 = new Date(doc.vencimento);
                            var total = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
                            if (total > -1) {
                                listaValor.push(doc.valor)
                            }
                        })
                        if (listaValor.length > 0) {
                            if (Math.min(...listaValor) === dadosInscricao.valorCategoria) {
                                if (boleto) {
                                    geraBoleto(dadosInscricao.valorTotal)
                                } else {
                                    if (dadosInscricao.responsavel.id) {
                                        window.location = window.location.href.replace('/minhaInscricao/', '/pagamentoAluno/')
                                    } else {
                                        window.location.href = `/pagamento?e=${evento.id}&i=${dadosInscricao.id}`
                                    }
                                }
                            } else {
                                valorCat = Math.min(...listaValor)
                                servicos.inscricoes.doc(dadosInscricao.id).update({
                                    valorCategoria: parseFloat(Math.min(...listaValor)),
                                    valorCategoriaOriginal: parseFloat(Math.min(...listaValor)),
                                    valorTotal: valorTotalNovoLoteCategoria(parseFloat(Math.min(...listaValor))),
                                    valorTotalOriginal: valorTotalNovoLoteCategoria(parseFloat(Math.min(...listaValor)))
                                }).then(() => {
                                    if (boleto) {
                                        geraBoleto(parseFloat(Math.min(...listaValor) + dadosInscricao.valorAbsoluto))
                                    } else {
                                        if (dadosInscricao.responsavel.id) {
                                            window.location = window.location.href.replace('/minhaInscricao/', '/pagamentoAluno/')
                                        } else {
                                            window.location.href = `/pagamento?e=${evento.id}&i=${dadosInscricao.id}`
                                        }
                                    }
                                })
                            }
                        } else {
                            servicos.eventos.doc(evento.id).collection('lotes')
                                .where('tipoCategoria', '==', 'categoria')
                                .where('sexo', '==', dadosInscricao.usuario.sexo)
                                .where('geral', '==', true)
                                .onSnapshot((snap) => {
                                    if (snap.size > 0) {
                                        const data = snap.docs.map(doc => doc.data())
                                        const listaValor = [];
                                        data.forEach((doc) => {
                                            var dt1 = new Date(moment().format('YYYY-MM-DD'));
                                            var dt2 = new Date(doc.vencimento);
                                            var total = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
                                            if (total > -1) {
                                                listaValor.push(doc.valor)
                                            }
                                        })
                                        if (listaValor.length > 0) {
                                            if (Math.min(...listaValor) === dadosInscricao.valorCategoria) {
                                                if (boleto) {
                                                    geraBoleto(dadosInscricao.valorTotal)
                                                } else {
                                                    if (dadosInscricao.responsavel.id) {
                                                        window.location = window.location.href.replace('/minhaInscricao/', '/pagamentoAluno/')
                                                    } else {
                                                        window.location.href = `/pagamento?e=${evento.id}&i=${dadosInscricao.id}`
                                                    }
                                                }
                                            } else {
                                                servicos.inscricoes.doc(dadosInscricao.id).update({
                                                    valorCategoria: parseFloat(Math.min(...listaValor)),
                                                    valorCategoriaOriginal: parseFloat(Math.min(...listaValor)),
                                                    valorTotal: valorTotalNovoLoteCategoria(parseFloat(Math.min(...listaValor))),
                                                    valorTotalOriginal: valorTotalNovoLoteCategoria(parseFloat(Math.min(...listaValor)))
                                                }).then(() => {
                                                    if (boleto) {
                                                        geraBoleto(parseFloat(Math.min(...listaValor) + dadosInscricao.valorAbsoluto))
                                                    } else {
                                                        if (dadosInscricao.responsavel.id) {
                                                            window.location = window.location.href.replace('/minhaInscricao/', '/pagamentoAluno/')
                                                        } else {
                                                            window.location.href = `/pagamento?e=${evento.id}&i=${dadosInscricao.id}`
                                                        }
                                                    }
                                                })
                                            }
                                        } else {
                                            mensagemAlerta("Lote vencido! Entre em contato com a administração")
                                        }
                                    } else {
                                        mensagemAlerta("Lote vencido! Entre em contato com a administração")
                                    }
                                })
                        }
                    } else {
                        servicos.eventos.doc(evento.id).collection('lotes')
                            .where('tipoCategoria', '==', 'categoria')
                            .where('sexo', '==', dadosInscricao.usuario.sexo)
                            .where('geral', '==', true)
                            .onSnapshot((snap) => {
                                if (snap.size > 0) {
                                    const data = snap.docs.map(doc => doc.data())
                                    const listaValor = [];
                                    data.forEach((doc) => {
                                        var dt1 = new Date(moment().format('YYYY-MM-DD'));
                                        var dt2 = new Date(doc.vencimento);
                                        var total = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
                                        if (total > -1) {
                                            listaValor.push(doc.valor)
                                        }
                                    })
                                    if (listaValor.length > 0) {
                                        if (Math.min(...listaValor) === dadosInscricao.valorCategoria) {
                                            if (boleto) {
                                                geraBoleto(dadosInscricao.valorTotal)
                                            } else {
                                                if (dadosInscricao.responsavel.id) {
                                                    window.location = window.location.href.replace('/minhaInscricao/', '/pagamentoAluno/')
                                                } else {
                                                    window.location.href = `/pagamento?e=${evento.id}&i=${dadosInscricao.id}`
                                                }
                                            }
                                        } else {
                                            
                                            servicos.inscricoes.doc(dadosInscricao.id).update({
                                                valorCategoria: parseFloat(Math.min(...listaValor)),
                                                valorCategoriaOriginal: parseFloat(Math.min(...listaValor)),
                                                valorTotal: valorTotalNovoLoteCategoria(parseFloat(Math.min(...listaValor))),
                                                valorTotalOriginal: valorTotalNovoLoteCategoria(parseFloat(Math.min(...listaValor)))
                                            }).then(() => {
                                                if (boleto) {
                                                    geraBoleto(parseFloat(Math.min(...listaValor) + dadosInscricao.valorAbsoluto))
                                                } else {
                                                    if (dadosInscricao.responsavel.id) {
                                                        window.location = window.location.href.replace('/minhaInscricao/', '/pagamentoAluno/')
                                                    } else {
                                                        window.location.href = `/pagamento?e=${evento.id}&i=${dadosInscricao.id}`
                                                    }
                                                }
                                            })
                                        }
                                    } else {
                                        mensagemAlerta("Lote vencido! Entre em contato com a administração")
                                    }
                                } else {
                                    mensagemAlerta("Lote vencido! Entre em contato com a administração")
                                }
                            })
                    }
                })

        }
        // if (dadosInscricao.voucher.id) {
        //     if (boleto) {
        //         geraBoleto(dadosInscricao.valorTotal)
        //     } else {
        //         if (dadosInscricao.responsavel.id) {
        //             window.location = window.location.href.replace('/minhaInscricao/', '/pagamentoAluno/')
        //         } else {
        //             window.location = window.location.href.replace('/minhaInscricao/' + dadosInscricao.id, '/pagamento/' + evento.id)
        //         }
        //     }
        // } else {

        // }
    }

    function segundadVia() {
        var venc = moment(moment(dadosInscricao.pagamento.vencimento.replace('T', ' ')).format('DD/MM/YYYY - HH:mm'), 'DD/MM/YYYY - HH:mm')
        var date1 = moment().format('DD/MM/YYYY')
        var data1 = moment(date1, 'DD/MM/YYYY');
        if (venc.diff(data1, 'day') > 0) {
            getPagamento(dadosInscricao.pagamento.pagamento, evento.chavePagamento).then((b) => {
                window.location = b.transaction_details.external_resource_url
            })
        } else {
            Swal.fire({
                title: 'Boleto Vencido',
                text: 'Seu boleto venceu. Você será redirecionado a tela de forma de pagamento',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ver Boleto',
                cancelButtonText: 'Não'
            }).then((result) => {
                if (result.value) {
                    buscaLote(true)
                }
            })
        }
    }

    function geraBoleto(valor) {
        const dado = {
            description: dadosInscricao.categoria.categoria.categoria.toUpperCase() + " - (" + dadosInscricao.evento.nome + ")",
            payment_method_id: 'bolbradesco',
            payer: {
                email: usuario.email,
                first_name: usuario.nome,
                last_name: usuario.sobrenome,
                identification: {
                    type: 'CPF',
                    number: usuario.cpf
                },
                address: {
                    zip_code: usuario.endereco.cep,
                    street_name: usuario.endereco.endereco,
                    street_number: usuario.endereco.numero ? usuario.endereco.numero : 0,
                    neighborhood: usuario.endereco.bairro,
                    city: usuario.endereco.cidade,
                    federal_unit: usuario.endereco.uf
                }
            },
            description: "PRO COMMPETIDOR",
            external_reference: dadosInscricao.id,
            transaction_amount: parseFloat(valor),
        }
        mensagemLoading("Gerando segunda via..")
        criaPagamento(dado, evento.chavePagamento).then((res) => {
            servicos.inscricoes.doc(dadosInscricao.id).update({
                pagamento: {
                    status: res.status,
                    barcode: res.barcode.content,
                    pagamento: res.id,
                    tipoPagamento: res.payment_type_id,
                    vencimento: res.date_of_expiration
                },
            }).then(() => {
                mensagemSucessoBoleto("Segunda Via de boleto gerado com sucesso! Você será direcionado(a) a tela de  inscrição para pegar o código de\n barras ou o PDF. \n Vencimento para " + moment(res.date_of_expiration).format("DD/MM/YYYY"), res.transaction_details.external_resource_url)
            })
        })
    }

    // Absoluto
    function cancelaInscricaoAbsoluto() {
        Swal.fire({
            title: 'Cancelar Inscrição Absoluto?',
            text: 'Ao confirmar, sua inscrição asboluto será excluída. Porém, é possível se inscrever novo absoluto após o cancelamento',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                if (dadosInscricao.statusAbsoluto === 2) {
                    mensagemAlerta('Entre em contato a administração para cancelar inscrição')
                } else {
                    mensagemLoading('Cancelando sua inscrição no absoluto...')
                    const d = {
                        metadata: {},
                        status: "cancelled",
                        transaction_amount: parseFloat(dadosInscricao.valorAbsoluto)
                    }
                    servicos.inscricoes.doc(idExt ? idExt : id).update({
                        categoria: {
                            absoluto: {},
                            categoria: dadosInscricao.categoria.categoria,
                            faixa: dadosInscricao.categoria.faixa,
                            idade: dadosInscricao.categoria.idade,
                            peso: dadosInscricao.categoria.peso,
                        },
                        pagamentoAbsoluto: {
                            status: null,
                            barcode: null,
                            pagamento: null,
                            tipoPagamento: null,
                            vencimento: null,
                        },
                        dataPagamentoAbsoluto: '',
                        statusAbsoluto: parseInt(0),
                        valorAbsoluto: parseInt(0),
                        valorTotal: dadosInscricao.valorTotalOriginal
                    })
                    servicos.inscricoes.doc(idExt ? idExt : id).get().then((d) => {
                        setInscricao(d.data())
                    }).then(() => {
                        Swal.close();
                    })
                }
            }
        })
    }

    function geraPagamentoAbsoluto() {

        if (dadosInscricao.pagamentoAbsoluto.pagamento) {
            if (dadosInscricao.pagamentoAbsoluto.tipoPagamento === 'bank_transfer') {
                Swal.fire({
                    title: 'Pagamento Pendente',
                    text: 'Você possui um pagamento em aberto do tipo PIX',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Trocar Pagamento',
                    cancelButtonText: 'Não'
                }).then((result) => {
                    if (result.value) {
                        buscaLoteAbsoluto()
                    }
                })
            } else if (dadosInscricao.pagamentoAbsoluto.tipoPagamento === 'ticket') {
                Swal.fire({
                    title: 'Pagamento Pendente',
                    text: 'Você possui um pagamento em aberto do tipo Boleto',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Trocar Pagamento',
                    cancelButtonText: 'Não'
                }).then((result) => {
                    if (result.value) {
                        buscaLoteAbsoluto()
                    }
                })
            } else if (dadosInscricao.pagamentoAbsoluto.tipoPagamento === 'credit_card') {
                buscaLoteAbsoluto()
            }
        } else {
            buscaLoteAbsoluto()
        }
    }

    function buscaLoteAbsoluto() {
        if (dadosInscricao.voucher.id) {
            if (dadosInscricao.responsavel.id) {
                window.location = window.location.href.replace('/minhaInscricao/', '/pagamentoAluno/')
            } else {
                window.location = window.location.href = `/pagamentoAbsoluto?e=${evento.id}&i=${dadosInscricao.id}`
                // window.location = window.location.href.replace('/minhaInscricao/' + dadosInscricao.id, '/pagamentoAbsoluto/' + evento.id)
            }
        } else {
            mensagemLoading('Recalculando valor...')
            let valorAbs = 0
            servicos.eventos.doc(evento.id).collection('lotes')
                .where('tipoCategoria', '==', 'absoluto')
                .where('sexo', '==', dadosInscricao.usuario.sexo)
                .where('geral', '==', true)
                .get().then((snap) => {
                    if (snap.size > 0) {
                        const data = snap.docs.map(doc => doc.data())
                        const listaValorAbs = [];
                        data.forEach((doc) => {
                            var dt1 = new Date(moment().format('YYYY-MM-DD'));
                            var dt2 = new Date(doc.vencimento);
                            var total = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
                            if (total > -1) {
                                listaValorAbs.push(doc.valor)
                            }
                        })
                        if (listaValorAbs.length > 0) {
                            if (Math.min(...listaValorAbs) === dadosInscricao.valorAbsoluto) {
                                if (dadosInscricao.responsavel.id) {
                                    window.location = window.location.href.replace('/minhaInscricao/', '/pagamentoAluno/')
                                } else {
                                    window.location = window.location.href = `/pagamentoAbsoluto?e=${evento.id}&i=${dadosInscricao.id}`
                                    // window.location = window.location.href.replace('/minhaInscricao/' + dadosInscricao.id, '/pagamentoAbsoluto/' + evento.id)
                                }
                            } else {
                                valorAbs = Math.min(...listaValorAbs)
                                servicos.inscricoes.doc(dadosInscricao.id).update({
                                    valorAbsoluto: parseFloat(valorAbs),
                                    valorTotal: parseFloat(dadosInscricao.valorCategoria + valorAbs)
                                }).then(() => {
                                    if (dadosInscricao.responsavel.id) {
                                        window.location = window.location.href.replace('/minhaInscricao/', '/pagamentoAluno/')
                                    } else {
                                        window.location = window.location.href = `/pagamentoAbsoluto?e=${evento.id}&i=${dadosInscricao.id}`
                                        // window.location = window.location.href.replace('/minhaInscricao/' + dadosInscricao.id, '/pagamentoAbsoluto/' + evento.id)
                                    }
                                })
                            }
                        } else {
                            mensagemAlerta("Lote vencido! Entre em contato com a administração")
                        }
                    }
                })
        }
    }

    function segundadViaAbsoluto() {
        var venc = moment(moment(dadosInscricao.pagamentoAbsoluto.vencimento.replace('T', ' ')).format('DD/MM/YYYY - HH:mm'), 'DD/MM/YYYY - HH:mm')
        var date1 = moment().format('DD/MM/YYYY')
        var data1 = moment(date1, 'DD/MM/YYYY');
        if (venc.diff(data1, 'day') > 0) {
            getPagamento(dadosInscricao.pagamentoAbsoluto.pagamento, evento.chavePagamento).then((b) => {
                window.location = b.transaction_details.external_resource_url
            })
        } else {
            Swal.fire({
                title: 'Boleto Vencido',
                text: 'Seu boleto venceu. Você será redirecionado a tela de forma de pagamento',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ver Boleto',
                cancelButtonText: 'Não'
            }).then((result) => {
                if (result.value) {
                    getPagamento(dadosInscricao.pagamentoAbsoluto.pagamento, evento.chavePagamento).then((b) => {
                        window.location = b.transaction_details.external_resource_url
                    })
                }
            })
        }
    }

    function editaInscricao() {
        if (dadosInscricao.responsavel.id) {
            window.location = window.location.href.replace('/minhaInscricao/', '/editaInscricaoAluno/')
        } else {
            window.location = window.location.href.replace('/minhaInscricao/', '/editaInscricao/')
        }
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location.href = id
        })
    }
    function mensagemSucessoCancelamento(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location = window.location.href.replace('/minhaInscricao/' + dadosInscricao.id, '/evento/' + evento.id)
        })
    }
    function mensagemSucessoBoleto(msg, link) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location = link
        })
    }
    function mensagemIinfo(titulo, msg) {
        Swal.fire(titulo, msg, 'info');
    }
    function mensagemAlerta(msg) {
        Swal.fire('Atenção', msg, 'warning');
    }


    function inscricaoComDesconto() {
        return (
            dadosInscricao.voucher.id || dadosInscricao?.descontoFiliacao?.descontoAplicado
        )
    }


    return (
        <div className="body-wrapper blank-loder">
            <Header />
            <div class="section_fullwidth">
                <div class="headline headline_img">
                    <div class="headline__inner">
                        <div class="headline__title">
                            <h1>{evento.nome}</h1>
                            <p>Minha Inscrição</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section_default section-single-class-layout clearfix">
                <main class="section_default">
                    <div class="vc_row wpb_row vc_row-fluid">
                        <div class="wpb_column vc_column_container vc_col-sm-12">
                            <div class="vc_column-inner ">
                                <div class="wpb_wrapper">
                                    <div class="vc_empty_space" style={{ height: 28 }}><span
                                        class="vc_empty_space_inner"></span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">

                        <div class="wpb_column vc_column_container vc_col-sm-8">
                            <div class="vc_column-inner ">
                                <h3 class="widget__title">Inscrição Categoria Peso</h3>
                                <div class="wpb_wrapper">
                                    <div class="wpb_text_column wpb_content_element ">
                                        <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                        </div>
                                        {dadosInscricao.usuario ?
                                            <div class="package-style-1 pnchr-package clearfix ">
                                                <div class="package-style-1__img">
                                                    <img src={dadosInscricao.usuario ? dadosInscricao.usuario.avatar : null} alt="" title="image-here-1" />
                                                </div>
                                                <div class="package-style-1__descr  package-style-1_with-img">
                                                    <h3 class="package-style-1__title" style={{ color: "#121212" }}> <i class="fa  fa-user" aria-hidden="true"
                                                        style={{ color: "#c21111" }}></i> {dadosInscricao.usuario ? dadosInscricao.usuario.nome + " " + dadosInscricao.usuario.sobrenome : null}</h3>
                                                    <div class="package-style-1__text"><i class="fa  fa-users" aria-hidden="true"
                                                        style={{ color: "#c21111" }}></i> {dadosInscricao.equipe.nomeEquipe} / {dadosInscricao.equipe.nomeProfessor}</div>
                                                    <div class="package-style-1__text" style={{ marginTop: 20 }}><i class="fa  fa-bookmark" aria-hidden="true"
                                                        style={{ color: "#c21111" }}></i> {dadosInscricao.categoria.absoluto.id && dadosInscricao.statusAbsoluto === 0 ? "Categoria e Absoluto" : "Apenas Categoria Peso"}</div>
                                                    <div class="package-style-1__text">{dadosInscricao.categoria.categoria.categoria} / {dadosInscricao.categoria.faixa.faixa.toUpperCase()} / {dadosInscricao.categoria.peso.nome.toUpperCase()} / {dadosInscricao.categoria.categoria.sexo.toUpperCase()}</div>
                                                    {dadosInscricao.categoria.absoluto.id && dadosInscricao.statusAbsoluto === 0 ?
                                                        <div class="package-style-1__text">{dadosInscricao.categoria.absoluto.absoluto.toUpperCase()} / {dadosInscricao.categoria.categoria.sexo.toUpperCase()}</div>
                                                        :
                                                        null
                                                    }

                                                    {dadosInscricao.status === 2 ?
                                                        <div class="package-style-1__text" style={{ color: "green" }}><i class="fa fa-check" aria-hidden="true"></i> Inscrição Confirmada</div>
                                                        : dadosInscricao.status === 3 ?
                                                            <div class="package-style-1__text" style={{ color: "red" }}><i class="fa fa-times" aria-hidden="true"></i>Pagamento Rejeitado</div>
                                                            :
                                                            <div class="package-style-1__text" style={{ color: "orange" }}><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Inscrição Pendente</div>
                                                    }

                                                    {(dadosInscricao.categoria.absoluto.id && dadosInscricao.statusAbsoluto === 0 && (dadosInscricao.voucher.tipo !== 'cortesia' || !dadosInscricao.voucher.id)) &&
                                                        (<div class="package-style-1__text" style={{ marginTop: 20, fontSize: 18, color: inscricaoComDesconto() ? 'green' : '' }}><i class="fa fa-money" aria-hidden="true"></i> Valor da Inscrição: <b>{dadosInscricao.valorTotal.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</b></div>)



                                                    }
                                                    {(dadosInscricao.categoria.absoluto.id && dadosInscricao.statusAbsoluto === 0 && (dadosInscricao.valorTotal === 0 || dadosInscricao.voucher.tipo === 'cortesia')) && (
                                                        <div class="package-style-1__text" style={{ marginTop: 20, fontSize: 18, color: "green" }}><i class="fa fa-money" aria-hidden="true"></i> Inscrição Cortesia</div>
                                                    )}
                                                    {((!dadosInscricao.categoria.absoluto.id && dadosInscricao.statusAbsoluto === 0) ||
                                                        (dadosInscricao.categoria.absoluto.id && dadosInscricao.statusAbsoluto !== 0)) && (
                                                            <div class="package-style-1__text" style={{ marginTop: 20, fontSize: 18, color: inscricaoComDesconto() ? 'green' : '' }}><i class="fa fa-money" aria-hidden="true"></i> Valor da Inscrição: <b> {dadosInscricao.valorTotalOriginal.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</b></div>
                                                        )}


                                                </div>
                                            </div>
                                            :
                                            null
                                        }

                                        {dadosInscricao.usuario ?
                                            <div class="package-style-1 pnchr-package clearfix row" style={{ padding: 10 }}>
                                                {dadosInscricao.status < 2 || dadosInscricao.status === 3 ?
                                                    <>
                                                        {dadosInscricao.valorTotal === 0 ?
                                                            <p style={{ fontSize: 10, marginLeft: 15, color: "red" }}>* Caso o valor esteja zerado, clique no botão <b>FORMA DE PAGAMENTO</b> para recalcular o valor e direcionar a página de pagamento</p>
                                                            :
                                                            null
                                                        }
                                                        {dadosInscricao.comprovante ?
                                                            <div style={{ marginTop: 20 }} class="package-style-1__btn vc_col-lg-4" >
                                                                <a href={dadosInscricao.comprovante} class="btn_defoult btn_fill vc_col-lg-12" ><i class="fa  fa-file" aria-hidden="true" style={{ color: "#fff" }}></i> Ver Comprovante</a>
                                                            </div>
                                                            :
                                                            <div style={{ marginTop: 20 }} class="package-style-1__btn vc_col-lg-4" onClick={() => geraPagamento()} >
                                                                <a href="javascript:void(0)" class="btn_defoult btn_fill vc_col-lg-12" ><i class="fa  fa-money" aria-hidden="true"></i> Formas Pagamento</a>
                                                            </div>
                                                        }
                                                    </>
                                                    :
                                                    <div style={{ marginTop: 20 }} class="package-style-1__btn vc_col-lg-4" >
                                                        {dadosInscricao.comprovante ?
                                                            <a href={dadosInscricao.comprovante} class="btn_defoult btn_fill vc_col-lg-12" style={{ backgroundColor: "#A4A4A4" }}><i class="fa  fa-user" aria-hidden="true" style={{ color: "#fff" }}></i> Ver Comprovante</a>
                                                            :
                                                            <a href="javascript:void(0)" class="btn_defoult btn_fill vc_col-lg-12" style={{ backgroundColor: "#A4A4A4" }}><i class="fa  fa-user" aria-hidden="true" style={{ color: "#fff" }}></i> Formas Pagamento</a>
                                                        }

                                                    </div>
                                                }
                                                {dadosInscricao.pagamento.pagamento && dadosInscricao.pagamento.tipoPagamento === "ticket" && dadosInscricao.status < 2 || dadosInscricao.status === 3 ?
                                                    <div style={{ marginTop: 20 }} class="package-style-1__btn vc_col-lg-4" onClick={() => segundadVia()}>
                                                        <a href="javascript:void(0)" class="btn_defoult btn_fill vc_col-lg-12" ><i class="fa  fa-file" aria-hidden="true"></i> 2° Via de Boleto</a>
                                                    </div>
                                                    :
                                                    <div style={{ marginTop: 20 }} class="package-style-1__btn vc_col-lg-4" >
                                                        <a href="javascript:void(0)" class="btn_defoult btn_fill vc_col-lg-12" style={{ backgroundColor: "#A4A4A4" }}><i class="fa  fa-file" aria-hidden="true" style={{ color: "#fff" }}></i> 2° Via de Boleto</a>
                                                    </div>
                                                }
                                                {evento.edicao === "SIM" && dadosInscricao.status === 2 ?
                                                    <div style={{ marginTop: 20 }} class="package-style-1__btn vc_col-lg-4" onClick={() => editaInscricao()}>
                                                        <a href="javascript:void(0)" class="btn_defoult btn_fill vc_col-lg-12" ><i class="fa  fa-pencil" aria-hidden="true"></i> Alterar Inscrição</a>
                                                    </div>
                                                    :
                                                    <div style={{ marginTop: 20 }} class="package-style-1__btn vc_col-lg-4" onClick={() => {
                                                        if (evento.edicao === 'NAO') {
                                                            mensagemAlerta(`O período de edição das inscrições já está encerrado.`)
                                                        } else {
                                                            mensagemAlerta(`A alteração da inscrição só será permitida após a confirmação do pagamento.`)

                                                        }
                                                    }}>
                                                        <a href="javascript:void(0)" class="btn_defoult btn_fill vc_col-lg-12" style={{ backgroundColor: "#A4A4A4" }}><i class="fa  fa-pencil" aria-hidden="true" style={{ color: "#fff" }}></i> Alterar Inscrição</a>
                                                    </div>
                                                }
                                                <div style={{ marginTop: 20 }} class="package-style-1__btn vc_col-lg-4" >
                                                    <a href="javascript:void(0)" class="btn_defoult btn_fill vc_col-lg-12" onClick={() => cancelaInscricaoCategoria()} ><i class="fa  fa-close" aria-hidden="true"></i> Cancelar Inscrição</a>
                                                </div>
                                            </div>
                                            :
                                            null
                                        }

                                    </div>
                                </div>
                            </div>
                            {dadosInscricao && dadosInscricao.statusAbsoluto > 0 ?
                                <div class="vc_column-inner ">
                                    <h3 class="widget__title">Inscrição Categoria Absoluto</h3>
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                            {dadosInscricao.usuario ?
                                                <div class="package-style-1 pnchr-package clearfix ">
                                                    <div class="package-style-1__img">
                                                        <img src={dadosInscricao.usuario ? dadosInscricao.usuario.avatar : null} alt="" title="image-here-1" />
                                                    </div>
                                                    <div class="package-style-1__descr  package-style-1_with-img">
                                                        <h3 class="package-style-1__title" style={{ color: "#121212" }}> <i class="fa  fa-user" aria-hidden="true"
                                                            style={{ color: "#c21111" }}></i> {dadosInscricao.usuario ? dadosInscricao.usuario.nome : null}</h3>
                                                        <div class="package-style-1__text"><i class="fa  fa-users" aria-hidden="true"
                                                            style={{ color: "#c21111" }}></i> {dadosInscricao.equipe.nomeEquipe} / {dadosInscricao.equipe.nomeProfessor}</div>
                                                        <div class="package-style-1__text" style={{ marginTop: 20 }}><i class="fa  fa-bookmark" aria-hidden="true"
                                                            style={{ color: "#c21111" }}></i> Categoria Absoluto</div>
                                                        <div class="package-style-1__text">{dadosInscricao.categoria.absoluto.absoluto.toUpperCase()} / {dadosInscricao.categoria.categoria.sexo.toUpperCase()}</div>

                                                        {dadosInscricao.statusAbsoluto === 2 ?
                                                            <div class="package-style-1__text" style={{ color: "green" }}><i class="fa fa-check" aria-hidden="true"></i> Inscrição Confirmada</div>
                                                            : dadosInscricao.statusAbsoluto === 3 ?
                                                                <div class="package-style-1__text" style={{ color: "red" }}><i class="fa fa-times" aria-hidden="true"></i> Inscrição Confirmada</div>
                                                                :
                                                                <div class="package-style-1__text" style={{ color: "orange" }}><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Inscrição Pendente</div>
                                                        }
                                                        <div class="package-style-1__text" style={{ marginTop: 20, fontSize: 18 }}><i class="fa fa-money" aria-hidden="true"></i> Valor da Inscrição: <b>{dadosInscricao.valorAbsoluto.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</b></div>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }
                                            {dadosInscricao.usuario ?
                                                <div class="package-style-1 pnchr-package clearfix row" style={{ padding: 10 }}>
                                                    {dadosInscricao.statusAbsoluto < 2 ?
                                                        <>
                                                            {dadosInscricao.valorTotal === 0 ?
                                                                <p style={{ fontSize: 10, marginLeft: 15, color: "red" }}>* Caso o valor esteja zerado, clique no botão <b>FORMA DE PAGAMENTO</b> para recalcular o valor e direcionar a página de pagamento</p>
                                                                :
                                                                null
                                                            }
                                                            <div style={{ marginTop: 20 }} class="package-style-1__btn vc_col-lg-4" onClick={() => geraPagamentoAbsoluto()} >
                                                                <a href="javascript:void(0)" class="btn_defoult btn_fill vc_col-lg-12" ><i class="fa  fa-money" aria-hidden="true"></i> Formas Pagamento</a>
                                                            </div>
                                                        </>
                                                        :
                                                        <div style={{ marginTop: 20 }} class="package-style-1__btn vc_col-lg-4" >
                                                            <a href="javascript:void(0)" class="btn_defoult btn_fill vc_col-lg-12" style={{ backgroundColor: "#A4A4A4" }}><i class="fa  fa-user" aria-hidden="true" style={{ color: "#fff" }}></i> Formas Pagamento</a>
                                                        </div>
                                                    }
                                                    {dadosInscricao.pagamentoAbsoluto.pagamento && dadosInscricao.pagamentoAbsoluto.tipoPagamento === "ticket" && dadosInscricao.status < 2 ?
                                                        <div style={{ marginTop: 20 }} class="package-style-1__btn vc_col-lg-4" onClick={() => segundadViaAbsoluto()}>
                                                            <a href="javascript:void(0)" class="btn_defoult btn_fill vc_col-lg-12" ><i class="fa  fa-file" aria-hidden="true"></i> 2° Via de Boleto</a>
                                                        </div>
                                                        :
                                                        <div style={{ marginTop: 20 }} class="package-style-1__btn vc_col-lg-4" >
                                                            <a href="javascript:void(0)" class="btn_defoult btn_fill vc_col-lg-12" style={{ backgroundColor: "#A4A4A4" }}><i class="fa  fa-file" aria-hidden="true" style={{ color: "#fff" }}></i> 2° Via de Boleto</a>
                                                        </div>
                                                    }
                                                    <div style={{ marginTop: 20 }} class="package-style-1__btn vc_col-lg-4" >
                                                        <a href="javascript:void(0)" class="btn_defoult btn_fill vc_col-lg-12" onClick={() => cancelaInscricaoAbsoluto()} ><i class="fa  fa-close" aria-hidden="true"></i> Cancelar Inscrição</a>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }

                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }
                        </div>

                        <div class="wpb_column vc_column_container vc_col-sm-4 vc_col-lg-offset-0 vc_col-lg-4 vc_col-md-offset-6 vc_col-md-4 vc_hidden-md vc_hidden-sm vc_hidden-xs">
                            <div class="vc_column-inner ">
                                <div class="wpb_wrapper">
                                    <div class="wpb_widgetised_column wpb_content_element">
                                        <div class="wpb_wrapper">
                                            <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                                <h3 class="widget__title">{evento.nome}</h3>
                                                <div class="ratings-widget__item widget-item">
                                                    <div class="ratings-widget__img js-hover-veil">
                                                        <img
                                                            src={evento.avatar}
                                                            alt="" title="image here (2)" />
                                                    </div>
                                                    <div class="ratings-widget__details ">
                                                        {/* <h3 class="package-style-1__title" style={{ color: "#121212" }}>{evento.nome}</h3> */}
                                                        <div class="package-style-1__text"><b>De {moment(evento.inicioEvento).format("DD/MM/YYYY")} à {moment(evento.fimEvento).format("DD/MM/YYYY")}</b></div>
                                                        <div class="package-style-1__text">{evento.endereco ? evento.endereco.cidade : null} / {evento.endereco ? evento.endereco.uf : null}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>
            </div>
            <Footer />
        </div>
    );
}