import { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import Swal from 'sweetalert2'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import moment from 'moment'
import firebase from 'firebase'
import './Filiacao.css'
import RG_Frente_Verso from './Imagens/RG-Frente-verso.jpg'
import Foto_Documento from './Imagens/foto-documento.jpg'
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core'


export default function Filiacao() {

  const { idFederacao } = useParams()

  const [servicos] = useState({
    eventos: firebase.firestore().collection("eventos"),
    equipes: firebase.firestore().collection("equipes"),
    usuarios: firebase.firestore().collection("usuarios"),
    inscricoes: firebase.firestore().collection('inscricoes'),
    federacoes: firebase.firestore().collection('federacoes'),
    filiacoes: firebase.firestore().collection('federacoes').doc(idFederacao).collection('filiacoes'),
    configuracoes: firebase.firestore().collection('configuracoes'),
    auth: firebase.auth()
  })

  const [federacao, setFederacao] = useState({})
  const [modalidade, setModalidade] = useState('')
  const [graduacoes, setGraduacoes] = useState([])
  const [filiacao, setFiliacao] = useState({});
  const [statusFiliacao, setStatusFiliacao] = useState(1)
  const [usuario, setUsuario] = useState({})
  const [usuarioFiliacao, setUsuarioFiliacao] = useState({})
  const [equipe, setEquipe] = useState('')
  const [professor, setProfessor] = useState('')
  const [graduacao, setGraduacao] = useState('')

  const [idade, setIdade] = useState('')
  const [competidores, setCompetidores] = useState([])

  const [fotoRg, setFotoRg] = useState('Nenhum arquivo selecionado');
  const [arquivoRg, setArquivoRg] = useState('');
  const refRgFile = useRef(null);
  const [uploadArquivoRg, setUploadArquivoRg] = useState('');

  const [fotoDocumento, setFotoDocumento] = useState('Nenhum arquivo selecionado');
  const [arquivoFoto, setArquivoFoto] = useState('');
  const refDocumentoFile = useRef(null);
  const [uploadArquivoFoto, setUploadArquivoFoto] = useState('');

  const [fotoComprovante, setFotoComprovante] = useState('Nenhum arquivo selecionado');
  const [arquivoComprovante, setArquivoComprovante] = useState('');
  const [uploadArquivoComprovante, setUploadArquivoComprovante] = useState('');
  const refComprovanteFile = useRef(null);
  const [pagamentoPendente, setPagamentoPendente] = useState({})
  const [ultimoPagamento, setUltimoPagamento] = useState({})
  const [pagamentosAprovados, setPagamentosAprovados] = useState([])

  const [concordoTermo, setConcordoTermo] = useState(false);

  const [opcaoEntregaSelecionado, setOpcaoEntregaSelecionado] = useState('')

  const [progresso, setProgresso] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingImage, setLoadingImage] = useState(false)
  const [loadingCep, setLoadingCep] = useState(false)


  useEffect(() => {

    servicos.auth.onAuthStateChanged(async (user) => {
      let idUser = JSON.parse(localStorage.getItem('usuarioId'))

      if (user || idUser) {

        servicos.usuarios.doc(user.uid ? user.uid : idUser).get().then(async (doc) => {
          if (doc.exists) {
            setUsuario(doc.data())

            if (doc.data().usuario === 2) {
              setUsuarioFiliacao(doc.data())

              const date = new Date();
              const hoje = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());

              const [ano, mes, dia] = doc.data().dataNascimento.split('-').map(Number);
              const dtNasc = Date.UTC(ano, mes - 1, dia);

              setIdade(moment(hoje).diff(dtNasc, 'years'))

              // Carregar equipe e professor
              if (doc.data().equipe) {

                servicos.equipes.doc(doc.data().equipe).get().then((e) => {
                  setEquipe(e.data().equipe ? e.data().equipe : '')
                })

                if (doc.data().professor) {
                  servicos.equipes.doc(doc.data().equipe).collection('professores').doc(doc.data().professor).get().then((e) => {
                    setProfessor(e.data().professor)
                  })
                }

              }

              // carregar filiação
              if (doc.data()?.filiacoes?.length > 0) {
                if (doc.data()?.filiacoes.includes(idFederacao)) {
                  const docRef = await servicos.federacoes.doc(idFederacao).collection('filiacoes').where('idFiliado', '==', doc.data().id).where('tipoUsuario', '==', doc.data().tipoUsuario).get();
                  const data = docRef.docs.map(d => d.data())[0];
                  setConcordoTermo(data.concordoTermo)
                  setStatusFiliacao(data.statusFiliacao)
                  setGraduacao((data.graduacao && data.statusFiliacao !== 4) ? data.graduacao : '')
                  setFiliacao(data);
                  setFotoRg(data.nomeImagemRg)
                  setFotoDocumento(data.nomeImagemFoto)
                  setOpcaoEntregaSelecionado(data.opcaoEntregaSelecionado ? data.opcaoEntregaSelecionado : '')

                }
              }

            }

            if (doc.data().usuario === 3) {

              servicos.usuarios.doc(doc.data().id).collection('competidores').get().then(snap => {
                if (snap.size > 0) {
                  const data = snap.docs.map(d => d.data())
                  setCompetidores(data.map(comp => {
                    return { ...comp, responsavel: doc.data().id }
                  }))

                }
              })
            }

          }

        })

      } else {
        window.location = '/'
      }

    })

    // carregar Federação, Modalidade e Graduações

    servicos.federacoes.doc(idFederacao).get().then(snap => {

      setFederacao(snap.data());

      const idModalidade = snap.data().modalidade

      servicos.configuracoes.get().then(snap => {
        const idConfiguracoes = snap.docs.map(d => d.id)[0];

        servicos.configuracoes.doc(idConfiguracoes).collection('modalidades').doc(idModalidade).get().then(snap => {
          setModalidade(snap.data().descricao ? snap.data().descricao : '')
        })

        servicos.configuracoes.doc(idConfiguracoes).collection('modalidades').doc(idModalidade).collection('graduacoes').get().then(snap => {
          if (snap.size > 0) {
            const data = snap.docs.map(d => d.data());
            setGraduacoes(data);
          }
        })
      })

    })



  }, [])

  useEffect(() => {
    if (filiacao.id) {
      servicos.filiacoes.doc(filiacao.id).collection('pagamentos').get().then(snap => {
        const data = snap.docs.map(d => d.data())
        if (data.some(pgto => pgto.status !== 2)) {
          const _pagamento = data.find(pgto => pgto.status !== 2)
          setPagamentoPendente(_pagamento)
          setFotoComprovante(_pagamento.nomeImagemComprovantePgto)
        }
        if (data.some(pgto => pgto.status == 2)) {
          const _pagamentos = data.filter(pgto => pgto.status == 2)
          setPagamentosAprovados(_pagamentos)
        }

        if (data.length > 0) {
          const _ultimoPagamento = data.sort((a, b) => b.dtAnexo - a.dtAnexo)[0]
          setUltimoPagamento(_ultimoPagamento)
        }

      })

    } else {
      setPagamentoPendente({})
      setFotoComprovante('Nenhum arquivo selecionado')
    }

  }, [filiacao])

  useEffect(async () => {

    // Alterar status da filiação para Vencido quando ocorrer vencimento.

    if (filiacao?.statusFiliacao === 2) {
      if (ultimoPagamento?.status === 2) {
        const diasPassados = moment(new Date()).diff(moment(converterData(ultimoPagamento.dtAprovado), 'DD-MM-YYYY HH:mm'), 'days')

        //  console.log('diasPassados: ', diasPassados);

        let dados = {
          statusFiliacao: 4,
          statusImagemFoto: 4,
          statusImagemRg: 4
        }

        if (filiacao.opcaoEntregaSelecionado) {
          dados = {
            ...dados,
            opcaoEntregaSelecionado: ''
          }
        }

        if (ultimoPagamento.status == 2 && diasPassados > 365) {
          await servicos.filiacoes.doc(filiacao.id).update(dados)
          servicos.filiacoes.doc(filiacao.id).get().then(snap => {
            setFiliacao(snap.data())
            setStatusFiliacao(4)
            setOpcaoEntregaSelecionado('')
            setGraduacao('')
          })
        }
      }
    }


  }, [ultimoPagamento?.id, filiacao?.tipoPagamento, filiacao?.id])

  const handleFotoRg = (event) => {
    event.stopPropagation()
    setArquivoRg(event.target.files[0])
    if (uploadArquivoRg) {
      URL.revokeObjectURL(uploadArquivoRg)
    }
    setUploadArquivoRg(URL.createObjectURL(event.target.files[0]) ? URL.createObjectURL(event.target.files[0]) : '')
    const file = event.target.files[0];
    console.log('file: ', file);
    setFotoRg(file ? file.name : 'Nenhum arquivo selecionado');
  };

  const handleFotoDocumento = (event) => {
    console.log('Chamou handleFotoDocumento(');
    event.stopPropagation()
    setArquivoFoto(event.target.files[0])
    if (uploadArquivoFoto) {
      URL.revokeObjectURL(uploadArquivoFoto)
    }
    setUploadArquivoFoto(URL.createObjectURL(event.target.files[0]) ? URL.createObjectURL(event.target.files[0]) : '')
    const file = event.target.files[0];
    console.log('file: ', file);
    setFotoDocumento(file ? file.name : 'Nenhum arquivo selecionado');

  };
  const handleFotoComprovante = (event) => {
    event.stopPropagation()
    setArquivoComprovante(event.target.files[0])
    if (uploadArquivoComprovante) {
      URL.revokeObjectURL(uploadArquivoComprovante)
    }
    setUploadArquivoComprovante(URL.createObjectURL(event.target.files[0]) ? URL.createObjectURL(event.target.files[0]) : '')
    const file = event.target.files[0];
    console.log('file: ', file);
    setFotoComprovante(file ? file.name : 'Nenhum arquivo selecionado');

  };

  const handleCompetidor = async (event) => {
    event.persist();
    setLoading(true)
    setUsuarioFiliacao({})
    setFiliacao({})
    setFotoRg('Nenhum arquivo selecionado')
    setArquivoRg('')
    setUploadArquivoRg('')
    setFotoDocumento('Nenhum arquivo selecionado')
    setArquivoFoto('')
    setFotoComprovante('Nenhum arquivo selecionado')
    setUploadArquivoFoto('')
    setArquivoComprovante('')
    setUploadArquivoComprovante('')
    setEquipe('')
    setProfessor('')
    setIdade('')
    setPagamentosAprovados([])
    setPagamentoPendente([])
    setConcordoTermo(false)
    setGraduacao('')
    setOpcaoEntregaSelecionado('')
    // setCompetidorSelecionado(event.target.value);
    if (event.target.value !== "") {
      if (event.target.value === "eu") {

        setUsuarioFiliacao(usuario);

        // Carrega Equipe
        if (usuario?.equipe) {
          servicos.equipes.doc(usuario.equipe).get().then((e) => {
            setEquipe(e.data()?.equipe ? e.data().equipe : '');
          })
          // Carrega Professor
          if (usuario?.professor) {
            servicos.equipes.doc(usuario.equipe).collection('professores').doc(usuario.professor).get().then((e) => {
              if (e.data()) {
                setProfessor(e.data().professor)
              }
            })
          }

        }

        // carregar idade
        if (usuario?.id) {
          const date = new Date();

          const hoje = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());

          const [ano, mes, dia] = usuario.dataNascimento.split('-').map(Number);
          const dtNasc = Date.UTC(ano, mes - 1, dia);

          setIdade(moment(hoje).diff(dtNasc, 'years'))
        }

        // Carregar os dados da filiação do usuário selecionado

        if (usuario?.filiacoes?.length > 0 && usuario?.filiacoes.includes(idFederacao)) {

          const snap = await servicos.federacoes.doc(idFederacao).collection('filiacoes').where('idFiliado', '==', usuario.id).where('tipoUsuario', '==', usuario.tipoUsuario).get();

          if (!snap.empty) {

            const data = snap.docs.map(d => d.data())[0]
            setFiliacao(data);
            setFotoRg(data.nomeImagemRg)
            setFotoDocumento(data.nomeImagemFoto)
            setConcordoTermo(data.concordoTermo)
            setGraduacao((data.graduacao && data.statusFiliacao !== 4) ? data.graduacao : '')
            setOpcaoEntregaSelecionado(data.opcaoEntregaSelecionado ? data.opcaoEntregaSelecionado : '')


            if (data?.pagamentoFiliacao?.length > 0) {
              const pgto = data.pagamentoFiliacao.sort((a, b) => a.dtAnexo - b.dtAnexo)[0];
              setFotoComprovante(pgto.nomeImagemComprovantePgto)
            }
          } else {
            setFiliacao({});
            setFotoRg('Nenhum arquivo selecionado')
            setFotoDocumento('Nenhum arquivo selecionado')
            setFotoComprovante('Nenhum arquivo selecionado')
            setConcordoTermo(false)
            setGraduacao('')
            setOpcaoEntregaSelecionado('')
          }

        }

      } else {

        const competidor = competidores[event.target.value]

        setUsuarioFiliacao(competidor)

        // Carregar Equipe
        if (competidor.equipe) {
          servicos.equipes.doc(competidor.equipe).get().then((e) => {
            setEquipe(e.data()?.equipe ? e.data()?.equipe : '')
          })

          // Carregar Professor
          if (competidor.professor) {
            servicos.equipes.doc(competidor.equipe).collection('professores').doc(competidor.professor).get().then((e) => {
              setProfessor(e.data()?.professor ? e.data()?.professor : '')
            })
          }
        }

        // Carrregar Idade

        const dataNascimento = moment(competidor.dataNascimento, 'YYYY-MM-DD')
        setIdade(moment(new Date()).diff(dataNascimento, 'years'))

        if (competidor?.filiacoes?.length > 0) {
          if (competidor?.filiacoes.includes(idFederacao)) {

            const snap = await servicos.federacoes.doc(idFederacao).collection('filiacoes').where('idFiliado', '==', competidor.id).where('tipoUsuario', '==', competidor.tipoUsuario).get();

            if (!snap.empty) {
              const data = snap.docs.map(d => d.data())[0]
              setFiliacao(data);
              setFotoRg(data.nomeImagemRg)
              setFotoDocumento(data.nomeImagemFoto)
              setConcordoTermo(data.concordoTermo)
              setGraduacao((data.graduacao && data.statusFiliacao !== 4) ? data.graduacao : '')
              setOpcaoEntregaSelecionado(data.opcaoEntregaSelecionado ? data.opcaoEntregaSelecionado : '')

              if (data?.pagamentoFiliacao?.length > 0) {
                const pgto = data.pagamentoFiliacao.sort((a, b) => a.dtAnexo - b.dtAnexo)[0];
                setFotoComprovante(pgto.nomeImagemComprovantePgto)
              }
            } else {
              setFiliacao({});
              setFotoRg('Nenhum arquivo selecionado')
              setFotoDocumento('Nenhum arquivo selecionado')
              setFotoComprovante('Nenhum arquivo selecionado')
              setConcordoTermo(false)
              setGraduacao('')
              setOpcaoEntregaSelecionado('')

            }

          }
        } else {
          setFiliacao({});
          setFotoRg('Nenhum arquivo selecionado')
          setFotoDocumento('Nenhum arquivo selecionado')
          setFotoComprovante('Nenhum arquivo selecionado')
          setConcordoTermo(false)
        }



        setLoading(false)



      }
    }
  }


  function mensagemAtencao(msg) {
    Swal.fire('Atenção', msg, 'warning')
  }
  function mensagemAlerta(msg) {
    Swal.fire('Alerta', msg, 'warning')
  }
  function mensagemReprovado(msg) {
    Swal.fire('Documento Reprovado', msg, 'warning')
  }

  function mensagemSucesso(msg, idInscricao) {
    Swal.fire('Sucesso', msg, 'success').then(() => {
      // if (itemVoucher.id && itemVoucher.tipo === 'cortesia') {
      //   window.location = window.location.href.replace('/inscricao/' + id, '/evento/' + evento.id)
      // } else if ((valorAbsoluto + valorCategoria) === 0) {
      //   window.location = window.location.href.replace('/inscricao/' + id, '/minhaInscricao/' + id)
      // } else {
      //   window.location.href = `/pagamento?e=${id}&i=${idInscricao}`
      // }

    })
  }

  function mensagemLoading(msg) {
    Swal.fire({
      title: 'Aguarde',
      html: msg,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
      },
    })
  }
  function mensagemErro(msg) {
    Swal.fire('Erro', msg, 'error')
  }




  async function cadastraImagem(idFiliacao, arquivo, _imagem, idPagamento) {

    return new Promise((resolve, reject) => {

      let caminho = (arquivo !== 'comprovante') ?
        `federacoes/${idFederacao}/filiacoes/${idFiliacao}/${arquivo}` :
        `federacoes/${idFederacao}/filiacoes/${idFiliacao}/pagamentos/${idPagamento}`

      var uploadTask = firebase.storage().ref().child(caminho).put(_imagem, { contentType: _imagem.type });

      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgresso(parseInt(progress) + '%')
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('uploadImagem is paused');
            setLoadingImage(false)
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log('uploadImagem is running');
            break;
          default:
            console.log('Passou')
        }
      }, (error) => {
        switch (error.code) {
          case 'storage/unauthorized':
            break;
          case 'storage/canceled':
            break;
          case 'storage/unknown':
            break;
          default:
            console.log('Passou')
        }
      }, async () => {


        try {
          const atualizarDados = async () => {
            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()
            let dados = {}
            if (arquivo === 'rg') {

              dados = {
                ...dados,
                urlImagemRg: downloadURL,
                statusImagemRg: 1,
                nomeImagemRg: _imagem.name,
                dtAnexoRg: firebase.firestore.Timestamp.fromDate(new Date())
              }

              if (filiacao.statusImagemRg === 3) {
                dados = { ...dados, observacaoRg: '' }
              }

              await servicos.federacoes.doc(idFederacao).collection('filiacoes').doc(idFiliacao).update(dados)

            }
            if (arquivo === 'foto') {
              dados = {
                ...dados,
                urlImagemFoto: downloadURL,
                statusImagemFoto: 1,
                nomeImagemFoto: _imagem.name,
                dtAnexoFoto: firebase.firestore.Timestamp.fromDate(new Date())
              }
              if (filiacao.statusImagemFoto === 3) {
                dados = { ...dados, observacaoFoto: '' }
              }
              await servicos.federacoes.doc(idFederacao).collection('filiacoes').doc(idFiliacao).update(dados)

            }

            if (arquivo === 'comprovante') {

              dados = {
                urlComprovantePgto: downloadURL,
                nomeImagemComprovantePgto: _imagem.name,
                dtAnexo: firebase.firestore.Timestamp.fromDate(new Date()),
                status: 1
              }

              if (pagamentoPendente.status === 3) {
                dados = { ...dados, observacaoComprovante: '' }
              }

              await servicos.federacoes.doc(idFederacao).collection('filiacoes').doc(idFiliacao).collection('pagamentos').doc(idPagamento).update(dados)

            }
          }
          resolve(atualizarDados());
        } catch (error) {
          reject(error);
        }

      });

    });

  }

  async function salvarFiliacao() {

    mensagemLoading('Salvando filiação...');
    setLoading(true);

    await new Promise((resolve) => {
      setTimeout(resolve, 1)
    })

    if (!usuarioFiliacao.id) {
      mensagemAlerta('Por favor, selecione um usuário para a filiação');
      return;
    }
    if (!graduacao) {
      mensagemAlerta('Por favor, selecione uma Graduação');
      return;
    }

    if (federacao?.carteirinha?.carteirinhaFisica == 1 && federacao?.carteirinha?.gerarPedidoComAFiliacao == '1' && federacao?.carteirinha?.opcaoEntrega == '3' && !opcaoEntregaSelecionado) {
      mensagemAlerta('Por favor, selecione uma opção para entrega da carteirnha.');
      return;
    }

    if (!filiacao.id) {
      if (!uploadArquivoRg) {
        mensagemAlerta('Por favor, anexe uma foto do seu RG (Frente e Verso)');
        return;
      }
      if (!uploadArquivoFoto) {
        mensagemAlerta('Por favor, anexe uma foto do filiado');
        return;
      }

    } else if (usuarioFiliacao.id) {

      if (filiacao.statusImagemRg === 3 && !uploadArquivoRg) {
        mensagemAlerta('Por favor, anexe uma nova foto do RG/CNH');
        return;
      }
      if (filiacao.statusImagemFoto === 3 && !uploadArquivoFoto) {
        mensagemAlerta('Por favor, anexe uma nova foto do Filiado');
        return;
      }
      if (pagamentoPendente?.status === 3 && !uploadArquivoComprovante) {
        mensagemAlerta('Por favor, anexe uma nova foto do comprovante de pagamento');
        return;
      }
      if (filiacao.statusImagemRg === 4 && !uploadArquivoRg) {
        mensagemAlerta('Por favor, anexe uma nova foto do RG/CNH para renovar sua filiação.');
        return;
      }
      if (filiacao.statusImagemFoto === 4 && !uploadArquivoFoto) {
        mensagemAlerta('Por favor, anexe uma nova foto do Filiado para renovar sua filiação.');
        return;
      }
      if (filiacao.statusFiliacao === 4 && !uploadArquivoComprovante) {
        mensagemAlerta('Por favor, anexe uma nova foto do comprovante de pagamento para renovar sua filiação.');
        return;
      }

    }

    if (!concordoTermo) {
      mensagemAlerta('Para concluir a filiação, leia e confirme os termos de uso.');
      return;
    }



    let dados = {
      idFiliado: usuarioFiliacao.id,
      nomeFiliado: usuarioFiliacao.nome + ' ' + usuarioFiliacao.sobrenome,
      urlAvatarFiliado: usuarioFiliacao.avatar,
      cpfFiliado: usuarioFiliacao.cpf,
      emailFiliado: usuarioFiliacao.email,
      idResponsavel: usuarioFiliacao?.responsavel ? usuarioFiliacao.responsavel : '',
      graduacao: graduacao,
      dtCadastro: filiacao?.dtCadastro ? filiacao.dtCadastro : firebase.firestore.Timestamp.fromDate(new Date()),
      tipoUsuario: usuarioFiliacao.tipoUsuario,
      concordoTermo: concordoTermo
    }

    if (
      federacao?.carteirinha?.carteirinhaFisica == 1 &&
      federacao?.carteirinha?.gerarPedidoComAFiliacao == '1' &&
      federacao?.carteirinha?.opcaoEntrega == '3' &&
      opcaoEntregaSelecionado) {
      dados = {
        ...dados,
        opcaoEntregaSelecionado: opcaoEntregaSelecionado
      }
    }


    if (!filiacao.id) {

      const dadosFederacao = (await servicos.federacoes.doc(idFederacao).get()).data()

      dados = {
        ...dados,
        statusFiliacao: 1,
        numeroFiliacao: dadosFederacao.ultimafiliacao ? dadosFederacao.ultimafiliacao + 1 : 1
      }

      const docRef = await servicos.federacoes.doc(idFederacao).collection('filiacoes').add(dados);

      await servicos.federacoes.doc(idFederacao).collection('filiacoes').doc(docRef.id).update({ id: docRef.id })

      await servicos.federacoes.doc(idFederacao).update({
        ultimafiliacao: dadosFederacao.ultimafiliacao ? dadosFederacao.ultimafiliacao + 1 : 1
      })

      await cadastraImagem(docRef.id, 'rg', arquivoRg, null)

      await cadastraImagem(docRef.id, 'foto', arquivoFoto, null)

      if (federacao?.regrasFiliacao?.tipoFiliacao === 2) {

        // Filiação Paga

        let dadosPgto = {
          status: 1,
          tipoPagamento: 'manual',
          valorFiliacao: parseFloat(federacao.regrasFiliacao.valorPagamento),
          valorTotal: valorTotal()
        }

        if (federacao?.carteirinha?.carteirinhaFisica == 1 &&
          federacao?.carteirinha?.tipoAquisicao == '1' &&
          federacao?.carteirinha?.gerarPedidoComAFiliacao == '1'
        ) {

          dadosPgto = {
            ...dadosPgto,
            valorCarteirinha: parseFloat(federacao?.carteirinha?.valorCarteirinha)
          }

          if (
            (federacao?.carteirinha?.opcaoEntrega == '2' || (federacao?.carteirinha?.opcaoEntrega == '3' && opcaoEntregaSelecionado == '2')) && federacao?.carteirinha?.cobrarTaxaEnvio === '1'
          ) {
            dadosPgto = {
              ...dadosPgto,
              valorTaxaEnvio: parseFloat(federacao?.carteirinha?.valorTaxaEnvio)
            }
          }

        }



        const pgtoDocRef = await servicos.federacoes.doc(idFederacao).collection('filiacoes').doc(docRef.id).collection('pagamentos').add(dadosPgto)

        await servicos.federacoes.doc(idFederacao).collection('filiacoes').doc(docRef.id).collection('pagamentos').doc(pgtoDocRef.id).update({ id: pgtoDocRef.id })

        await cadastraImagem(docRef.id, 'comprovante', arquivoComprovante, pgtoDocRef.id)

        // Gerar pedido de carteirinha
        if (federacao?.carteirinha?.carteirinhaFisica === 1 && federacao?.carteirinha?.gerarPedidoComAFiliacao == '1') {
          let dadosCarteirinha = {
            status: 1,
            valor: 0,
            dtPedido: firebase.firestore.Timestamp.fromDate(new Date()),
            idFiliacao: docRef.id,
            idFederacao: idFederacao,
            idFiliado: usuarioFiliacao.id,
            urlAvatarFiliado: usuarioFiliacao.avatar,
            nomeFiliado: `${usuarioFiliacao.nome} ${usuarioFiliacao.sobrenome}`,
            cpfFiliado: usuarioFiliacao.cpf
          }

          if (federacao?.carteirinha?.carteirinhaFisica == 1 && federacao?.carteirinha?.gerarPedidoComAFiliacao == '1' && federacao?.carteirinha?.opcaoEntrega == '3' && opcaoEntregaSelecionado) {
            dadosCarteirinha = {
              ...dadosCarteirinha,
              opcaoEntregaSelecionado: opcaoEntregaSelecionado
            }
          }

          const carteirinhaDocRef = await servicos.filiacoes.doc(docRef.id).collection('carteirinhas').add(dadosCarteirinha)

          await servicos.filiacoes.doc(docRef.id).collection('carteirinhas').doc(carteirinhaDocRef.id).update({ id: carteirinhaDocRef.id })

        }


      }

      // Atualizar cadastro de usuário para adicionar as filiações

      if (['competidor', 'professor'].includes(usuarioFiliacao?.tipoUsuario)) {

        if (usuarioFiliacao?.filiacoes?.length > 0) {
          await servicos.usuarios.doc(usuarioFiliacao.id).update({
            filiacoes: [...usuarioFiliacao.filiacoes, idFederacao]
          })

        } else if (usuarioFiliacao?.filiacoes?.length === 0 || !usuarioFiliacao?.filiacoes) {
          await servicos.usuarios.doc(usuarioFiliacao.id).update(
            {
              filiacoes: [idFederacao]
            }
          )

        }

      } else if (usuarioFiliacao?.tipoUsuario === 'aluno' && usuarioFiliacao?.responsavel === usuario.id) {

        if (usuarioFiliacao?.filiacoes?.length > 0) {
          await servicos.usuarios.doc(usuario.id).collection('competidores').doc(usuarioFiliacao.id).update({
            filiacoes: [...usuarioFiliacao.filiacoes, idFederacao]
          })
        } else if (!usuarioFiliacao?.filiacoes || usuarioFiliacao?.filiacoes?.length === 0) {
          await servicos.usuarios.doc(usuario.id).collection('competidores').doc(usuarioFiliacao.id).update({
            filiacoes: [idFederacao]
          })
        }

      }



      let texto = 'Sua solicitação para filiação foi gerada e a Federação irá avaliar sua documentação em breve. Para acompanhar o andamento basta acessar novamente a página da federação.'

      Swal.fire('Concluído', texto, 'success').then(() => {
        window.location.href = window.location.href;
      })


      setLoading(false);

    } else if (filiacao.id) {

      dados = { ...dados, statusFiliacao: statusFiliacao === 2 ? 2 : 1 }


      await servicos.federacoes.doc(idFederacao).collection('filiacoes').doc(filiacao.id).update(dados)

      if (uploadArquivoRg) {
        await cadastraImagem(filiacao.id, 'rg', arquivoRg, null)
      }
      if (uploadArquivoFoto) {
        await cadastraImagem(filiacao.id, 'foto', arquivoFoto, null)
      }


      if (uploadArquivoComprovante && federacao?.regrasFiliacao?.tipoFiliacao === 2) {

        if (pagamentoPendente.id) {
          await cadastraImagem(filiacao.id, 'comprovante', arquivoComprovante, pagamentoPendente.id)
        } {

          // Filiação Paga

          let dadosPgto = {
            status: 1,
            tipoPagamento: 'manual',
            valorFiliacao: parseFloat(federacao.regrasFiliacao.valorPagamento),
            valorTotal: valorTotal()
          }

          if (federacao?.carteirinha?.carteirinhaFisica == 1 &&
            federacao?.carteirinha?.tipoAquisicao == '1' &&
            federacao?.carteirinha?.gerarPedidoComAFiliacao == '1'
          ) {

            dadosPgto = {
              ...dadosPgto,
              valorCarteirinha: parseFloat(federacao?.carteirinha?.valorCarteirinha)
            }

            if (
              (federacao?.carteirinha?.opcaoEntrega == '2' || (federacao?.carteirinha?.opcaoEntrega == '3' && opcaoEntregaSelecionado == '2')) && federacao?.carteirinha?.cobrarTaxaEnvio === '1'
            ) {
              dadosPgto = {
                ...dadosPgto,
                valorTaxaEnvio: parseFloat(federacao?.carteirinha?.valorTaxaEnvio)
              }
            }

          }
          // Em que situação eu vou incluir pagamento? R: quando não houver filiação ou quando houver filiação não houver pagamento pendente.
          // Se houver pagamento pendente vou só dar update nele
          if (pagamentoPendente.id) {
            await servicos.federacoes.doc(idFederacao).collection('filiacoes').doc(filiacao.id).collection('pagamentos').doc(pagamentoPendente.id).update(dadosPgto)

            await cadastraImagem(filiacao.id, 'comprovante', arquivoComprovante, pagamentoPendente.id)
          } else {
            const pgtoDocRef = await servicos.federacoes.doc(idFederacao).collection('filiacoes').doc(filiacao.id).collection('pagamentos').add(dadosPgto)

            await servicos.federacoes.doc(idFederacao).collection('filiacoes').doc(filiacao.id).collection('pagamentos').doc(pgtoDocRef.id).update({ id: pgtoDocRef.id })

            await cadastraImagem(filiacao.id, 'comprovante', arquivoComprovante, pgtoDocRef.id)
          }


          if (statusFiliacao === 4) {
            // Gerar novo pedido de carteirinha quando a filiação expirar
            if (federacao?.carteirinha?.carteirinhaFisica === 1 && federacao?.carteirinha?.gerarPedidoComAFiliacao == '1') {
              let dadosCarteirinha = {
                status: 1,
                valor: 0,
                dtPedido: firebase.firestore.Timestamp.fromDate(new Date()),
                idFiliacao: filiacao.id,
                idFederacao: idFederacao,
                idFiliado: usuarioFiliacao.id,
                urlAvatarFiliado: usuarioFiliacao.avatar,
                nomeFiliado: `${usuarioFiliacao.nome} ${usuarioFiliacao.sobrenome}`,
                cpfFiliado: usuarioFiliacao.cpf
              }

              if (
                federacao?.carteirinha?.carteirinhaFisica == 1 &&
                federacao?.carteirinha?.gerarPedidoComAFiliacao == '1' &&
                federacao?.carteirinha?.opcaoEntrega == '3' && opcaoEntregaSelecionado) {
                dadosCarteirinha = {
                  ...dadosCarteirinha,
                  opcaoEntregaSelecionado: opcaoEntregaSelecionado
                }
              }

              const carteirinhaDocRef = await servicos.filiacoes.doc(filiacao.id).collection('carteirinhas').add(dadosCarteirinha)

              await servicos.filiacoes.doc(filiacao.id).collection('carteirinhas').doc(carteirinhaDocRef.id).update({ id: carteirinhaDocRef.id })

            }
          }

        }
      }

      let texto = ''
      if (filiacao.id && statusFiliacao === 4) {
        texto = 'A renovação da sua filiação foi solicitada e a Federação irá avaliar sua documentação em breve. Para acompanhar o andamento basta acessar novamente a página da federação .'
      }
      if (filiacao.id && statusFiliacao === 3) {
        texto = 'Documentação reenviada com sucesso. A Federação irá avaliar sua documentação em breve. Para acompanhar o andamento basta acessar novamente esta página .'
      }

      if (filiacao.id && [1, 2].includes(statusFiliacao)) {
        texto = 'Dados alterados com sucesso. A Federação irá avaliar suas alterações. Para acompanhar o andamento basta acessar novamente esta página.'
      }

      Swal.fire('Concluído', texto, 'success').then(() => {
        window.location.href = window.location.href;
      })


      setLoading(false);

    }


  }

  function converterData(data) {
    return moment(new firebase.firestore.Timestamp(data.seconds, data.nanoseconds).toDate()).format('DD-MM-YYYY HH:mm')
  }


  function converterDataVencimento(data) {
    const _data = moment(new firebase.firestore.Timestamp(data.seconds, data.nanoseconds).toDate()).format('DD-MM-YYYY').toString()
    const [dia, mes, ano] = _data.split('-')

    return [dia, mes, (parseInt(ano) + 1).toString()].join('-')
  }

  async function excluirFiliacao() {

    const text =
      filiacao.statusFiliacao === 2 ? 'Atenção: A sua inscrição está aprovada e <span  style="color:green; font-weight:bold;">Ativa</span>. Se excluir não poderá mais usufruir dos benefíficios e vantagems da Filiação. Confirma a exclusão mesmo assim?' :
        filiacao.statusFiliacao === 1 ?
          'Atenção: A sua inscrição está com situação <span  style="color:orange; font-weight:bold;">Pendente</span>, o que significa que a documentação anexada ainda não foi aprovada. Deseja realizar a exclusão mesmo assim?' :
          'A filiação será desvinculada do seu cadastro. Caso queira filiar-se novamente, basta preencher com os documentos necessários para a filiação.';

    const result = await Swal.fire({
      title: 'Confirma exclusão da Filiação?',
      html: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar'
    })

    if (!result.value) {
      return;
    }

    try {
      // excluir Filiação
      // Remover a referencia da federação da collection usuarios

      await servicos.federacoes.doc(idFederacao).collection('filiacoes').doc(filiacao.id).delete();

      if (['competidor', 'responsavel', 'professor'].includes(usuarioFiliacao.tipoUsuario)) {
        await servicos.usuarios.doc(usuarioFiliacao.id).update({
          filiacoes: usuarioFiliacao.filiacoes.filter(fdr => fdr !== idFederacao)
        })
      } else if (usuarioFiliacao.tipoUsuario === 'aluno') {
        servicos.usuarios.doc(usuario.id).collection('competidores').doc(usuarioFiliacao.id).update({
          filiacoes: usuarioFiliacao.filiacoes.filter(fdr => fdr !== idFederacao)
        })
      }

      // remover os arquivos anexados

      let caminho = `federacoes/${idFederacao}/filiacoes/${filiacao.id}`

      let metadataRg = null

      try {
        await firebase.storage().ref(`${caminho}/rg`).getMetadata().then(metadata => metadataRg = metadata)
      } catch (error) {
        console.log('Erro ao excluir rg: ', error);
      }

      if (metadataRg) {
        await firebase.storage().ref(`${caminho}/rg`).delete();
      }

      let metadataFoto = null
      try {
        await firebase.storage().ref(`${caminho}/foto`).getMetadata().then(metadata => metadataFoto = metadata)

      } catch (error) {
        console.log('Erro ao excluir foto: ', error);
      }

      if (metadataFoto) {
        await firebase.storage().ref(`${caminho}/foto`).delete();

      }


      if (pagamentosAprovados?.length > 0) {
        for (let pagamento of pagamentosAprovados) {
          let metadataComprovante = null
          try {
            metadataComprovante = await firebase.storage().ref(`${caminho}/pagamentos/${pagamento.id}`).getMetadata()

          } catch (error) {
            console.log('Erro ao excluir pagamento: ', error.message);

          }

          if (metadataComprovante) {
            await firebase.storage().ref(`${caminho}/pagamentos/${pagamento.id}`).delete();

          }
        }
      }

      if (pagamentoPendente.id) {
        let metadataComprovante = null

        try {
          metadataComprovante = await firebase.storage().ref(`${caminho}/pagamentos/${pagamentoPendente.id}`).getMetadata()

        } catch (error) {
          console.log('Erro ao excluir pagamento: ', error.message);

        }

        if (metadataComprovante) {
          await firebase.storage().ref(`${caminho}/pagamentos/${pagamentoPendente.id}`).delete();
        }
      }


      Swal.fire('Sucesso', 'Filiação excluída com sucesso!', 'success').then(() => {
        window.location = '/federacoes'
      })

    } catch (error) {
      mensagemErro('Não foi possível concluír a exclusão. Entre em contato com o suporte.')
      console.log('erro: ', error.message);
    }



  }

  function valorTotal() {
    let valor = 0;

    if (federacao?.regrasFiliacao?.tipoFiliacao === 2) {
      valor += parseFloat(federacao?.regrasFiliacao?.valorPagamento)
    }

    if (
      federacao?.carteirinha?.carteirinhaFisica == 1 &&
      federacao?.carteirinha?.tipoAquisicao == '1' &&
      federacao?.carteirinha?.gerarPedidoComAFiliacao == '1'
    ) {
      valor += parseFloat(federacao?.carteirinha?.valorCarteirinha)
    }

    if (
      federacao?.carteirinha?.carteirinhaFisica == 1 &&
      federacao?.carteirinha?.tipoAquisicao == '1' &&
      federacao?.carteirinha?.gerarPedidoComAFiliacao == '1' &&
      ((federacao?.carteirinha?.opcaoEntrega == '2' || (federacao?.carteirinha?.opcaoEntrega == '3' && opcaoEntregaSelecionado == '2')) &&
        federacao?.carteirinha?.cobrarTaxaEnvio === '1')
    ) {
      valor += parseFloat(federacao?.carteirinha?.valorTaxaEnvio)
    }

    return valor;

  }

  function exibirInputComprovante() {
    return (
      federacao?.regrasFiliacao?.tipoFiliacao === 2 &&
      ((filiacao.statusFiliacao !== 2 &&
        pagamentoPendente.id) ||
        !filiacao.id || filiacao.statusFiliacao === 4 && !pagamentoPendente.id)
    )
  }

  return (
    <div className="body-wrapper blank-loder">
      <Header />
      <div class="section_fullwidth">
        <div class="headline headline_img">
          <div class="headline__inner">
            <div class="headline__title">
              <h1>Filiação</h1>
              {filiacao.numeroFiliacao && (
                <p style={{ position: 'relative', top: '-40px', left: '278px', fontSize: '21px' }}>Nº {filiacao.numeroFiliacao.toString().padStart(4, '0')}</p>
              )}
            </div>
            <div class="headline__description">
              <span>Faça aqui a sua filiação ou acompanhe o seu andamento</span>
            </div>

            {filiacao?.id && (
              <>
                <p style={{ position: 'relative', top: '44px', left: '5px', fontSize: '21px' }}><b>Situação:
                  {filiacao.statusFiliacao === 1 && (<span style={{ color: 'orange' }}> Aguardando aprovação da documentação</span>)}
                  {filiacao.statusFiliacao === 2 && (<span style={{ color: 'green' }}> Aprovada</span>)}
                  {filiacao.statusFiliacao === 3 && (<span style={{ color: 'red' }}> Documentação Reprovada</span>)}
                  {filiacao.statusFiliacao === 4 && (<span style={{ color: 'red' }}> Vencida (pendente de renovação)</span>)}
                </b></p>



              </>
            )}
          </div>

        </div>
      </div>
      <div class="section_default section-single-class-layout clearfix">
        <main class="section_default">
          <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
            <div class="wpb_column vc_column_container vc_col-sm-12">
              <div class="vc_column-inner ">
                <div class="wpb_wrapper">
                  <div class="wpb_text_column wpb_content_element ">
                    <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                    </div>

                    <div style={{ border: '1px solid rgba(0, 0, 0, .125)', borderRadius: '5px', padding: '15px' }}>

                      <div style={{ display: 'flex' }}>

                        <div class=" vc_col-sm-4">
                          <div class="wpb_single_image wpb_content_element vc_align_left">
                            <figure class="wpb_wrapper vc_figure">
                              <div class="vc_single_image-wrapper package-style-1   vc_box_border_grey">
                                <img width="1920" height="1401"
                                  src={federacao?.urlImagemFederacao}
                                  class="vc_single_image-img attachment-full" alt=""
                                  srcset={federacao?.urlImagemFederacao}
                                  sizes="(max-width: 1920px) 100vw, 1920px" /></div>
                            </figure>

                          </div>
                        </div>

                        <div class="wpb_column vc_column_container vc_col-sm-8">

                          <div class="vc_column-inner ">
                            <div class="wpb_wrapper">
                              <div class="wpb_widgetised_column wpb_content_element">
                                <div class="wpb_wrapper">
                                  <div style={{ marginLeft: '10vw' }} id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                    <h3>Dados da Federação / Agremiação</h3>
                                  </div>
                                  <div role="form" class="row " >
                                    <p class="contact-form-name vc_col-sm-12">
                                      <span class="wpcf7-form-control-wrap your-name">
                                        <h6>Nome</h6>
                                        <input
                                          type="text" name="your-name" size="40"
                                          class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                          aria-required="true" aria-invalid="false"
                                          value={federacao?.nome}
                                          disabled
                                          placeholder="Nome *" style={{ background: '#F5F5F5' }} />
                                      </span>
                                    </p>

                                    <p class="contact-form-name vc_col-sm-2">
                                      <span class="wpcf7-form-control-wrap your-name">
                                        <h6>DDD</h6>
                                        <input
                                          type="text" name="your-name" size="40"
                                          class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                          aria-required="true" aria-invalid="false"
                                          value={federacao?.ddd}
                                          disabled
                                          placeholder="DDD *" style={{ background: '#F5F5F5' }} />
                                      </span>
                                    </p>
                                    <p class="contact-form-name vc_col-sm-3">
                                      <span class="wpcf7-form-control-wrap your-name">
                                        <h6>Telefone</h6>
                                        <input
                                          type="text" name="your-name" size="40"
                                          class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                          aria-required="true" aria-invalid="false"
                                          value={federacao?.telefone}
                                          disabled
                                          placeholder="Celular *" style={{ background: '#F5F5F5' }} />
                                      </span>
                                    </p>
                                    <p class="contact-form-name vc_col-sm-7">
                                      <span class="wpcf7-form-control-wrap your-name">
                                        <h6>Email</h6>
                                        <input
                                          type="text" name="your-name" size="40"
                                          class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                          aria-required="true" aria-invalid="false"
                                          value={federacao?.email}
                                          disabled
                                          placeholder="Celular *" style={{ background: '#F5F5F5' }} />
                                      </span>
                                    </p>
                                    <p class="contact-form-name vc_col-sm-6">
                                      <span class="wpcf7-form-control-wrap your-name">
                                        <h6>Cidade</h6>
                                        <input
                                          type="text" name="your-name" size="40"
                                          class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                          aria-required="true" aria-invalid="false"
                                          value={federacao?.cidade}
                                          disabled
                                          placeholder="Cidade" style={{ background: '#F5F5F5' }} />
                                      </span>
                                    </p>
                                    <p class="contact-form-name vc_col-sm-2">
                                      <span class="wpcf7-form-control-wrap your-name">
                                        <h6>UF</h6>
                                        <input
                                          type="text" name="your-name" size="40"
                                          class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                          aria-required="true" aria-invalid="false"
                                          value={federacao?.uf}
                                          disabled
                                          style={{ background: '#F5F5F5' }} />
                                      </span>
                                    </p>
                                    <p class="contact-form-name vc_col-sm-4">
                                      <span class="wpcf7-form-control-wrap your-name">
                                        <h6>Modalidade</h6>
                                        <input
                                          type="text" name="your-name" size="40"
                                          class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                          aria-required="true" aria-invalid="false"
                                          value={modalidade}
                                          disabled
                                          style={{ background: '#F5F5F5' }} />
                                      </span>
                                    </p>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>

                      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '50px' }}>
                        {/* Dados Bancários - Início */}
                        {federacao?.regrasFiliacao?.tipoFiliacao === 2 && (
                          <div class="wpb_column vc_column_container vc_col-sm-12">

                            <div class="vc_column-inner ">
                              <div class="wpb_wrapper">
                                <div class="wpb_widgetised_column wpb_content_element">
                                  <div class="wpb_wrapper">

                                    <div role="form" class="row " >

                                      <h4 style={{ textAlign: 'center', display: 'block' }}>*Dados Bancários</h4>

                                      <p class="contact-form-name vc_col-sm-8">
                                        <span class="wpcf7-form-control-wrap your-name">
                                          <h6>Nome Favorecido</h6>
                                          <input
                                            type="text" name="your-name" size="40"
                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                            aria-required="true" aria-invalid="false"
                                            value={federacao?.dadosBancarios?.favorecido}
                                            disabled
                                            style={{ background: '#F5F5F5' }} />
                                        </span>
                                      </p>
                                      <p class="contact-form-name vc_col-sm-4">
                                        <span class="wpcf7-form-control-wrap your-name">
                                          <h6>CPF/CNPJ</h6>
                                          <input
                                            type="text" name="your-name" size="40"
                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                            aria-required="true" aria-invalid="false"
                                            value={federacao?.dadosBancarios?.cpfCnpj}
                                            disabled
                                            style={{ background: '#F5F5F5' }} />
                                        </span>
                                      </p>
                                      <p class="contact-form-name vc_col-sm-4">
                                        <span class="wpcf7-form-control-wrap your-name">
                                          <h6>Agência</h6>
                                          <input
                                            type="text" name="your-name" size="40"
                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                            aria-required="true" aria-invalid="false"
                                            value={federacao?.dadosBancarios?.agencia}
                                            disabled style={{ background: '#F5F5F5' }} />
                                        </span>
                                      </p>
                                      <p class="contact-form-name vc_col-sm-4">
                                        <span class="wpcf7-form-control-wrap your-name">
                                          <h6>Conta</h6>
                                          <input
                                            type="text" name="your-name" size="40"
                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                            aria-required="true" aria-invalid="false"
                                            value={federacao?.dadosBancarios?.conta}
                                            disabled style={{ background: '#F5F5F5' }} />
                                        </span>
                                      </p>
                                      <p class="contact-form-name vc_col-sm-4">
                                        <span class="wpcf7-form-control-wrap your-name">
                                          <h6>Banco</h6>
                                          <input
                                            type="text" name="your-name" size="40"
                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                            aria-required="true" aria-invalid="false"
                                            value={federacao?.dadosBancarios?.banco}
                                            disabled style={{ background: '#F5F5F5' }} />
                                        </span>
                                      </p>
                                      <p class="contact-form-name vc_col-sm-3">
                                        <span class="wpcf7-form-control-wrap your-name">
                                          <h6>Tipo Chave Pix</h6>
                                          <input
                                            type="text" name="your-name" size="40"
                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                            aria-required="true" aria-invalid="false"
                                            value={federacao?.dadosBancarios?.tipoChavePix}
                                            disabled style={{ background: '#F5F5F5' }} />
                                        </span>
                                      </p>
                                      <p class="contact-form-name vc_col-sm-9">
                                        <span class="wpcf7-form-control-wrap your-name">
                                          <h6>Chave Pix</h6>
                                          <input
                                            type="text" name="your-name" size="40"
                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                            aria-required="true" aria-invalid="false"
                                            value={federacao?.dadosBancarios?.chavePix}
                                            disabled style={{ background: '#F5F5F5' }} />
                                        </span>
                                      </p>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* Dados Bancários - Fim */}
                      </div>

                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '50px' }}>
                      <div style={{ border: '1px solid rgba(0, 0, 0, .125)', borderRadius: '5px', padding: '15px' }}>
                        <div className='wpb_row row'>

                          <div class="wpb_column vc_column_container vc_col-sm-12">

                            <div class="vc_column-inner ">
                              <div class="wpb_wrapper">
                                <div class="wpb_widgetised_column wpb_content_element">
                                  <div class="wpb_wrapper">
                                    <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix" style={{ margin: '25px 0', textAlign: 'center' }}>
                                      <h3>Dados do Filiado</h3>
                                    </div>
                                    <div role="form" class="row " >

                                      {usuario.usuario === 2 && (
                                        <p class="contact-form-name vc_col-sm-5">
                                          <span class="wpcf7-form-control-wrap your-name">
                                            <h6>Nome</h6>
                                            <input
                                              type="text" name="your-name" size="40"
                                              class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                              aria-required="true" aria-invalid="false"
                                              value={usuarioFiliacao.nome + " " + usuarioFiliacao.sobrenome}
                                              disabled
                                              placeholder="Nome *" style={{ background: '#F5F5F5' }} />
                                          </span>
                                        </p>
                                      )}

                                      {usuario.usuario === 3 && (
                                        <p class="contact-form-name vc_col-sm-5">
                                          <span class="wpcf7-form-control-wrap your-name"
                                          >
                                            <h6>Nome</h6>
                                            <select name="select" id="select"
                                              className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" style={{ minWidth: '100%' }} onChange={handleCompetidor} >
                                              <option value="">Selecione o filiado </option>
                                              <option value="eu">{usuario.nome} - (Responsável) </option>
                                              {competidores.sort((a, b) => a.nome - b.nome).map((c, index) =>
                                                <option value={index} >{c.nome} {c.sobrenome} - {c.cpf}</option>
                                              )}
                                            </select>
                                          </span>
                                        </p>
                                      )}

                                      <p class="contact-form-name vc_col-sm-3">
                                        <span class="wpcf7-form-control-wrap your-name">
                                          <h6>CPF</h6>
                                          <input
                                            type="text" name="your-name" size="40"
                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                            aria-required="true" aria-invalid="false"
                                            value={usuarioFiliacao?.cpf ? usuarioFiliacao.cpf : ''}
                                            disabled
                                            placeholder="Cpf *" style={{ background: '#F5F5F5' }} />
                                        </span>
                                      </p>
                                      <p class="contact-form-name vc_col-sm-2">
                                        <span class="wpcf7-form-control-wrap your-name">
                                          <h6>Sexo</h6>
                                          <input
                                            type="text" name="your-name" size="40"
                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                            aria-required="true" aria-invalid="false"
                                            value={usuarioFiliacao?.sexo ? usuarioFiliacao.sexo : ''}
                                            disabled
                                            placeholder="Sexo *" style={{ background: '#F5F5F5' }} />
                                        </span>
                                      </p>
                                      <p class="contact-form-name vc_col-sm-2">
                                        <span class="wpcf7-form-control-wrap your-name">
                                          <h6>Idade</h6>
                                          <input
                                            type="text" name="your-name" size="40"
                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                            aria-required="true" aria-invalid="false"
                                            value={idade ? `${idade} anos` : ''}
                                            disabled
                                            placeholder="Idade *" style={{ background: '#F5F5F5' }} />
                                        </span>
                                      </p>
                                      <p class="contact-form-name vc_col-sm-3">
                                        <span class="wpcf7-form-control-wrap your-name">
                                          <h6>Tipo</h6>
                                          <input
                                            type="text" name="your-name" size="40"
                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                            aria-required="true" aria-invalid="false"
                                            value={usuarioFiliacao?.tipoUsuario ? usuarioFiliacao.tipoUsuario : ''}
                                            disabled
                                            placeholder="Tipo *" style={{ background: '#F5F5F5' }} />
                                        </span>
                                      </p>
                                      <p class="contact-form-name vc_col-sm-3">
                                        <span class="wpcf7-form-control-wrap your-name">
                                          <h6>Sua Equipe</h6>
                                          <input type='text' name="select"
                                            class="form-control"
                                            value={equipe ? equipe : ''}
                                            disabled
                                            placeholder={!usuarioFiliacao.id ? 'Equipe *' : 'Sem Equipe'}
                                            style={{ background: '#F5F5F5' }} />

                                        </span>
                                      </p>
                                      <p class="contact-form-name vc_col-sm-3">
                                        <span class="wpcf7-form-control-wrap your-name">
                                          <h6>Seu Professor</h6>
                                          <input
                                            type="text" name="your-name" size="40"
                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                            aria-required="true" aria-invalid="false"
                                            value={professor}
                                            disabled
                                            placeholder={!usuarioFiliacao.id ? 'Professor *' : 'Sem Professor'} style={{ background: '#F5F5F5' }} />
                                        </span>
                                      </p>
                                      <p class="contact-form-name vc_col-sm-3">
                                        <span class="wpcf7-form-control-wrap your-name"
                                        >
                                          <h6>Graduação</h6>
                                          <select name="select" id="select"
                                            className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" style={{ minWidth: '100%' }} onChange={(e) => setGraduacao(e.target.value)} value={graduacao} >
                                            <option value="">Selecione a graduação </option>
                                            {graduacoes.sort((a, b) => a.posicao - b.posicao).map((faixa) =>
                                              <option value={faixa.descricao} selected={faixa.descricao === graduacao}>{faixa.descricao}</option>
                                            )}
                                          </select>
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>


                        </div>

                      </div>


                    </div>


                    {/* Dados da Filiação - Início */}

                    {usuarioFiliacao.id && (
                      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '50px' }}>
                        <div style={{ border: '1px solid rgba(0, 0, 0, .125)', borderRadius: '5px', padding: '15px' }}>

                          <div className=' wpb_row row'>

                            <div class="wpb_column vc_column_container vc_col-sm-12">



                              <div class="vc_column-inner">
                                <div class="wpb_wrapper">
                                  <div class="wpb_widgetised_column wpb_content_element">
                                    <div class="wpb_wrapper">
                                      <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix" style={{ margin: '25px 0', textAlign: 'center' }}>
                                        <h3>Anexar documentos para a filiação</h3>
                                      </div>

                                      {/* Tabela Extrato */}
                                      <table style={{ width: "100%", marginTop: 20 }}>
                                        <thead>
                                          <tr >
                                            <th class="schedule-table__day">Extrato</th>
                                          </tr>
                                        </thead>
                                      </table>
                                      <table style={{ width: "100%" }} className="3">
                                        <tbody>

                                          <tr>
                                            <td>Filiação</td>
                                            <td>{parseFloat(federacao?.regrasFiliacao?.valorPagamento).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                          </tr>
                                          <tr>
                                            <td>Carteirinha</td>
                                            <td>{parseFloat(federacao?.carteirinha?.valorCarteirinha).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                          </tr>
                                          {((federacao?.carteirinha?.opcaoEntrega == '2' || (federacao?.carteirinha?.opcaoEntrega == '3' && opcaoEntregaSelecionado == '2'))
                                            && federacao?.carteirinha?.cobrarTaxaEnvio === '1') && (
                                              <tr>
                                                <td>Taxa de Envio</td>
                                                <td>{parseFloat(federacao?.carteirinha?.valorTaxaEnvio).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                              </tr>
                                            )}

                                          <tr>
                                            <td><b>Total a Pagar</b></td>
                                            <td><b>{valorTotal().toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</b></td>
                                          </tr>

                                        </tbody>
                                      </table>
                                      {/* Tabela Extrato - Fim */}
                                      {(federacao?.carteirinha?.carteirinhaFisica === 1 &&
                                        federacao?.carteirinha?.opcaoEntrega === '3' && federacao?.carteirinha?.gerarPedidoComAFiliacao === '1') && (
                                          <div style={{ border: '1px solid rgba(0, 0, 0, .125)', borderRadius: '5px', padding: '5px 14px', width: '100%', marginTop: '15px' }}>
                                            <FormControl component="fieldset" disabled={([1, 2, 3].includes(filiacao?.id && filiacao?.statusFiliacao))}>
                                              <h6>Selecione uma opção de recebimento da carteirinha</h6>
                                              <RadioGroup
                                                aria-label="options"
                                                name="options"
                                                value={opcaoEntregaSelecionado}
                                                onChange={(e) => setOpcaoEntregaSelecionado(e.target.value)}
                                                style={{ display: 'flex', flexDirection: 'row' }}
                                              >
                                                <FormControlLabel value="1" control={<Radio />} label="Retirar na Federação" />
                                                <FormControlLabel value="2" control={<Radio />} label="Enviar ao Filiado" />

                                              </RadioGroup>
                                            </FormControl>

                                            {opcaoEntregaSelecionado == '1' && (
                                              <div className='col-md-12' style={{ marginLeft: '80px' }}>
                                                <b>Endereço: </b>{`${federacao?.carteirinha?.enderecoRetirada?.endereco}, ${federacao?.carteirinha?.enderecoRetirada?.numero}, `}
                                                {federacao?.carteirinha?.enderecoRetirada?.complemento && (<><b>Complemento: </b>{federacao?.carteirinha?.enderecoRetirada?.complemento} </>)}
                                                <b>Bairro: </b> {`${federacao?.carteirinha?.enderecoRetirada?.bairro}, ${federacao?.cidade} - ${federacao?.uf}, `}<b> CEP: </b>{`${federacao?.carteirinha?.enderecoRetirada?.cep}`}
                                              </div>
                                            )}


                                          </div>
                                        )}




                                      {/* <p><b>*Anexe abaixo os documentos necessários para a sua filiação</b></p>
                {federacao?.regrasFiliacao?.tipoFiliacao === 2 && (
                  <p><b>**Valor da filiação: {parseFloat(federacao.regrasFiliacao.valorPagamento).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</b></p>
                )}
                {federacao?.regrasFiliacao?.tipoFiliacao === 1 && (
                  <p><b>**filiação GRATUITA</b></p>
                )} */}

                                      <div role="form" class="row " >

                                        {/* Tabela anexar arquivos - Inicio */}
                                        <table style={{ width: "100%", marginTop: 20 }}>
                                          <thead>
                                            <tr >
                                              <th class="schedule-table__day">
                                                Anexe aqui os documentos necessários para {filiacao.statusFiliacao === 4 && (<span style={{ textDecoration: 'underline', color: 'red' }}>Renovar </span>)}sua filiação</th>
                                            </tr>
                                          </thead>
                                        </table>
                                        <table style={{ width: "100%" }} className="3">
                                          <thead style={{ height: '15px' }}>
                                            <tr style={{ background: '#121212', color: 'white' }}>
                                              <th className="" style={{ width: '250px' }} >Documento </th>
                                              <th className="">Anexar </th>
                                              <th className="" style={{ width: '250px' }}>Status </th>
                                              <th className="">Observação</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>RG/CNH (frente-verso)</td>
                                              <td onClick={() => { refRgFile.current.click() }}>
                                                <div className="file-input-wrapper">
                                                  <input
                                                    ref={refRgFile}
                                                    type="file"
                                                    id="file-input1"
                                                    className="file-input"
                                                    onChange={(e) => handleFotoRg(e)}
                                                  />
                                                  <label htmlFor="file-input" className="file-label">
                                                    Selecione um arquivo
                                                  </label>
                                                  <p style={{ margin: '2px 10px' }} className="file-name">{fotoRg}</p>
                                                </div>
                                              </td>
                                              <td>
                                                {(!filiacao.id && !uploadArquivoRg) && 'Não Anexado'}
                                                {(uploadArquivoRg) && 'Anexado'}
                                                {(filiacao.statusImagemRg === 1 && !uploadArquivoRg) && (
                                                  <span style={{ fontWeight: 'bold', color: 'orange' }}>Aguardando Aprovação</span>
                                                )}
                                                {(filiacao.statusImagemRg === 2 && !uploadArquivoRg) && (
                                                  <span style={{ fontWeight: 'bold', color: 'green' }}>Aprovado</span>
                                                )}
                                                {(filiacao.statusImagemRg === 3 && !uploadArquivoRg) && (
                                                  <span style={{ fontWeight: 'bold', color: 'red' }}>Reprovado</span>
                                                )}
                                                {(filiacao.statusImagemRg === 4 && !uploadArquivoRg) && (
                                                  <span style={{ fontWeight: 'bold', color: 'red' }}>Renovar</span>
                                                )}
                                              </td>
                                              <td>
                                                {(filiacao.statusImagemRg === 3) && (
                                                  <div
                                                    className='badge-doc-reprovada'
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => mensagemReprovado(filiacao.observacaoRg)} data-toggle="modal" data-target="#modal"
                                                  >
                                                    <span className="badge-doc-reprovada badge-danger"><i className='fa fa-commenting'></i></span>
                                                  </div>
                                                )}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Foto P/ Documento</td>
                                              <td onClick={() => { refDocumentoFile.current.click() }}>
                                                <div className="file-input-wrapper">
                                                  <input
                                                    ref={refDocumentoFile}
                                                    type="file"
                                                    id="file-input2"
                                                    className="file-input"
                                                    onChange={(e) => handleFotoDocumento(e)}
                                                  />
                                                  <label htmlFor="file-input" className="file-label">
                                                    Selecione um arquivo
                                                  </label>
                                                  <p style={{ margin: '2px 10px' }} className="file-name">{fotoDocumento}</p>
                                                </div>
                                              </td>
                                              <td>
                                                {(!filiacao.id && !uploadArquivoFoto) && 'Não Anexado'}
                                                {(uploadArquivoFoto) && 'Anexado'}
                                                {(filiacao.statusImagemFoto === 1 && !uploadArquivoFoto) && (
                                                  <span style={{ fontWeight: 'bold', color: 'orange' }}>Aguardando Aprovação</span>
                                                )}
                                                {(filiacao.statusImagemFoto === 2 && !uploadArquivoFoto) && (
                                                  <span style={{ fontWeight: 'bold', color: 'green' }}>Aprovado</span>
                                                )}
                                                {(filiacao.statusImagemFoto === 3 && !uploadArquivoFoto) && (
                                                  <span style={{ fontWeight: 'bold', color: 'red' }}>Reprovado</span>
                                                )}
                                                {(filiacao.statusImagemFoto === 4 && !uploadArquivoFoto) && (
                                                  <span style={{ fontWeight: 'bold', color: 'red' }}>Renovar</span>
                                                )}
                                              </td>
                                              <td>
                                                {(filiacao.statusImagemFoto === 3) && (
                                                  <div
                                                    className='badge-doc-reprovada'
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => mensagemReprovado(filiacao.observacaoFoto)} data-toggle="modal" data-target="#modal"
                                                  >
                                                    <span className="badge-doc-reprovada badge-danger"><i className='fa fa-commenting'></i></span>
                                                  </div>
                                                )}
                                              </td>

                                            </tr>

                                            {exibirInputComprovante() && (
                                              // Obs: A solicitação de pagamento só deve ocorrer se o tipoFiliacao for pago (2)
                                              <tr>
                                                <td>Comprovante de Pagamento</td>
                                                <td onClick={() => { refComprovanteFile.current.click() }}>
                                                  <div className="file-input-wrapper">
                                                    <input
                                                      ref={refComprovanteFile}
                                                      type="file"
                                                      id="file-input2"
                                                      className="file-input"
                                                      onChange={(e) => handleFotoComprovante(e)}
                                                    />
                                                    <label htmlFor="file-input" className="file-label">
                                                      Selecione um arquivo
                                                    </label>
                                                    <p style={{ margin: '2px 10px' }} className="file-name">{fotoComprovante}</p>
                                                  </div>
                                                </td>
                                                <td>
                                                  {(!pagamentoPendente.id && !uploadArquivoComprovante) && 'Não Anexado'}
                                                  {(uploadArquivoComprovante) && 'Anexado'}
                                                  {(pagamentoPendente.status === 1 && !uploadArquivoComprovante) && (
                                                    <span style={{ fontWeight: 'bold', color: 'orange' }}>Aguardando Aprovação</span>
                                                  )}
                                                  {(pagamentoPendente.status === 2 && !uploadArquivoComprovante) && (
                                                    <span style={{ fontWeight: 'bold', color: 'green' }}>Aprovado</span>
                                                  )}
                                                  {(pagamentoPendente.status === 3 && !uploadArquivoComprovante) && (
                                                    <span style={{ fontWeight: 'bold', color: 'red' }}>Reprovado</span>
                                                  )}
                                                </td>
                                                <td>
                                                  {pagamentoPendente.status === 3 && (
                                                    <div
                                                      className='badge-doc-reprovada'
                                                      style={{ cursor: 'pointer' }}
                                                      onClick={() => mensagemReprovado(pagamentoPendente.observacaoComprovante)} data-toggle="modal" data-target="#modal"
                                                    >
                                                      <span className="badge-doc-reprovada badge-danger"><i className='fa fa-commenting'></i></span>
                                                    </div>
                                                  )}
                                                </td>
                                              </tr>
                                            )}

                                          </tbody>
                                        </table>
                                        {/* Tabela anexar arquivos - Fim */}



                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>


                        </div>
                      </div>
                    )}


                    {/* Dados da Filiação - Fim */}

                    {/* Histórico - Inicio */}

                    {(pagamentosAprovados.length > 0) && (
                      <>

                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '50px' }}>
                          <div style={{ border: '1px solid rgba(0, 0, 0, .125)', borderRadius: '5px', padding: '15px' }}>



                            <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix" style={{ margin: '25px', textAlign: 'center' }}>
                              <h3>Histórico</h3>
                            </div>
                            <table style={{ width: "100%", marginTop: 20 }}>
                            </table>
                            <table style={{ width: "100%" }}>
                              <thead style={{ height: '15px' }}>
                                <tr style={{ background: '#121212', color: 'white' }}>
                                  <th style={{ height: '15px' }} class="" >Valor </th>
                                  <th style={{ height: '15px' }} class="">Data Anexado</th>
                                  <th style={{ height: '15px' }} class="">Data Aprovado</th>
                                  {federacao?.regrasFiliacao?.tipoPagamento == '2' && (
                                    <th style={{ height: '15px' }} class="">Vencimento</th>
                                  )}
                                  <th style={{ height: '15px' }} class="">Visualizar <i className='fa fa-search'></i></th>
                                </tr>
                              </thead>
                              <tbody>
                                {pagamentosAprovados.sort((a, b) => b.dtAnexo - a.dtAnexo).map(pgto => (
                                  <tr>
                                    <td>{pgto.valorTotal ? pgto.valorTotal.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : ''}</td>
                                    <td>{pgto.dtAnexo ? converterData(pgto.dtAnexo) : 'sem anexo'}</td>
                                    <td>{pgto.dtAprovado ? converterData(pgto.dtAprovado) : 'sem anexo'}</td>
                                    {federacao?.regrasFiliacao?.tipoPagamento == '2' && (
                                      <th style={{ height: '15px' }} class="">{converterDataVencimento(pgto.dtAprovado)}</th>
                                    )}
                                    <td><a href={pgto.urlComprovantePgto} target='_blank' style={{ textDecoration: 'underline', color: 'blue' }}>{pgto.nomeImagemComprovantePgto}</a></td>

                                  </tr>
                                ))}

                              </tbody>
                            </table>

                          </div>
                        </div>

                      </>
                    )}
                    {/* Histórico - Fim */}

                    {/* Carteirinha - Início */}


                    {((filiacao.id && filiacao.statusFiliacao == 2) && (federacao?.carteirinha?.carteirinhaFisica == '1' || federacao?.carteirinha?.carteirinhaPdf == '1')) && (
                      <>

                        <div style={{ marginTop: '50px' }}>
                          <div style={{ border: '1px solid rgba(0, 0, 0, .125)', borderRadius: '5px', padding: '15px' }}>


                            <div style={{ margin: '25px 0', textAlign: 'center' }} id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                              <h3>Carteirinha</h3>
                            </div>

                            {federacao?.carteirinha?.carteirinhaFisica == '1' && (
                              <p>{federacao?.carteirinha?.gerarPedidoComAFiliacao == '1' ? '* Clique no  botão ao lado para acompanhar a solicitação da sua carteirinha.' : '* Clique no  botão ao lado para solicitar sua carteirinha.'}<i class="fa fa-hand-o-right" aria-hidden="true"></i><div style={{ margin: '0 10px', padding: '0 5px', background: '#ffc107', cursor: 'pointer', display: 'inline-block', width: 'fitContent', borderRadius: '5px', }} >
                                <a href={`/carteirinha?fl=${filiacao.id}&fd=${idFederacao}`}>
                                  <button style={{ cursor: 'pointer', fontSize: '16px', color: 'black', padding: '8px' }} class="wpcf7-form-control wpcf7-pnchr_submit" >Carteirinha &nbsp;<i className='fa fa-list-alt' aria-hidden="true"></i></button>
                                </a>

                              </div></p>
                            )}

                            {federacao?.carteirinha?.carteirinhaPdf == '1' && (

                              <p>* Clique no  botão ao lado para gerar sua carteirinha em PDF <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                                <div onClick={() => Swal.fire('Em desenvolvimento', 'Recurso em desenvolvimento. Disponível em breve.', 'info')} style={{ margin: '0 10px', padding: '0 5px', background: '#ffc107', cursor: 'pointer', display: 'inline-block', width: 'fitContent', borderRadius: '5px', }} >
                                  <input style={{ cursor: 'pointer', fontSize: '16px', color: 'black' }} type="submit" value={'Carteirinha PDF'} class="wpcf7-form-control wpcf7-pnchr_submit" />
                                </div>
                              </p>

                            )}

                          </div>
                        </div>

                      </>
                    )}

                    {/* Carteirinha - Fim */}

                    {/* Termos da Filiação - Início */}
                    {usuarioFiliacao.id && (
                      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '50px' }}>


                        <div className=' wpb_row row'>

                          <div class="wpb_column vc_column_container vc_col-sm-12">



                            <div class="vc_column-inner">
                              <div class="wpb_wrapper">
                                <div class="wpb_widgetised_column wpb_content_element">
                                  <div class="wpb_wrapper">

                                    <div style={{ margin: '50px 0 25px 0', textAlign: 'center' }} id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                      <h3>Termos da Filiação</h3>
                                    </div>
                                    {federacao?.regrasFiliacao?.tipoFiliacao === 2 && (
                                      <>
                                        <p>* A filiação só será confirmada após a confirmação do pagamento e aprovação da documentação anexada.</p>
                                        <p>* O pagamento deverá ser realizado através de transferência bancária ou Pix e o comprovante deverá ser anexado no ato da inscrição. O comprovante será analisado para que a filiação seja confirmada.</p>
                                      </>
                                    )}
                                    {federacao?.regrasFiliacao?.tipoFiliacao === 1 && (
                                      <p>* A filiação só será confirmada após a aprovação da documentação anexada.</p>
                                    )}
                                    <p>* O documento de identidade escolhido RG/CNH deve ser anexado frente e verso no mesmo arquivo como no modelo ao lado <i class="fa fa-hand-o-right" aria-hidden="true"></i> <img src={RG_Frente_Verso} style={{ display: 'inline-block', height: '50px', marginLeft: '10px' }}></img></p>
                                    <p>* A foto do filiado deve ser em formato "Foto de Documento" como no modelo ao lado <i class="fa fa-hand-o-right" aria-hidden="true"></i> <img src={Foto_Documento} style={{ display: 'inline-block', height: '50px', marginLeft: '10px' }}></img></p>

                                    <p>* Caso alguma documentação seja rejeitada será necessário reanexar novo arquivo para que possa ser validado pela Federação / Agremiação</p>
                                    <p>* Em caso de dúvidas entre em contato com a Federação/Agremiação através dos dados fornecidos nesta página.</p>
                                    <div id='concordo-termos-filiacao' className="checkbox-wrapper">
                                      <input
                                        type="checkbox"
                                        id="checkbox"
                                        className="checkbox-input"
                                        checked={concordoTermo}
                                        onChange={() => setConcordoTermo(!concordoTermo)}
                                      />
                                      <label htmlFor="checkbox" className="checkbox-label">
                                        <span className="checkbox-custom"></span>
                                        Declaro que li e concordo com os termos da filiação.
                                      </label>
                                    </div>

                                    {/* Termos da Filiação - Fim */}


                                    <div onClick={() => salvarFiliacao()} style={{ margin: '30px 10px 0 0', background: '#28a745', cursor: 'pointer' }} class={`form-submit__btn-wrapp btn_defoult btn_fill ${filiacao.id ? 'vc_col-sm-10' : 'vc_col-sm-12'}`}>
                                      <input style={{ cursor: 'pointer', fontSize: '16px' }} type="submit" value={
                                        !filiacao.id ? 'Confirmar Filiação' :
                                          filiacao.statusFiliacao === 4 ?
                                            'Renovar Filiação' : 'Confirmar alterações'} class="wpcf7-form-control wpcf7-pnchr_submit" />
                                    </div>
                                    {filiacao.id && (
                                      <div onClick={() => excluirFiliacao()} style={{ margin: '30px 0 0 0px', background: '#dc3545', cursor: 'pointer' }} class="form-submit__btn-wrapp btn_defoult btn_fill ">
                                        <input style={{ cursor: 'pointer', fontSize: '16px' }} type="submit" value='Excluir Filiação' class="wpcf7-form-control wpcf7-pnchr_submit vc_col-sm-3" />
                                      </div>
                                    )}



                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                      </div>
                    )}






                  </div>
                </div>
              </div>
            </div>
          </div>

        </main>
      </div>
      <Footer />
    </div>
  );
}
