import { useState, useEffect, useRef } from 'react'
import Swal from 'sweetalert2'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import moment from 'moment'
import firebase from 'firebase'
import './Filiacao.css'


export default function Carteirinha() {


  const queryParams = new URLSearchParams(window.location.search);
  const idFederacao = queryParams.get('fd')
  const idFiliacao = queryParams.get('fl')

  const [servicos] = useState({
    eventos: firebase.firestore().collection("eventos"),
    equipes: firebase.firestore().collection("equipes"),
    usuarios: firebase.firestore().collection("usuarios"),
    inscricoes: firebase.firestore().collection('inscricoes'),
    federacoes: firebase.firestore().collection('federacoes'),
    filiacoes: firebase.firestore().collection('federacoes').doc(idFederacao).collection('filiacoes'),
    configuracoes: firebase.firestore().collection('configuracoes'),
    auth: firebase.auth()
  })

  const [federacao, setFederacao] = useState({})
  const [filiacao, setFiliacao] = useState({});
  const [statusFiliacao, setStatusFiliacao] = useState(1)
  const [usuario, setUsuario] = useState({})
  const [usuarioFiliacao, setUsuarioFiliacao] = useState({})
  const [equipe, setEquipe] = useState('')
  const [professor, setProfessor] = useState('')
  const [graduacao, setGraduacao] = useState('')
  // console.log('graduacao: ', graduacao);

  const [idade, setIdade] = useState('')
  const [competidores, setCompetidores] = useState([])

  const [fotoRg, setFotoRg] = useState('Nenhum arquivo selecionado');
  const [arquivoRg, setArquivoRg] = useState('');
  const refRgFile = useRef(null);
  const [uploadArquivoRg, setUploadArquivoRg] = useState('');

  const [fotoDocumento, setFotoDocumento] = useState('Nenhum arquivo selecionado');
  const [arquivoFoto, setArquivoFoto] = useState('');
  const refDocumentoFile = useRef(null);
  const [uploadArquivoFoto, setUploadArquivoFoto] = useState('');

  const [fotoComprovante, setFotoComprovante] = useState('Nenhum arquivo selecionado');
  const [arquivoComprovante, setArquivoComprovante] = useState('');
  const [uploadArquivoComprovante, setUploadArquivoComprovante] = useState('');
  const refComprovanteFile = useRef(null);
  const [pagamentoPendente, setPagamentoPendente] = useState({})
  const [pagamentosAprovados, setPagamentosAprovados] = useState([])

  const [concordoTermo, setConcordoTermo] = useState(false);

  const [carteirinhas, setCarteirinhas] = useState([])
  console.log('carteirinhas: ', carteirinhas);
  const [novaCarteirinha, setNovaCarteirinha] = useState(false);

  const [progresso, setProgresso] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingImage, setLoadingImage] = useState(false)
  const [loadingCep, setLoadingCep] = useState(false)


  // const graduacoes = ["BRANCA", "CINZA", "AMARELA", "LARANJA", "VERDE", "AZUL", "ROXA", "MARROM", "PRETA", "CORAL"]


  useEffect(() => {

    servicos.auth.onAuthStateChanged(async (user) => {
      let idUser = JSON.parse(localStorage.getItem('usuarioId'))

      if (user || idUser) {

        servicos.usuarios.doc(user.uid ? user.uid : idUser).get().then(async (doc) => {
          if (doc.exists) {
            setUsuario(doc.data())

            servicos.filiacoes.doc(idFiliacao).get().then(snap => {
              if (snap.exists) {
                setFiliacao(snap.data())
              }
            })

            servicos.filiacoes.doc(idFiliacao).collection('carteirinhas').get().then(snap => {
              if (!snap.empty) {
                const data = snap.docs.map(d => d.data())
                setCarteirinhas(data);
              }
            })


          }

        })

      } else {
        window.location = '/'
      }

    })

    // carregar Federação, Modalidade e Graduações

    servicos.federacoes.doc(idFederacao).get().then(snap => {

      setFederacao(snap.data());


    })



  }, [])

  useEffect(() => {
    if (filiacao.id) {
      servicos.filiacoes.doc(filiacao.id).collection('pagamentos').get().then(snap => {
        const data = snap.docs.map(d => d.data())
        if (data.some(pgto => pgto.status !== 2)) {
          const _pagamento = data.find(pgto => pgto.status !== 2)
          setPagamentoPendente(_pagamento)
          setFotoComprovante(_pagamento.nomeImagemComprovantePgto)
        }
        if (data.some(pgto => pgto.status == 2)) {
          const _pagamentos = data.filter(pgto => pgto.status == 2)
          setPagamentosAprovados(_pagamentos)
        }

      })

    } else {
      setPagamentoPendente({})
      setFotoComprovante('Nenhum arquivo selecionado')
    }

  }, [filiacao])


  const handleFotoComprovante = (event) => {
    event.stopPropagation()
    setArquivoComprovante(event.target.files[0])
    if (uploadArquivoComprovante) {
      URL.revokeObjectURL(uploadArquivoComprovante)
    }
    setUploadArquivoComprovante(URL.createObjectURL(event.target.files[0]) ? URL.createObjectURL(event.target.files[0]) : '')
    const file = event.target.files[0];
    console.log('file: ', file);
    setFotoComprovante(file ? file.name : 'Nenhum arquivo selecionado');

  };


  function mensagemAtencao(msg) {
    Swal.fire('Atenção', msg, 'warning')
  }
  function mensagemAlerta(msg) {
    Swal.fire('Alerta', msg, 'warning')
  }
  function mensagemReprovado(msg) {
    Swal.fire('Solicitação Reprovada', msg, 'warning')
  }

  function mensagemObservacao(status, msg) {

    switch (status) {
      case 1:
        Swal.fire('Solicitação Pendente', 'Aguarde. A solicitção da carteirinha será processada em breve. ', 'info'); break;
      case 2:
        Swal.fire('Em Produção', 'A carteirinha já está sendo confeccionada e ficará pronta em breve. ', 'info'); break;
      case 3:
        Swal.fire('Solicitação Reprovada', msg, 'warning'); break;
      case 4:
        Swal.fire('Retirada Disponível', 'A carteirinha já pode ser retirada no endereço da Federação. ', 'info'); break;
      case 5:
        Swal.fire('Concluído', 'Carteirinha entregue.', 'success'); break;
    }
  }

  function mensagemSucesso(msg, idInscricao) {
    Swal.fire('Sucesso', msg, 'success').then(() => {
      // if (itemVoucher.id && itemVoucher.tipo === 'cortesia') {
      //   window.location = window.location.href.replace('/inscricao/' + id, '/evento/' + evento.id)
      // } else if ((valorAbsoluto + valorCategoria) === 0) {
      //   window.location = window.location.href.replace('/inscricao/' + id, '/minhaInscricao/' + id)
      // } else {
      //   window.location.href = `/pagamento?e=${id}&i=${idInscricao}`
      // }

    })
  }

  function mensagemLoading(msg) {
    Swal.fire({
      title: 'Aguarde',
      html: msg,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
      },
    })
  }
  function mensagemErro(msg) {
    Swal.fire('Erro', msg, 'error')
  }




  async function cadastraImagem(idFiliacao, _imagem, idCarteirinha) {

    let caminho = `federacoes/${idFederacao}/filiacoes/${idFiliacao}/pgtoCarteirinha/${idCarteirinha}`

    var uploadTask = firebase.storage().ref().child(caminho).put(_imagem, { contentType: _imagem.type });

    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => { }, (error) => { }, async () => {
      const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();

      await servicos.filiacoes.doc(idFiliacao).collection('carteirinhas').doc(idCarteirinha).update({ urlPgtoCarteirinha: downloadURL })

    });


  }


  function converterData(data) {
    return moment(new firebase.firestore.Timestamp(data.seconds, data.nanoseconds).toDate()).format('DD-MM-YYYY HH:mm')
  }


  async function confirmarPedido() {

    mensagemLoading('Salvando solicitação de carteirinha...');
    setLoading(true);

    await new Promise((resolve) => {
      setTimeout(resolve, 1)
    })

    if (!uploadArquivoComprovante) {
      mensagemAlerta('Por favor, anexe uma nova foto do comprovante de pagamento');
      return;
    }

    if (carteirinhas.some(carteirinha => carteirinha.status !== 5)) {
      const careteirinhaPendente = carteirinhas.find(carteirinha => carteirinha.status !== 5)

      if (careteirinhaPendente.status !== 3) {
        mensagemAlerta('Seu pedido de carteirinha já está sendo processado. Por favor, guarde um retorno da Federação.')
        return;
      }

      await servicos.filiacoes.doc(idFiliacao).collection('carteirinhas').doc(careteirinhaPendente.id).update({
        status: 1,
        dtAtualizado: firebase.firestore.Timestamp.fromDate(new Date()),
        observacao: ''
      })

      await cadastraImagem(idFiliacao, arquivoComprovante, careteirinhaPendente.id)

      mensagemSucesso('Solicitação alterada com sucesso.')

    } else {

      let valor = 0;
      if (federacao?.carteirinha?.valorCarteirinha) {
        valor += parseFloat(federacao?.carteirinha?.valorCarteirinha)
      }
      if (federacao?.carteirinha?.valorTaxaEnvio) {
        valor += parseFloat(federacao?.carteirinha?.valorTaxaEnvio)
      }

      const dados = {
        status: 1,
        valor: valor,
        dtPedido: firebase.firestore.Timestamp.fromDate(new Date()),
        idFiliacao: idFiliacao,
        idFederacao: idFederacao,
        idFiliado: filiacao.idFiliado,
        urlAvatarFiliado: filiacao.urlAvatarFiliado,
        nomeFiliado: filiacao.nomeFiliado
      }

      const carteirinhaDocRef = await servicos.filiacoes.doc(idFiliacao).collection('carteirinhas').add(dados)

      await servicos.filiacoes.doc(idFiliacao).collection('carteirinhas').doc(carteirinhaDocRef.id).update({ id: carteirinhaDocRef.id })

      await cadastraImagem(idFiliacao, arquivoComprovante, carteirinhaDocRef.id)

      mensagemSucesso('Solicitação gerada com sucesso.')
    }


  }

  function mensagemStatus(status) {
    let mensagem = ''
    switch (status) {
      case 1: mensagem = 'Pendente'; break;
      case 2: mensagem = 'Em Produção'; break;
      case 3: mensagem = 'Reprovada'; break;
      case 4: mensagem = 'Retirada Disponível'; break;
      case 5: mensagem = 'Entregue'; break;
      default: mensagem = ''; break;
    }

    return mensagem;
  }

  function mensagemCor(status) {
    let cor = ''
    switch (status) {
      case 1: cor = 'orange'; break;
      case 2: case 4: cor = 'blue'; break;
      case 3: cor = 'red'; break;
      case 5: cor = 'green'; break;
      default: cor = ''; break;
    }
    return cor;
  }

  function valorTotal() {
    let valor = parseFloat(federacao?.carteirinha?.valorCarteirinha);
    if (federacao?.carteirinha?.cobrarTaxaEnvio) {
      valor += parseFloat(federacao?.carteirinha?.valorTaxaEnvio)
    }
    return valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })

  }

  return (
    <div className="body-wrapper blank-loder">
      <Header />
      <div class="section_fullwidth">
        <div class="headline headline_img">
          <div class="headline__inner">
            <div class="headline__title">
              <h1>Carteirinha</h1>
            </div>
            <div class="headline__description">
              <span>Solicite ou acompanhe aqui sua solicitação de carteirinha</span>
            </div>
          </div>
        </div>
      </div>
      <div class="section_default section-single-class-layout clearfix">
        <main class="section_default">
          <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
            <div class="wpb_column vc_column_container vc_col-sm-12">
              <div class="vc_column-inner ">
                <div class="wpb_wrapper">
                  <div class="wpb_text_column wpb_content_element ">

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div className='wpb_row row'>
                        <h6>Filiado: {filiacao.nomeFiliado} </h6>
                      </div>

                      <div className=' wpb_row row'>

                        <div class="wpb_column vc_column_container vc_col-sm-12">



                          <div class="vc_column-inner">
                            <div class="wpb_wrapper">
                              <div class="wpb_widgetised_column wpb_content_element">
                                <div class="wpb_wrapper">

                                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div onClick={() => setNovaCarteirinha(!novaCarteirinha)} style={{ background: '#28a745', cursor: 'pointer', margin: '15px' }} class={`form-submit__btn-wrapp btn_defoult btn_fill vc_col-sm-2`}>
                                      <input style={{ cursor: 'pointer', fontSize: '16px' }} type="submit" value='Nova Carteirinha' class="wpcf7-form-control wpcf7-pnchr_submit" />
                                    </div>
                                    <div onClick={() => { window.location = `/filiacao/${idFederacao}` }} style={{ background: '#007bff', cursor: 'pointer', margin: '15px' }} class={`form-submit__btn-wrapp btn_defoult btn_fill vc_col-sm-1`}>
                                      <button style={{ cursor: 'pointer', fontSize: '16px' }} type="submit" class="wpcf7-form-control wpcf7-pnchr_submit" ><i class="fa fa-arrow-left" aria-hidden="true"></i> Voltar</button>
                                    </div>
                                  </div>



                                  {novaCarteirinha && (
                                    <div style={{ border: '1px solid rgba(0, 0, 0, .125)', borderRadius: '5px', padding: '15px' }}>
                                      {/* Tabela Extrato */}
                                      <table style={{ width: "100%", marginTop: 20 }}>
                                        <thead>
                                          <tr >
                                            <th class="schedule-table__day">Extrato</th>
                                          </tr>
                                        </thead>
                                      </table>
                                      <table style={{ width: "100%" }} className="3">
                                        <tbody>

                                          <tr>
                                            <td>Carteirinha</td>
                                            <td>{parseFloat(federacao?.carteirinha?.valorCarteirinha).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                          </tr>
                                          {federacao?.carteirinha?.cobrarTaxaEnvio === '1' && (
                                            <tr>
                                              <td>Taxa de Envio</td>
                                              <td>{parseFloat(federacao?.carteirinha?.valorTaxaEnvio).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                            </tr>
                                          )}

                                          <tr>
                                            <td><b>Total a Pagar</b></td>
                                            <td><b>{valorTotal()}</b></td>
                                          </tr>

                                        </tbody>
                                      </table>
                                      {/* Tabela Extrato - Fim */}

                                      <p style={{ marginTop: '15px' }}><b>*O pedido da carteirinha será processado após a confirmação do pagamento</b></p>
                                      {false && (
                                        <p><b>**Valor da carteirinha: {'50'.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</b></p>
                                      )}

                                      {/* Tabela anexar arquivos - Inicio */}
                                      <table style={{ width: "100%", marginTop: 20 }}>
                                        <thead>
                                          <tr >
                                            <th class="schedule-table__day">Anexe abaixo o comprovante de pagamento da carteirinha</th>
                                          </tr>
                                        </thead>
                                      </table>
                                      <table style={{ width: "100%" }} className="3">
                                        <thead style={{ height: '15px' }}>
                                          <tr style={{ background: '#121212', color: 'white' }}>
                                            <th className="">Anexar </th>
                                            <th className="" style={{ width: '250px' }}>Status </th>
                                            <th className=""></th>
                                          </tr>
                                        </thead>
                                        <tbody>

                                          {true && (
                                            // Obs: A solicitação de pagamento só deve ocorrer se o tipoFiliacao for pago (2)
                                            <tr>
                                              <td onClick={() => { refComprovanteFile.current.click() }}>
                                                <div className="file-input-wrapper">
                                                  <input
                                                    ref={refComprovanteFile}
                                                    type="file"
                                                    id="file-input2"
                                                    className="file-input"
                                                    onChange={(e) => handleFotoComprovante(e)}
                                                  />
                                                  <label htmlFor="file-input" className="file-label">
                                                    Selecione um arquivo
                                                  </label>
                                                  <p style={{ margin: '2px 10px' }} className="file-name">{fotoComprovante}</p>
                                                </div>
                                              </td>
                                              <td>
                                                {(!pagamentoPendente.id && !uploadArquivoComprovante) && 'Não Anexado'}
                                                {(uploadArquivoComprovante) && 'Anexado'}
                                                {(pagamentoPendente.status === 1 && !uploadArquivoComprovante) && (
                                                  <span style={{ fontWeight: 'bold', color: 'orange' }}>Aguardando Aprovação</span>
                                                )}
                                                {(pagamentoPendente.status === 2 && !uploadArquivoComprovante) && (
                                                  <span style={{ fontWeight: 'bold', color: 'green' }}>Aprovado</span>
                                                )}
                                                {(pagamentoPendente.status === 3 && !uploadArquivoComprovante) && (
                                                  <span style={{ fontWeight: 'bold', color: 'red' }}>Reprovado</span>
                                                )}
                                              </td>
                                              <td>
                                                <div onClick={() => confirmarPedido()} style={{ background: '#28a745', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '40px' }} class={`form-submit__btn-wrapp btn_defoult btn_fill vc_col-sm-12`}>
                                                  <input style={{ cursor: 'pointer', fontSize: '16px' }} type="submit" value='Confirmar Pedido' class="wpcf7-form-control wpcf7-pnchr_submit" />
                                                </div>
                                              </td>
                                            </tr>
                                          )}

                                        </tbody>
                                      </table>

                                    </div>
                                  )}



                                  <div role="form" class="row " >



                                    {/* Tabela Extrato - Inicio */}
                                    {(true) && (
                                      <>
                                        <div id="ratings-widget-25" class="widget_ratings-widget clearfix vc_col-sm-12" style={{ textAlign: 'center', marginBottom: '10px' }}>
                                          <h3>Histórico</h3>
                                        </div>
                                        <table style={{ width: "100%", marginTop: '0px' }}>
                                        </table>
                                        <table style={{ width: "100%" }}>
                                          <thead style={{ height: '15px' }}>
                                            <tr style={{ background: '#121212', color: 'white' }}>
                                              <th style={{ height: '15px' }} class="" >Data Solicitação </th>
                                              <th style={{ height: '15px' }} class="" >Valor </th>
                                              <th style={{ height: '15px' }} class="">Comprovante <i className='fa fa-search'></i></th>
                                              <th style={{ height: '15px' }} class="">Data Atualizado</th>
                                              <th style={{ height: '15px' }} class="">Data Entregue</th>
                                              <th style={{ height: '15px' }} class="">Situação</th>
                                              <th style={{ height: '15px' }} class="">Observação</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {carteirinhas.sort((a, b) => b.dtPedido - a.dtPedido).map(carteirinha => (
                                              <tr>
                                                <td>{converterData(carteirinha.dtPedido)}</td>
                                                <td>{carteirinha.valor > 0 ? carteirinha.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : '--'}</td>
                                                <td>
                                                  {carteirinha.urlPgtoCarteirinha ? (
                                                    <a href={carteirinha.urlPgtoCarteirinha} target='_blank' style={{ textDecoration: 'underline', color: 'blue' }}>{'comprovante'}</a>
                                                  ) : '--'}

                                                </td>
                                                <td>{carteirinha.dtAtualizado ? converterData(carteirinha.dtAtualizado) : ''}</td>
                                                <td>{carteirinha.dtEntregue ? converterData(carteirinha.dtEntregue) : ''}</td>
                                                <td>

                                                  <span style={{ fontWeight: 'bold', color: mensagemCor(carteirinha.status) }}>{
                                                    mensagemStatus(carteirinha.status)}</span>

                                                </td>
                                                <td>
                                                  <div
                                                    className={`${carteirinha.status === 3 ? 'badge-doc-reprovada badge-danger' : 'badge-doc-generico badge-warning'}`}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() =>
                                                      mensagemObservacao(carteirinha.status, carteirinha.observacao)
                                                    } data-toggle="modal" data-target="#modal"
                                                  >
                                                    <span className={`${carteirinha.status === 3 ? 'badge-doc-reprovada badge-danger' : 'badge-doc-generico badge-warning'}`}><i className='fa fa-commenting'></i></span>
                                                  </div>
                                                </td>


                                              </tr>
                                            ))}

                                          </tbody>
                                        </table>
                                      </>
                                    )}

                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </main>
      </div>
      <Footer />
    </div>
  );
}
