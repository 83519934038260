import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect } from 'react-router-dom'

// Autenticacao
import Cadastro from './autenticacao/Cadastro'
import Login from './autenticacao/Login'
import Perfil from './autenticacao/Perfil'
import MudaEmail from './autenticacao/MudaEmail'

import CadastroResponsavel from './autenticacao/CadastroResponsavel'
import CadastraCompetidor from './autenticacao/CadastraCompetidor'
import CadastraAluno from './autenticacao/CadastraAluno'
import LoginResponsavel from './autenticacao/LoginResponsavel'
import PerfilResponsavel from './autenticacao/PerfilResponsavel'
import Competidor from './autenticacao/Competidor'

// Site
import Home from './site/Home'
import Sobre from './site/Sobre'
import Ranking from './site/Ranking'
import DetalheRanking from './site/DetalheRanking'
import Contato from './site/Contato'

// Eventos
import Eventos from './eventos/Eventos'
import Evento from './eventos/Evento'
import Inscricao from './eventos/Inscricao'
import InscricaoAlunos from './eventos/InscricaoAlunos'
import ListaCompetidores from './eventos/ListaCompetidores'
import ListaInscricoes from './eventos/ListaInscricoes';
import PagamentoAluno from './eventos/PagamentoAluno'
import EditaInscricao from './eventos/EditaInscricao'
import EditaInscricaoAluno from './eventos/EditaInscricaoAluno'
import Pagamento from './eventos/Pagamento'
import PagamentoAbsoluto from './eventos/PagamentoAbsoluto'
import PagamentoManual from './eventos/PagamentoManual'
import PagamentoManualAluno from './eventos/PagamentoManualAluno'
import Resultados from './eventos/Resultados'
import Checagem from './eventos/Checagem'
import Checkout from './eventos/Checkout'
import Chaves from './eventos/Chaves'
import VerificaPerfil from './eventos/VerificaPerfil'
import MinhaInscricao from './eventos/MinhaInscricao'
import MudaEquipe from './eventos/MudaEquipe'

// Componentes
import PaginaSucesso from './componentes/PaginaSucesso'
import PaginaAtencao from './componentes/PaginaAtencao'
import PaginaErro from './componentes/PaginaErro'

import PaginaSucessoAbsoluto from './componentes/PaginaSucessoAbsoluto'
import PaginaAtencaoAbsoluto from './componentes/PaginaAtencaoAbsoluto'
import PaginaErroAbsoluto from './componentes/PaginaErroAbsoluto'
import Federacoes from './filiacoes/Federacoes';
import Filiacao from './filiacoes/Filiacao';
import Carteirinha from './filiacoes/Carteirinha';

export default class Routes extends Component {
    render() {
        return (
            <div className="App">
                <BrowserRouter>
                    {/* Componentes */}
                    <Route path="/sucesso" exact={true} component={PaginaSucesso} />
                    <Route path="/atencao" exact={true} component={PaginaAtencao} />
                    <Route path="/erro" exact={true} component={PaginaErro} />
                    <Route path="/sucessoAbsoluto" exact={true} component={PaginaSucessoAbsoluto} />
                    <Route path="/atencaoAbsoluto" exact={true} component={PaginaAtencaoAbsoluto} />
                    <Route path="/erroAbsoluto" exact={true} component={PaginaErroAbsoluto} />
                    <Route path="/mudaEmail" exact={true} component={MudaEmail} />

                    {/* Autenticacao */}
                    <Route path="/cadastro" exact={true} component={Cadastro} />
                    <Route path="/login" exact={true} component={Login} />
                    <Route path="/perfil" exact={true} component={Perfil} />
                    <Route path="/cadastroResponsavel" exact={true} component={CadastroResponsavel} />
                    <Route path="/cadastroCompetidor" exact={true} component={CadastraCompetidor} />
                    <Route path="/cadastraAluno/:id" exact={true} component={CadastraAluno} />
                    <Route path="/loginResponsavel" exact={true} component={LoginResponsavel} />
                    <Route path="/perfilResponsavel" exact={true} component={PerfilResponsavel} />
                    <Route path="/competidor/:id" exact={true} component={Competidor} />

                    {/* Site */}
                    <Route path="/" exact={true} component={Home} />
                    <Route path="/sobre" exact={true} component={Sobre} />
                    <Route path="/ranking" exact={true} component={Ranking} />
                    <Route path="/detalheRanking/:id" exact={true} component={DetalheRanking} />
                    <Route path="/contato" exact={true} component={Contato} />

                    {/* Eventos */}
                    <Route path="/eventos" exact={true} component={Eventos} />
                    <Route path="/evento/:id" exact={true} component={Evento} />
                    <Route path="/inscricao/:id" exact={true} component={Inscricao} />
                    <Route path="/inscricaoAlunos/:id" exact={true} component={InscricaoAlunos} />
                    <Route path="/listaCompetidores/:id" exact={true} component={ListaCompetidores} />
                    <Route path="/listaInscricoes/:id" exact={true} component={ListaInscricoes} />
                    <Route path="/pagamentoAluno/:id" exact={true} component={PagamentoAluno} />
                    <Route path="/editaInscricao/:id" exact={true} component={EditaInscricao} />
                    <Route path="/editaInscricaoAluno/:id" exact={true} component={EditaInscricaoAluno} />
                    <Route path="/pagamento/" exact={true} component={Pagamento} />
                    <Route path="/pagamentoAbsoluto/" exact={true} component={PagamentoAbsoluto} />
                    <Route path="/pagamentoManual" exact={true} component={PagamentoManual} />
                    <Route path="/pagamentoManualAluno/:id" exact={true} component={PagamentoManualAluno} />
                    <Route path="/resultados/:id" exact={true} component={Resultados} />
                    <Route path="/checagem/:id" exact={true} component={Checagem} />
                    <Route path="/checkout/:id" exact={true} component={Checkout} />
                    <Route path="/chaves/:id" exact={true} component={Chaves} />
                    <Route path="/verificaPerfil" exact={true} component={VerificaPerfil} />
                    <Route path="/minhaInscricao/:id" exact={true} component={MinhaInscricao} />
                    <Route path="/mudaEquipe/:id" exact={true} component={MudaEquipe} />

                    {/* Filiações */}
                    <Route path="/federacoes" exact={true} component={Federacoes} />
                    {/* <Route path="/federacoes" exact element={<Federacoes/>} /> */}
                    <Route path="/filiacao/:idFederacao" exact={true} component={Filiacao} />
                    <Route path="/carteirinha" exact={true} component={Carteirinha} />

                </BrowserRouter>
            </div>
        );
    }
}