import {
    useState, useEffect
} from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import firebase from 'firebase'
import { Link } from 'react-router-dom'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import MediaQuery from 'react-responsive'
import profile from '../componentes/Imagens/profile.jpg'


import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2'

const useStyles = makeStyles((theme) => ({
    card: {
        width: '49%',
        height: 120,
        display: 'flex',
    },
    heading: {
        fontSize: theme.typography.pxToRem(20),
        fontWeight: theme.typography.fontWeightRegular,
        marginLeft: 10
    },
    avatar: {
        width: 120,
        height: 120
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));

export default function Checagem() {
    const classes = useStyles();
    const [id] = useState(window.location.pathname.replace('/checagem/', ''))
    const [servicos] = useState({
        eventos: firebase.firestore().collection("eventos").doc(id),
        inscricoes: firebase.firestore().collection("inscricoes").where("evento.id", "==", id),
        equipes: firebase.firestore().collection("equipes"),
    })
    const [loading, setLoading] = useState(true)
    const [checagem, setChecagem] = useState([])
    const [checagemTemp, setChecagemTemp] = useState([])
    const [checagemAbs, setChecagemAbs] = useState([])
    const [checagemAbsTemp, setChecagemAbsTemp] = useState([])
    const [checagemEquipe, setChecagemEquipe] = useState([])
    const [inscricoes, setInscricoes] = useState([])
    const [evento, setEvento] = useState({})
    const [tipoChecagem, setTipoChecagem] = useState('geral')

    const [categorias, setCategorias] = useState([])
    const [absolutos, setAbsolutos] = useState([])
    const [pesos, setPesos] = useState([])
    const [faixas, setFaixas] = useState([])

    const [categoria, setCategoria] = useState('')
    const [absoluto, setAbsoluto] = useState('')
    const [peso, setPeso] = useState('')
    const [faixa, setFaixa] = useState('')
    const [sexo, setSexo] = useState('')

    useEffect(() => {
        servicos.eventos.get().then((snapE) => {
            setEvento(snapE.data())
            console.log(snapE.data())
            const l = []
            const li = []

            const lAbs = []
            const liAbs = []
            const lEquipe = []
            const liEquipe = []
            if (snapE.data().tipoChecagem === 'todos') {
                servicos.inscricoes.get().then((snap) => {
                    const data = snap.docs.map(doc => doc.data())
                    setInscricoes(data)
                    setLoading(false)
                    data.forEach((d) => {
                        var id = d.categoria.categoria.id + '-' + d.categoria.faixa.id + '-' + d.categoria.categoria.sexo + '-' + d.categoria.peso.id
                        var idEquipe = d.equipe.equipe
                        // Categoria
                        if (!(id in li)) {
                            li[id] = [d]
                        } else {
                            li[id].push(d)
                        }
                        // Equipe
                        if (!(idEquipe in liEquipe)) {
                            liEquipe[idEquipe] = [d]
                        } else {
                            liEquipe[idEquipe].push(d)
                        }
                        // Absoluto
                        if (d.categoria.absoluto.id) {
                            var idAbs = d.categoria.absoluto.id + '-' + d.categoria.categoria.sexo
                            if (!(idAbs in liAbs)) {
                                liAbs[idAbs] = [d]
                                servicos.eventos.collection('absolutos').doc(d.categoria.absoluto.id).get().then((ab) => {
                                    liAbs[idAbs].posicao = ab.data().posicao
                                })
                            } else {
                                liAbs[idAbs].push(d)
                                servicos.eventos.collection('absolutos').doc(d.categoria.absoluto.id).get().then((ab) => {
                                    liAbs[idAbs].posicao = ab.data().posicao
                                })
                            }
                        }
                    })
                    Object.keys(li).forEach((d) => {
                        l.push(li[d])
                    })
                    Object.keys(liAbs).forEach((d) => {
                        lAbs.push(liAbs[d])
                    })
                    Object.keys(liEquipe).forEach((d) => {
                        lEquipe.push(liEquipe[d])
                    })
                    setChecagem(l)
                    setChecagemAbs(lAbs)
                    setChecagemEquipe(lEquipe)
                    console.log(lAbs)
                })
            } else {
                servicos.inscricoes
                    .where('status', '==', 2)
                    .get().then((snap) => {
                        const data = snap.docs.map(doc => doc.data())
                        setInscricoes(data)
                        setLoading(false)
                        data.forEach((d) => {
                            var id = d.categoria.categoria.id + '-' + d.categoria.faixa.id + '-' + d.categoria.categoria.sexo + '-' + d.categoria.peso.id
                            var idEquipe = d.equipe.equipe
                            // Categoria
                            if (!(id in li)) {
                                li[id] = [d]
                            } else {
                                li[id].push(d)
                            }
                            // Equipe
                            if (!(idEquipe in liEquipe)) {
                                liEquipe[idEquipe] = [d]
                            } else {
                                liEquipe[idEquipe].push(d)
                            }
                            // Absoluto
                            if (d.categoria.absoluto.id && d.statusAbsoluto > 0) {
                                if (d.statusAbsoluto === 2) {
                                    var idAbs = d.categoria.absoluto.id + '-' + d.categoria.categoria.sexo
                                    if (!(idAbs in liAbs)) {
                                        liAbs[idAbs] = [d]
                                    } else {
                                        liAbs[idAbs].push(d)
                                    }
                                }
                            } else if (d.categoria.absoluto.id && d.statusAbsoluto === 0) {
                                var idAbs = d.categoria.absoluto.id + '-' + d.categoria.categoria.sexo
                                if (!(idAbs in liAbs)) {
                                    liAbs[idAbs] = [d]
                                } else {
                                    liAbs[idAbs].push(d)
                                }
                            }
                        })
                        Object.keys(li).forEach((d) => {
                            l.push(li[d])
                        })
                        Object.keys(liAbs).forEach((d) => {
                            lAbs.push(liAbs[d])
                        })
                        Object.keys(liEquipe).forEach((d) => {
                            lEquipe.push(liEquipe[d])
                        })
                        setChecagem(l)
                        setChecagemAbs(lAbs)
                        setChecagemEquipe(lEquipe)
                        console.log(lAbs)
                    })
            }
        })
        servicos.eventos.collection("categorias").onSnapshot((snap) => {
            const data = snap.docs.map(e => e.data())
            setCategorias(data);

        })

        servicos.eventos.collection("absolutos").onSnapshot((snap) => {
            const data = snap.docs.map(e => e.data())
            setAbsolutos(data);
        })
    }, [])

    function buscaTipo(tipo) {
        setTipoChecagem(tipo)
    }
    function buscaTipoOutros(tipo) {
        setTipoChecagem(tipo)
    }

    function filtrarCatgoria() {
        setChecagem([])
        if (!categoria && !faixa && !peso && !sexo) {
            return mensagemAlerta("Favor escolha ao menos um campo para filtrar")
        }
        const filtro = [sexo, categoria]
        servicos.eventos.collection("checagem").where("filtro", "array-contains-any", filtro).onSnapshot((snap) => {
            const data = snap.docs.map(e => e.data())
            setChecagem(data);
        })
    }

    function filtrarAbsoluto() {
        if (absoluto === "") {
            return mensagemAlerta("Favor um absoluto para filtrar")
        }
        servicos.eventos.collection("checagem")
            .where("absoluto", "==", true)
            .where("idAbsoluto", "==", absoluto)
            .onSnapshot((snap) => {
                const data = snap.docs.map(e => e.data())
                setChecagem(data);
            })
    }

    const handleCategoria = (event) => {
        setPesos([])
        setFaixas([])
        setCategoria("")
        setPeso("")
        setFaixa("")
        event.persist();
        if (event.target.value !== "" && event.target.value) {
            mensagemLoading("Carregando dados")
            setCategoria(event.target.value)
            servicos.eventos.collection("categorias").doc(event.target.value).collection("pesos").onSnapshot((snap) => {
                Swal.close()
                const data = snap.docs.map(doc => doc.data())
                setPesos(data)
            })
            servicos.eventos.collection("categorias").doc(event.target.value).collection("faixas").onSnapshot((snap) => {
                const data = snap.docs.map(doc => doc.data())
                setFaixas(data)
            })
        } else {
            setCategoria("")
        }
    }

    const handleFaixa = (event) => {
        event.persist();
        if (event.target.value !== "") {
            setFaixa(event.target.value)
        } else {
            setFaixa("")
        }
    }
    const handlePeso = (event) => {
        event.persist();
        if (event.target.value !== "") {
            setPeso(event.target.value)
        } else {
            setPeso("")
        }
    }
    const handleSexo = (event) => {
        event.persist();
        if (event.target.value !== "") {
            setSexo(event.target.value)
        } else {
            setSexo("")
        }
    }

    const handleAbsoluto = (event) => {
        event.persist();
        if (event.target.value !== "") {
            setAbsoluto(event.target.value)
        } else {
            setAbsoluto("")
        }
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location = window.location.href.replace('/inscricao/', '/pagamento/')
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div>
            <div className="body-wrapper blank-loder">
                <Header />
                <div class="section_fullwidth">
                    <div class="headline headline_img">
                        <div class="headline__inner">
                            <div class="headline__title">
                                <h1>Checagem {evento.id && evento.nome}</h1>
                            </div>
                            <div class="headline__description">
                                <span>Atletas pendentes, pagos ou insentos de pagamento </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div >
                    <main >
                        <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                                <h3 style={{ marginLeft: 16 }}>Tipos de Inscritos</h3>
                                                <div class="wpb_wrapper vc_col-md-12 row" style={{ marginTop: 20 }}>
                                                    <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-3" onClick={() => buscaTipo("geral")}>
                                                        <a href="javascript:void(0)" class="btn_defoult btn_fill "> Atleta / Geral</a>
                                                    </div>
                                                    <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-3" onClick={() => buscaTipo("categoria")}>
                                                        <a href="javascript:void(0)" class="btn_defoult btn_fill "> Por Categoria</a>
                                                    </div>
                                                    <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-3" onClick={() => buscaTipoOutros("absoluto")}>
                                                        <a href="javascript:void(0)" class="btn_defoult btn_fill "> Por Absoluto</a>
                                                    </div>
                                                    <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-3" onClick={() => buscaTipoOutros("equipe")}>
                                                        <a href="javascript:void(0)" class="btn_defoult btn_fill "> Por Equipe</a>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                                <h3 style={{ marginLeft: 16 }}>Checagem por {tipoChecagem}</h3>
                                                {/* {tipoChecagem === "categoria" ?
                                                    <div class="wpb_wrapper vc_col-md-12 row" style={{ marginTop: 20 }}>

                                                        <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-3" >
                                                            <select name="select" id="select" class="form-control" onChange={handleCategoria}>
                                                                <option value="" >Escolha a categoria </option>
                                                                {categorias.sort((a, b) => {
                                                                    return parseInt(a.posicao) - parseInt(b.posicao)
                                                                }).map((c) =>
                                                                    <option value={c.id} >{c.categoria} - {c.tipoIdade === 0 ? "Até " + c.idade + " anos" : c.tipoIdade === 1 ? "De " + c.minIdade + " até " + c.maxIdade + " anos" : "Acima de " + c.idade}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                        <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-2" >
                                                            <select name="select" id="select" class="form-control" onChange={handleFaixa}>
                                                                <option value="">Escolha o Faixa </option>
                                                                {faixas.sort((a, b) => {
                                                                    return parseInt(a.posicao) - parseInt(b.posicao)
                                                                }).map((f) =>
                                                                    <option value={f.id} >{f.faixa}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                        <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-3" >
                                                            <select name="select" id="select" class="form-control" onChange={handlePeso}>
                                                                <option value="">Escolha o Peso </option>
                                                                {pesos.sort((a, b) => {
                                                                    return a.peso - b.peso
                                                                }).map((c) =>
                                                                    <option value={c.id} >{c.nome} - {c.tipoPeso === 0 ? "Até " + c.peso + " Kg" : "Acima " + c.peso + " Kg"}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                        <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-2" >
                                                            <select name="select" id="select" class="form-control" onChange={handleSexo}>
                                                                <option value="">Escolha o Sexo </option>
                                                                <option value="masculino">Masculino </option>
                                                                <option value="feminino">Feminino </option>
                                                            </select>
                                                        </div>
                                                        <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-2" onClick={() => filtrarCatgoria()}>
                                                            <a href="javascript:void(0)" class="btn_defoult btn_fill "> Filtrar</a>
                                                        </div>
                                                    </div>
                                                    : tipoChecagem === "absoluto" ?
                                                        <div class="wpb_wrapper vc_col-md-12 row" style={{ marginTop: 20 }}>
                                                            <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-6" >
                                                                <select name="select" id="select" class="form-control" onChange={handleAbsoluto}>
                                                                    <option value="" >Escolha o Absoluto </option>
                                                                    {absolutos.sort((a, b) => {
                                                                        return parseInt(a.posicao) - parseInt(b.posicao)
                                                                    }).map((c) =>
                                                                        <option value={c.id} >{c.absoluto}</option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                            <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-2" onClick={() => filtrarAbsoluto()}>
                                                                <a href="javascript:void(0)" class="btn_defoult btn_fill "> Filtrar</a>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                } */}
                                            </div>
                                            {/* Checagem geral */}
                                            {tipoChecagem === "geral" ?
                                                <>
                                                    <MediaQuery minWidth={1224}>
                                                        <div class="vc_col-sm-12">
                                                            <Table>
                                                                <Thead>
                                                                    <Tr>
                                                                        <Th class="schedule-table__day">Atleta</Th>
                                                                        <Th class="schedule-table__day">Categoria / Faixa / Peso / Sexo</Th>
                                                                    </Tr>
                                                                </Thead>
                                                                <Tbody>
                                                                    {inscricoes.sort((a, b) => {
                                                                        if (a.usuario.nome < b.usuario.nome)
                                                                            return -1;
                                                                        if (a.usuario.nome > b.usuario.nome)
                                                                            return 1;
                                                                        return 0;
                                                                    }).map((inscricao, index) =>
                                                                        <Tr key={inscricao.id}>
                                                                            <Td>
                                                                                <div className="ratings-widget__item " style={{ marginTop: 10 }}>
                                                                                    <div className="ratings-widget__img js-hover-veil"><img
                                                                                        src={inscricao.usuario.avatar}
                                                                                        onError={(e) => e.target.src = `${profile}`}
                                                                                        style={{ height: 120 }}
                                                                                        alt="" title="image here (2)" />
                                                                                    </div>
                                                                                    <div className="ratings-widget__details ">
                                                                                        <div className="ratings-widget__details-title"> <h5>{inscricao.usuario.nome} {inscricao.usuario.sobrenome}</h5></div>
                                                                                        <div className="ratings-widget__details-title"> <h5>{inscricao.categoria.absoluto.id ? 'Categoria Peso e Absoluto' : 'Somente Peso'}</h5></div>
                                                                                        <div className="ratings-widget__details-cat"><p><b>Equipe</b> {inscricao.equipe.nomeEquipe}</p></div>
                                                                                        <div className="ratings-widget__details-cat"><p><b>Professor(a)</b> {inscricao.equipe.nomeProfessor}</p></div>
                                                                                        {evento.situacaoPagamentoChecagem === "SIM" || !evento.situacaoPagamentoChecagem ?
                                                                                            <>
                                                                                                {inscricao.pagamento && inscricao.status === 2 ?
                                                                                                    <div className="ratings-widget__details-cat"> Pagamento Realizado <i className='fa  fa-check-circle' aria-hidden='true' style={{ color: 'green' }}></i></div>
                                                                                                    :
                                                                                                    <div className="ratings-widget__details-cat"> Pagamento Pendente <i className='fa  fa-exclamation-circle' aria-hidden='true' style={{ color: 'red' }}></i></div>
                                                                                                }
                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                        }

                                                                                    </div>
                                                                                </div>
                                                                            </Td>
                                                                            <Td>
                                                                                <p><b>{inscricao.categoria.categoria.tipoIdade === 0 ? inscricao.categoria.categoria.categoria + " ( Até " + inscricao.categoria.categoria.idade + " anos )" : inscricao.categoria.categoria.tipoIdade === 1 ? inscricao.categoria.categoria.categoria + "( De " + inscricao.categoria.categoria.minIdade + " até " + inscricao.categoria.categoria.maxIdade + " anos )" : inscricao.categoria.categoria.categoria + "( Acima de " + inscricao.categoria.categoria.idade + " anos )"} / FAIXA {inscricao.categoria.faixa.faixa} / {inscricao.categoria.peso.tipoPeso === 0 ? inscricao.categoria.peso.nome + " ( Até " + inscricao.categoria.peso.peso + " Kg )" : inscricao.categoria.peso.nome + " ( Acima " + inscricao.categoria.peso.peso + " Kg )"} / {inscricao.categoria.categoria.sexo.toUpperCase()}</b></p>
                                                                            </Td>
                                                                        </Tr>
                                                                    )}
                                                                </Tbody>
                                                            </Table>
                                                        </div>
                                                    </MediaQuery>
                                                    <MediaQuery minWidth={300} maxWidth={1000}>
                                                        <div class="vc_col-sm-12">
                                                            <h3>Atletas</h3>
                                                            {inscricoes.sort((a, b) => {
                                                                if (a.usuario.nome < b.usuario.nome)
                                                                    return -1;
                                                                if (a.usuario.nome > b.usuario.nome)
                                                                    return 1;
                                                                return 0;
                                                            }).map((inscricao, index) =>
                                                                <div style={{ width: "100%" }} key={inscricao.id}>
                                                                    <div class="ticket-style-1 " style={{ textAlign: "start", backgroundColor: "#ffffff" }}>
                                                                        <h3 class="ticket-style-1__title" style={{ textAlign: "start", padding: 10, color: "#000000" }}>{inscricao.usuario.nome.toUpperCase()} {inscricao.usuario.sobrenome ? inscricao.usuario.sobrenome.toUpperCase() : ""} <br />{inscricao.categoria.absoluto.id ? "Categoria Peso e Absoluto" : "Somente Peso"}</h3>
                                                                        <p style={{ fontSize: 12, textAlign: "start", marginLeft: 10 }}>{inscricao.categoria.categoria.tipoIdade === 0 ? inscricao.categoria.categoria.categoria + " ( Até " + inscricao.categoria.categoria.idade + " anos )" : inscricao.categoria.categoria.tipoIdade === 1 ? inscricao.categoria.categoria.categoria + " ( De " + inscricao.categoria.categoria.minIdade + " até " + inscricao.categoria.categoria.maxIdade + " anos )" : inscricao.categoria.categoria.categoria + "( Acima de " + inscricao.categoria.categoria.idade + " anos )"} <br />{inscricao.categoria.faixa.faixa} / {inscricao.categoria.peso.tipoPeso === 0 ? inscricao.categoria.peso.nome + " ( Até " + inscricao.categoria.peso.peso + " Kg )" : inscricao.categoria.peso.nome + " ( Acima " + inscricao.categoria.peso.peso + " Kg )"} / {inscricao.categoria.categoria.sexo.toUpperCase()} <br />{inscricao.equipe.nomeEquipe} / {inscricao.equipe.nomeProfessor}</p>
                                                                        {evento.situacaoPagamentoChecagem === "SIM" || !evento.situacaoPagamentoChecagem ?
                                                                            <>
                                                                                {inscricao.pagamento && inscricao.status === 2 ?
                                                                                    <p style={{ fontSize: 10, textAlign: "start", marginLeft: 10 }}> Pagamento Realizado <i className='fa  fa-check-circle' aria-hidden='true' style={{ color: 'green' }}></i></p>
                                                                                    :
                                                                                    <p style={{ fontSize: 10, textAlign: "start", marginLeft: 10 }}> Pagamento Pendente <i className='fa  fa-exclamation-circle' aria-hidden='true' style={{ color: 'red' }}></i></p>
                                                                                }
                                                                            </>
                                                                            :
                                                                            null
                                                                        }

                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </MediaQuery>
                                                </>
                                                :
                                                null
                                            }
                                            {tipoChecagem === "categoria" ?
                                                <div class="vc_col-sm-12">
                                                    {checagem.length > 0 ?
                                                        <>
                                                            {checagem.sort((a, b) => {
                                                                if (parseInt(a[0].categoria.categoria.posicao) < parseInt(b[0].categoria.categoria.posicao))
                                                                    return -1;
                                                                if (parseInt(a[0].categoria.categoria.posicao) > parseInt(b[0].categoria.categoria.posicao))
                                                                    return 1;
                                                                if (parseInt(a[0].categoria.faixa.posicao) < parseInt(b[0].categoria.faixa.posicao))
                                                                    return -1;
                                                                if (parseInt(a[0].categoria.faixa.posicao) > parseInt(b[0].categoria.faixa.posicao))
                                                                    return 1;
                                                                if (parseInt(a[0].categoria.peso.peso) < parseInt(b[0].categoria.peso.peso))
                                                                    return -1;
                                                                if (parseInt(a[0].categoria.peso.peso) > parseInt(b[0].categoria.peso.peso))
                                                                    return 1;
                                                                if (b[0].categoria.categoria.sexo < a[0].categoria.categoria.sexo)
                                                                    return -1;
                                                                if (b[0].categoria.categoria.sexo > a[0].categoria.categoria.sexo)
                                                                    return 1;
                                                                return 0;
                                                            }).map((itemChecagem) =>
                                                                <>
                                                                    <MediaQuery minWidth={1224}>
                                                                        <Accordion>
                                                                            <AccordionSummary
                                                                                expandIcon={<ExpandMoreIcon />}
                                                                                aria-controls="panel1a-content"
                                                                                id="panel1a-header"
                                                                            >
                                                                                <p>{itemChecagem[0].categoria.categoria.categoria} ({itemChecagem[0].categoria.categoria.tipoIdade === 0 ? "Até " + itemChecagem[0].categoria.categoria.idade + " anos" : itemChecagem[0].categoria.categoria.tipoIdade === 1 ? "De " + itemChecagem[0].categoria.categoria.minIdade + " até " + itemChecagem[0].categoria.categoria.maxIdade + " anos" : "Acima de " + itemChecagem[0].categoria.categoria.idade}) , {itemChecagem[0].categoria.faixa.faixa}, {itemChecagem[0].categoria.peso.nome} ({itemChecagem[0].categoria.peso.tipoPeso === 0 ? "ATÉ " + itemChecagem[0].categoria.peso.peso + " KG" : "ACIMA " + itemChecagem[0].categoria.peso.peso + " KG"}), {itemChecagem[0].categoria.categoria.sexo} - {itemChecagem.length} Inscritos</p>
                                                                            </AccordionSummary>
                                                                            <AccordionDetails >
                                                                                <Grid container spacing={3}>
                                                                                    {itemChecagem.map((inscricao) =>
                                                                                        <Grid item xs={6}>
                                                                                            <div key={inscricao.id} className="ratings-widget__item  vc_col-lg-12" style={{ marginTop: 10 }}>
                                                                                                <Link to="#" >
                                                                                                    <div className="ratings-widget__img js-hover-veil"><img
                                                                                                        src={inscricao.usuario.avatar}
                                                                                                        onError={(e) => e.target.src = `${profile}`}
                                                                                                        style={{ height: 120 }}
                                                                                                        alt="" title="image here (2)" />
                                                                                                    </div>
                                                                                                </Link>
                                                                                                <div className="ratings-widget__details ">
                                                                                                    <div className="ratings-widget__details-title"> <h5>{inscricao.usuario.nome} {inscricao.usuario.sobrenome}</h5></div>
                                                                                                    <div className="ratings-widget__details-cat"><p><b>Equipe</b> {inscricao.equipe.nomeEquipe}</p></div>
                                                                                                    <div className="ratings-widget__details-cat"><p><b>Professor(a)</b> {inscricao.equipe.nomeProfessor}</p></div>
                                                                                                    {evento.situacaoPagamentoChecagem === "SIM" || !evento.situacaoPagamentoChecagem ?
                                                                                                        <>
                                                                                                            {inscricao.pagamento && inscricao.status === 2 ?
                                                                                                                <div className="ratings-widget__details-cat"> Pagamento Realizado <i className='fa  fa-check-circle' aria-hidden='true' style={{ color: 'green' }}></i></div>
                                                                                                                :
                                                                                                                <div className="ratings-widget__details-cat"> Pagamento Pendente <i className='fa  fa-exclamation-circle' aria-hidden='true' style={{ color: 'red' }}></i></div>
                                                                                                            }
                                                                                                        </>
                                                                                                        :
                                                                                                        null
                                                                                                    }

                                                                                                </div>
                                                                                            </div>
                                                                                        </Grid>
                                                                                    )}
                                                                                </Grid>
                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                    </MediaQuery>
                                                                    <MediaQuery minWidth={300} maxWidth={1000}>
                                                                        <Accordion>
                                                                            <AccordionSummary
                                                                                expandIcon={<ExpandMoreIcon />}
                                                                                aria-controls="panel1a-content"
                                                                                id="panel1a-header"
                                                                            >
                                                                                {/* <Typography className={classes.heading}>{itemChecagem.nomeCategoria.toUpperCase()} FAIXA {itemChecagem.nomeFaixa.toUpperCase()} PESO {itemChecagem.nomePeso.toUpperCase()} {itemChecagem.sexo.toUpperCase()} - ({inscricoes.filter((i) => i.checagem === itemChecagem.id).length} inscritos)</Typography> */}
                                                                                {/* <p style={{ fontSize: 12 }}><b>{itemChecagem.nomeCategoria.toUpperCase()}, {itemChecagem.nomeFaixa.toUpperCase()}, {itemChecagem.nomePeso.toUpperCase()}, {itemChecagem.sexo.toUpperCase()}</b></p> */}
                                                                                <p style={{ fontSize: 12 }}><b>{itemChecagem[0].categoria.categoria.categoria} ({itemChecagem[0].categoria.categoria.tipoIdade === 0 ? "Até " + itemChecagem[0].categoria.categoria.idade + " anos" : itemChecagem[0].categoria.categoria.tipoIdade === 1 ? "De " + itemChecagem[0].categoria.categoria.minIdade + " até " + itemChecagem[0].categoria.categoria.maxIdade + " anos" : "Acima de " + itemChecagem[0].categoria.categoria.idade}) , {itemChecagem[0].categoria.faixa.faixa}, {itemChecagem[0].categoria.peso.nome} ({itemChecagem[0].categoria.peso.tipoPeso === 0 ? "ATÉ " + itemChecagem[0].categoria.peso.peso + " KG" : "ACIMA " + itemChecagem[0].categoria.peso.peso + " KG"}), {itemChecagem[0].categoria.categoria.sexo} - {itemChecagem.length} Inscritos</b></p>
                                                                            </AccordionSummary>
                                                                            <AccordionDetails >
                                                                                <Grid container spacing={3}>
                                                                                    {itemChecagem.map((inscricao) =>
                                                                                        <Grid item xs={12}>
                                                                                            <div style={{ width: "100%" }} key={inscricao.id}>
                                                                                                <div class="ticket-style-1 " style={{ textAlign: "start", backgroundColor: "#ffffff" }}>
                                                                                                    <h3 class="ticket-style-1__title" style={{ textAlign: "start", padding: 10, color: "#000000" }}>{inscricao.usuario.nome.toUpperCase()} {inscricao.usuario.sobrenome ? inscricao.usuario.sobrenome.toUpperCase() : ""}</h3>
                                                                                                    <p style={{ fontSize: 12, textAlign: "start", marginLeft: 10 }}>{inscricao.categoria.categoria.tipoIdade === 0 ? inscricao.categoria.categoria.categoria + " ( Até " + inscricao.categoria.categoria.idade + " anos )" : inscricao.categoria.categoria.tipoIdade === 1 ? inscricao.categoria.categoria.categoria + " ( De " + inscricao.categoria.categoria.minIdade + " até " + inscricao.categoria.categoria.maxIdade + " anos )" : inscricao.categoria.categoria.categoria + "( Acima de " + inscricao.categoria.categoria.idade + " anos )"} <br />{inscricao.categoria.faixa.faixa} / {inscricao.categoria.peso.tipoPeso === 0 ? inscricao.categoria.peso.nome + " ( Até " + inscricao.categoria.peso.peso + " Kg )" : inscricao.categoria.peso.nome + " ( Acima " + inscricao.categoria.peso.peso + " Kg )"} / {inscricao.categoria.categoria.sexo.toUpperCase()} <br />{inscricao.equipe.nomeEquipe} / {inscricao.equipe.nomeProfessor}</p>
                                                                                                    <p style={{ fontSize: 10, textAlign: "start", marginLeft: 10 }}>

                                                                                                    </p>
                                                                                                    {evento.situacaoPagamentoChecagem === "SIM" || !evento.situacaoPagamentoChecagem ?
                                                                                                        <>
                                                                                                            {inscricao.pagamento && inscricao.status === 2 ?
                                                                                                                <p style={{ fontSize: 10, textAlign: "start", marginLeft: 10 }}> Pagamento Realizado <i className='fa  fa-check-circle' aria-hidden='true' style={{ color: 'green' }}></i></p>
                                                                                                                :
                                                                                                                <p style={{ fontSize: 10, textAlign: "start", marginLeft: 10 }}> Pagamento Pendente <i className='fa  fa-exclamation-circle' aria-hidden='true' style={{ color: 'red' }}></i></p>
                                                                                                            }
                                                                                                        </>
                                                                                                        :
                                                                                                        null
                                                                                                    }

                                                                                                </div>
                                                                                            </div>
                                                                                        </Grid>
                                                                                    )}
                                                                                </Grid>
                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                    </MediaQuery>
                                                                </>
                                                            )}
                                                        </>
                                                        :
                                                        <>
                                                            {loading ?
                                                                <h2><i className="fa  fa-spinner" aria-hidden="true"
                                                                    style={{ color: "#c21111" }}></i> Aguarde... Carregando checagem </h2>
                                                                :
                                                                <h4> Sem informações de filtro para esta consulta </h4>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                                :
                                                null
                                            }

                                            {/* Checagem por Absoluto */}
                                            {tipoChecagem === "absoluto" ?
                                                <div class="vc_col-sm-12">
                                                    {checagemAbs.length > 0 ?
                                                        <>
                                                            {checagemAbs.sort((a, b) => {
                                                                if (parseInt(a[0].categoria.categoria.posicao) < parseInt(b[0].categoria.categoria.posicao))
                                                                    return -1;
                                                                if (parseInt(a[0].categoria.categoria.posicao) > parseInt(b[0].categoria.categoria.posicao))
                                                                    return 1;
                                                                return 0
                                                            }).map((itemChecagem) =>
                                                                <>
                                                                    <MediaQuery minWidth={1224}>
                                                                        <Accordion>
                                                                            <AccordionSummary
                                                                                expandIcon={<ExpandMoreIcon />}
                                                                                aria-controls="panel1a-content"
                                                                                id="panel1a-header"
                                                                            >
                                                                                <Typography className={classes.heading}>{itemChecagem[0].categoria.absoluto.absoluto.toUpperCase()} / {itemChecagem[0].categoria.categoria.sexo.toUpperCase()}  - ({itemChecagem.length} inscritos)</Typography>
                                                                            </AccordionSummary>
                                                                            <AccordionDetails className={classes.detalheAcordeon}>
                                                                                <Grid container spacing={3}>
                                                                                    {itemChecagem.map((inscricao) =>
                                                                                        <Grid item xs={6}>
                                                                                            <div key={inscricao.id} className="ratings-widget__item  vc_col-lg-12" style={{ marginTop: 10 }}>
                                                                                                <Link to="#" >
                                                                                                    <div className="ratings-widget__img js-hover-veil"><img
                                                                                                        src={inscricao.usuario.avatar}
                                                                                                        onError={(e) => e.target.src = `${profile}`}
                                                                                                        style={{ height: 120 }}
                                                                                                        alt="" title="image here (2)" />
                                                                                                    </div>
                                                                                                </Link>
                                                                                                <div className="ratings-widget__details ">
                                                                                                    <div className="ratings-widget__details-title"> <h5>{inscricao.usuario.nome} {inscricao.usuario.sobrenome}</h5></div>
                                                                                                    <div className="ratings-widget__details-cat"><p><b>Equipe</b> {inscricao.equipe.nomeEquipe}</p></div>
                                                                                                    <div className="ratings-widget__details-cat"><p><b>Professor(a)</b> {inscricao.equipe.nomeProfessor}</p></div>
                                                                                                    {evento.situacaoPagamentoChecagem === "SIM" || !evento.situacaoPagamentoChecagem ?
                                                                                                        <>
                          {((inscricao?.categoria?.absoluto?.id && inscricao.statusAbsoluto === 0 && inscricao.status === 2 && inscricao.valorAbsoluto != 0)  || inscricao.statusAbsoluto === 2) ?
                                                                                                                <div className="ratings-widget__details-cat"> Pagamento Realizado <i className='fa  fa-check-circle' aria-hidden='true' style={{ color: 'green' }}></i></div>
                                                                                                                :
                                                                                                                <div className="ratings-widget__details-cat"> Pagamento Pendente <i className='fa  fa-exclamation-circle' aria-hidden='true' style={{ color: 'red' }}></i></div>
                                                                                                            }
                                                                                                        </>
                                                                                                        :
                                                                                                        null
                                                                                                    }


                                                                                                </div>
                                                                                            </div>
                                                                                        </Grid>
                                                                                    )}
                                                                                </Grid>
                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                    </MediaQuery>
                                                                    <MediaQuery minWidth={200} maxWidth={1000}>
                                                                        <Accordion>
                                                                            <AccordionSummary
                                                                                expandIcon={<ExpandMoreIcon />}
                                                                                aria-controls="panel1a-content"
                                                                                id="panel1a-header"
                                                                            >
                                                                                <p style={{ fontSize: 12 }}><b>{itemChecagem[0].categoria.absoluto.absoluto.toUpperCase()} / {itemChecagem[0].categoria.categoria.sexo.toUpperCase()}  - ({itemChecagem.length} inscritos)</b></p>
                                                                            </AccordionSummary>
                                                                            <AccordionDetails className={classes.detalheAcordeon}>
                                                                                <Grid container spacing={3}>
                                                                                    {itemChecagem.map((inscricao) =>
                                                                                        <Grid item xs={12}>
                                                                                            <div style={{ width: "100%" }} key={inscricao.id}>
                                                                                                <div class="ticket-style-1 " style={{ textAlign: "start", backgroundColor: "#ffffff" }}>
                                                                                                    <h3 class="ticket-style-1__title" style={{ textAlign: "start", padding: 10, color: "#000000" }}>{inscricao.usuario.nome.toUpperCase()} {inscricao.usuario.sobrenome ? inscricao.usuario.sobrenome.toUpperCase() : ""}</h3>
                                                                                                    <p style={{ fontSize: 12, textAlign: "start", marginLeft: 10 }}>{inscricao.categoria.categoria.tipoIdade === 0 ? inscricao.categoria.categoria.categoria + " ( ATÉ " + inscricao.categoria.categoria.idade + " anos )" : inscricao.categoria.categoria.tipoIdade === 1 ? inscricao.categoria.categoria.categoria + " ( De " + inscricao.categoria.categoria.minIdade + " ATÉ " + inscricao.categoria.categoria.maxIdade + " anos )" : inscricao.categoria.categoria.categoria + "( Acima de " + inscricao.categoria.categoria.idade + " anos )"} <br />{inscricao.categoria.faixa.faixa} / {inscricao.categoria.peso.tipoPeso === 0 ? inscricao.categoria.peso.nome + " ( ATÉ " + inscricao.categoria.peso.peso + " Kg )" : inscricao.categoria.peso.nome + " ( Acima " + inscricao.categoria.peso.peso + " Kg )"} / {inscricao.categoria.categoria.sexo.toUpperCase()} <br />{inscricao.equipe.nomeEquipe} / {inscricao.equipe.nomeProfessor}</p>
                                                                                                    <p style={{ fontSize: 10, textAlign: "start", marginLeft: 10 }}>

                                                                                                    </p>
                                                                                                    {evento.situacaoPagamentoChecagem === "SIM" || !evento.situacaoPagamentoChecagem ?
                                                                                                        <>
                                                                                                            {inscricao.pagamento && inscricao.status === 2 ?
                                                                                                                <p style={{ fontSize: 10, textAlign: "start", marginLeft: 10 }}> Pagamento Realizado <i className='fa  fa-check-circle' aria-hidden='true' style={{ color: 'green' }}></i></p>
                                                                                                                :
                                                                                                                <p style={{ fontSize: 10, textAlign: "start", marginLeft: 10 }}> Pagamento Pendente <i className='fa  fa-exclamation-circle' aria-hidden='true' style={{ color: 'red' }}></i></p>
                                                                                                            }
                                                                                                        </>
                                                                                                        :
                                                                                                        null
                                                                                                    }

                                                                                                </div>
                                                                                            </div>
                                                                                        </Grid>
                                                                                    )}
                                                                                </Grid>
                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                    </MediaQuery>
                                                                </>
                                                            )}
                                                        </>
                                                        :
                                                        <>
                                                            {loading ?
                                                                <h2><i className="fa  fa-spinner" aria-hidden="true"
                                                                    style={{ color: "#c21111" }}></i> Aguarde... Carregando checagem </h2>
                                                                :
                                                                <h4>Sem informações de filtro para esta consulta </h4>
                                                            }

                                                        </>
                                                    }
                                                </div>
                                                :
                                                null
                                            }

                                            {/* Checagem por Equipe */}
                                            {tipoChecagem === "equipe" ?
                                                <div class="vc_col-sm-12">
                                                    {checagemEquipe.length > 0 ?
                                                        <>
                                                            {checagemEquipe.sort((a, b) => {
                                                                if (a[0].equipe.nomeEquipe < b[0].equipe.nomeEquipe)
                                                                    return -1;
                                                                if (a[0].equipe.nomeEquipe > b[0].equipe.nomeEquipe)
                                                                    return 1;
                                                                return 0;
                                                            }).map((itemChecagem) =>
                                                                <>
                                                                    <MediaQuery minWidth={1224}>
                                                                        <Accordion>
                                                                            <AccordionSummary
                                                                                expandIcon={<ExpandMoreIcon />}
                                                                                aria-controls="panel1a-content"
                                                                                id="panel1a-header"
                                                                            >
                                                                                <Avatar alt="Remy Sharp" src={itemChecagem[0].equipe.avatar} className={classes.large} />
                                                                                <Typography className={classes.heading}> {itemChecagem[0].equipe.nomeEquipe.toUpperCase()}  - ({itemChecagem.length} inscritos)</Typography>
                                                                            </AccordionSummary>
                                                                            <AccordionDetails className={classes.detalheAcordeon}>
                                                                                <Grid container spacing={3}>
                                                                                    {itemChecagem.map((inscricao) =>
                                                                                        <Grid item xs={6}>
                                                                                            <div key={inscricao.id} className="ratings-widget__item  vc_col-lg-12" style={{ marginTop: 10 }}>
                                                                                                <Link to="#" >
                                                                                                    <div className="ratings-widget__img js-hover-veil"><img
                                                                                                        src={inscricao.usuario.avatar}
                                                                                                        onError={(e) => e.target.src = `${profile}`}
                                                                                                        style={{ height: 120 }}
                                                                                                        alt="" title="image here (2)" />
                                                                                                    </div>
                                                                                                </Link>
                                                                                                <div className="ratings-widget__details ">
                                                                                                    <div className="ratings-widget__details-title"> <h5>{inscricao.usuario.nome} {inscricao.usuario.sobrenome}</h5></div>
                                                                                                    <div className="ratings-widget__details-cat"><p><b>Equipe</b> {inscricao.equipe.nomeEquipe}</p></div>
                                                                                                    <div className="ratings-widget__details-cat"><p><b>Professor(a)</b> {inscricao.equipe.nomeProfessor}</p></div>
                                                                                                    {evento.situacaoPagamentoChecagem === "SIM" || !evento.situacaoPagamentoChecagem ?
                                                                                                        <>
                                                                                                            {inscricao.pagamento && inscricao.status === 2 ?
                                                                                                                <div className="ratings-widget__details-cat"> Pagamento Realizado <i className='fa  fa-check-circle' aria-hidden='true' style={{ color: 'green' }}></i></div>
                                                                                                                :
                                                                                                                <div className="ratings-widget__details-cat"> Pagamento Pendente <i className='fa  fa-exclamation-circle' aria-hidden='true' style={{ color: 'red' }}></i></div>
                                                                                                            }
                                                                                                        </>
                                                                                                        :
                                                                                                        null
                                                                                                    }


                                                                                                </div>
                                                                                            </div>
                                                                                        </Grid>
                                                                                    )}
                                                                                </Grid>
                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                    </MediaQuery>
                                                                    <MediaQuery minWidth={200} maxWidth={1000}>
                                                                        <Accordion>
                                                                            <AccordionSummary
                                                                                expandIcon={<ExpandMoreIcon />}
                                                                                aria-controls="panel1a-content"
                                                                                id="panel1a-header"
                                                                            >
                                                                                <p style={{ fontSize: 12 }}><b>{itemChecagem[0].equipe.nomeEquipe.toUpperCase()}  - ({itemChecagem.length} inscritos)</b></p>
                                                                            </AccordionSummary>
                                                                            <AccordionDetails className={classes.detalheAcordeon}>
                                                                                <Grid container spacing={3}>
                                                                                    {itemChecagem.map((inscricao) =>
                                                                                        <Grid item xs={12}>
                                                                                            <div style={{ width: "100%" }} key={inscricao.id}>
                                                                                                <div class="ticket-style-1 " style={{ textAlign: "start", backgroundColor: "#ffffff" }}>
                                                                                                    <h3 class="ticket-style-1__title" style={{ textAlign: "start", padding: 10, color: "#000000" }}>{inscricao.usuario.nome.toUpperCase()} {inscricao.usuario.sobrenome ? inscricao.usuario.sobrenome.toUpperCase() : ""}</h3>
                                                                                                    <p style={{ fontSize: 12, textAlign: "start", marginLeft: 10 }}>{inscricao.categoria.categoria.tipoIdade === 0 ? inscricao.categoria.categoria.categoria + " ( ATÉ " + inscricao.categoria.categoria.idade + " anos )" : inscricao.categoria.categoria.tipoIdade === 1 ? inscricao.categoria.categoria.categoria + " ( De " + inscricao.categoria.categoria.minIdade + " ATÉ " + inscricao.categoria.categoria.maxIdade + " anos )" : inscricao.categoria.categoria.categoria + "( Acima de " + inscricao.categoria.categoria.idade + " anos )"} <br />{inscricao.categoria.faixa.faixa} / {inscricao.categoria.peso.tipoPeso === 0 ? inscricao.categoria.peso.nome + " ( ATÉ " + inscricao.categoria.peso.peso + " Kg )" : inscricao.categoria.peso.nome + " ( Acima " + inscricao.categoria.peso.peso + " Kg )"} / {inscricao.categoria.categoria.sexo.toUpperCase()} <br />{inscricao.equipe.nomeEquipe} / {inscricao.equipe.nomeProfessor}</p>
                                                                                                    <p style={{ fontSize: 10, textAlign: "start", marginLeft: 10 }}>

                                                                                                    </p>
                                                                                                    {evento.situacaoPagamentoChecagem === "SIM" || !evento.situacaoPagamentoChecagem ?
                                                                                                        <>
                                                                                                            {inscricao.pagamento && inscricao.status === 2 ?
                                                                                                                <p style={{ fontSize: 10, textAlign: "start", marginLeft: 10 }}> Pagamento Realizado <i className='fa  fa-check-circle' aria-hidden='true' style={{ color: 'green' }}></i></p>
                                                                                                                :
                                                                                                                <p style={{ fontSize: 10, textAlign: "start", marginLeft: 10 }}> Pagamento Pendente <i className='fa  fa-exclamation-circle' aria-hidden='true' style={{ color: 'red' }}></i></p>
                                                                                                            }
                                                                                                        </>
                                                                                                        :
                                                                                                        null
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </Grid>
                                                                                    )}
                                                                                </Grid>
                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                    </MediaQuery>
                                                                </>
                                                            )}
                                                        </>
                                                        :
                                                        <>
                                                            {loading ?
                                                                <h2><i className="fa  fa-spinner" aria-hidden="true"
                                                                    style={{ color: "#c21111" }}></i> Aguarde... Carregando checagem </h2>
                                                                :
                                                                <h4>Sem informações de filtro para esta consulta </h4>
                                                            }

                                                        </>
                                                    }
                                                </div>
                                                :
                                                null
                                            }


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </main>
                </div>
                <Footer />
            </div>
        </div>
    );
}
