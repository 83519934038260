import {
    useState, useEffect
} from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import moment from 'moment'
import firebase from 'firebase'
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'


export default function Evento() {
    const [servicos] = useState({
        eventos: firebase.firestore().collection("eventos"),
        equipes: firebase.firestore().collection("equipes"),
        usuarios: firebase.firestore().collection("usuarios"),
        inscricoes: firebase.firestore().collection('inscricoes'),
        auth: firebase.auth()
    })
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [id] = useState(queryParams.get('e'));
    const [idInscricao] = useState(queryParams.get('i'));

    const [inscricao, setIncricao] = useState({})
    const [evento, setEvento] = useState({})
    const [usuario, setUsuario] = useState({})
    const [url, setUrl] = useState('')

    var metadata = { contentType: 'image/jpeg' };
    const [avatar, setAvatar] = useState([]);
    const [upload, setUpload] = useState('');

    useEffect(() => {
        servicos.auth.onAuthStateChanged((user) => {
            let idUser = JSON.parse(localStorage.getItem('usuarioId'))
            if (user) {
                mensagemLoading('Carregando dados do pagamento..')
                servicos.usuarios.doc(user.uid).get().then((doc) => {
                    setUsuario(doc.data())
                    servicos.inscricoes
                        .doc(idInscricao)
                        .get().then((snap) => {
                            const data = snap.data();
                            setIncricao(data)
                            servicos.eventos.doc(id).get().then((d) => {
                                Swal.close()
                                setEvento(d.data())
                            })
                        })
                })
            } else if (idUser) {
                mensagemLoading('Carregando dados do pagamento..')
                servicos.usuarios.doc(idUser).get().then((doc) => {
                    setUsuario(doc.data())
                    servicos.inscricoes
                        .doc(idInscricao)
                        .get().then((snap) => {
                            const data = snap.data();
                            setIncricao(data)
                            console.log(data.id)
                            servicos.eventos.doc(id).get().then((d) => {
                                Swal.close()
                                setEvento(d.data())
                            })
                        })
                })
            }
        })
    }, [])

    function cadastraAvatar() {
        Swal.close();
        // o comprovante será salvo em 'inscricao/comprovante' se a inscrição for ctg + abs ou apenas ctg
        // o comprovante sera salvo em 'inscricao/comprovante_absoluto' se a inscricao for apenas inclusão abs

        let update = { caminho: 'inscricao/comprovante', apenasAbsoluto: false }
        if (
            ((inscricao?.categoria?.categoria?.id && inscricao?.categoria?.absoluto?.id) &&
                (!inscricao?.pagamento?.status && !inscricao?.pagamentoAbsoluto?.status)) ||
            (inscricao?.categoria?.categoria?.id && !inscricao?.categoria?.absoluto?.id && !inscricao?.pagamento?.status)

        ) {
            update.caminho = 'inscricao/comprovante'
        } else if (
            ((inscricao?.categoria?.categoria?.id && inscricao?.categoria?.absoluto?.id) &&
                (inscricao?.pagamento?.status === 'approved' && inscricao?.pagamentoAbsoluto?.status === 'pending'))
        ) {
            update.caminho = 'inscricao/comprovante_absoluto'
            update.apenasAbsoluto = true;
        }

        // console.log('update: ', update);
        // return;

        mensagemLoading('Enviando comprovante')
        var uploadTask = firebase.storage().ref().child(update.caminho + inscricao.id).put(avatar[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                let _update = {}
                if (update.apenasAbsoluto) {
                    _update = { comprovanteAbsoluto: downloadURL }
                } else {
                    _update = { comprovante: downloadURL }
                }
                servicos.inscricoes.doc(inscricao.id).update(_update).then(() => {
                    Swal.close();
                    mensagemSucesso('Comprovante enviado com sucesso!')
                })
            });
        });
    }

    function enviaComprovante() {
        if (avatar.length === 0) {
            mensagemAlerta('Favor selecione seu comprovante de pagamento')
            return
        }
        cadastraAvatar();
    }

    function sucesso() {
        window.location = window.location.href.replace('/pagamento/' + id, '/sucesso/' + inscricao.id)
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location = window.location.href = `/minhaInscricao/${idInscricao}`
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }
    const handleArquivo = (event) => {
        event.persist();
        setAvatar(event.target.files)
    }


    function calculaValorTotal() {
        return inscricao?.valorCategoria + inscricao?.valorAbsoluto
    }
    function exibirDescontoFederacao() {
        return (
            inscricao?.descontoFiliacao
                ?.descontoAplicado
        )
    }

    return (
        <div className="body-wrapper blank-loder">
            <Header />
            <div class="section_fullwidth">
                <div class="headline headline_img">
                    <div class="headline__inner">
                        <div class="headline__title">
                            <h1>Pagagmento </h1>
                            <h1>Ao administrador do Evento</h1>
                        </div>
                        <div class="headline__description">
                            <span>Para realizar sua inscrição, escolha as formas de pagamentos.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section_default section-single-class-layout clearfix">
                <main class="section_default">
                    <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
                        <div class="wpb_column vc_column_container vc_col-sm-12">
                            <div class="vc_column-inner ">
                                <div class="wpb_wrapper">
                                    <div class="wpb_text_column wpb_content_element ">
                                        <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                        </div>
                                        <div class=" vc_col-sm-4">
                                            <div class="wpb_single_image wpb_content_element vc_align_left">
                                                <figure class="wpb_wrapper vc_figure">
                                                    <div class="package-style-1__img">
                                                        <img src={evento.avatar}
                                                            alt="" title="price-img" /></div>
                                                    <div class="package-style-1__descr  package-style-1_with-img">
                                                        <h3 class="package-style-1__title" style={{ color: "#121212" }}>{evento.nome} </h3>
                                                        <div class="package-style-1__text">
                                                            {evento.inicioEvento === evento.fimEvento ?
                                                                <div class="package-style-1__text"><i class="fa  fa-calendar" aria-hidden="true"
                                                                    style={{ color: "#c21111" }}></i> Dia {moment(evento.inicioEvento).format("DD/MM/YYYY")}</div>
                                                                :
                                                                <div class="package-style-1__text"><i class="fa  fa-calendar" aria-hidden="true"
                                                                    style={{ color: "#c21111" }}></i> De {moment(evento.inicioEvento).format("DD/MM/YYYY")} à {moment(evento.fimEvento).format("DD/MM/YYYY")}</div>
                                                            }
                                                            <div class="package-style-1__text"><i class="fa  fa-map-marker" aria-hidden="true"
                                                                style={{ color: "#c21111" }}></i> {evento.endereco ? evento.endereco.cidade : null} / {evento.endereco ? evento.endereco.uf : null}</div>
                                                        </div>
                                                        <div class="package-style-1__price clearfix">

                                                            {inscricao.status === 2 ? (
                                                                <td><b>{inscricao.valorAbsoluto ? inscricao.valorAbsoluto.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : ''}</b></td>
                                                            )
                                                                :
                                                                (
                                                                    <td><b>{inscricao.valorTotal ? inscricao.valorTotal.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : ''}</b></td>
                                                                )
                                                            }

                                                        </div>
                                                    </div>
                                                </figure>
                                                <figure class="wpb_wrapper vc_figure">
                                                    <div class="package-style-1__img">
                                                        <img src={usuario.avatar}
                                                            alt="" title="price-img" /></div>
                                                    <div class="package-style-1__descr  package-style-1_with-img">
                                                        <h3 class="package-style-1__title" style={{ color: "#121212" }}>{usuario.nome} {usuario.sobrenome}</h3>
                                                        <div class="package-style-1__text">
                                                            <div class="package-style-1__text"><i class="fa  fa-file" aria-hidden="true"
                                                                style={{ color: "#c21111" }}></i> CPF {usuario.cpf} </div>
                                                            <div class="package-style-1__text"><i class="fa  fa-user" aria-hidden="true"
                                                                style={{ color: "#c21111" }}></i> {usuario.sexo}</div>
                                                        </div>
                                                    </div>
                                                </figure>

                                            </div>
                                        </div>
                                        <div class="wpb_column vc_column_container vc_col-sm-8">
                                            <div class="vc_column-inner ">
                                                <div class="wpb_wrapper">
                                                    <div class="wpb_widgetised_column wpb_content_element">
                                                        <div class="wpb_wrapper">
                                                            <div role="form" class="row " >
                                                                <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix" style={{ marginTop: 20 }}>
                                                                    <h3>Categorias</h3>
                                                                    {inscricao.categoria ?
                                                                        <table style={{ width: "100%" }}>
                                                                            <thead>
                                                                                <tr >
                                                                                    <th class="schedule-table__day">Categoria</th>
                                                                                    <th class="schedule-table__day">Peso</th>
                                                                                    <th class="schedule-table__day">Faixa</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td style={{ height: 50 }}><h6>{inscricao.categoria.categoria.categoria}</h6></td>
                                                                                    <td><h6>{inscricao.categoria.peso.tipoPeso === 0 ? "Até " + inscricao.categoria.peso.peso + " Kg" : "Acima " + inscricao.categoria.peso.peso + " Kg"}</h6></td>
                                                                                    <td><h6>{inscricao.categoria.faixa.faixa}</h6></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        :
                                                                        null
                                                                    }
                                                                    {inscricao.categoria && inscricao.categoria.absoluto.id ?
                                                                        <table style={{ width: "100%", marginTop: 20 }}>
                                                                            <thead>
                                                                                <tr >
                                                                                    <th class="schedule-table__day">Absoluto</th>
                                                                                    <th class="schedule-table__day">Idade</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td style={{ height: 50 }}><h6>{inscricao.categoria.absoluto.absoluto}</h6></td>
                                                                                    <td><h6>{inscricao.categoria.categoria.tipoIdade === 0 ? "Até " + inscricao.categoria.categoria.idade + " anos" : inscricao.categoria.categoria.tipoIdade === 1 ? "De " + inscricao.categoria.categoria.minIdade + " até " + inscricao.categoria.categoria.maxIdade + " anos" : "Acima de " + inscricao.categoria.categoria.idade}</h6></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        :
                                                                        null
                                                                    }
                                                                    <table style={{ width: "100%", marginTop: 20 }}>
                                                                        <thead>
                                                                            <tr >
                                                                                <th class="schedule-table__day">Extrato</th>
                                                                            </tr>
                                                                        </thead>
                                                                    </table>
                                                                    <table style={{ width: "100%" }}>
                                                                        <tbody>
                                                                            {inscricao?.pagamento?.status === 'pending' && (
                                                                                <tr>
                                                                                    <td style={{ height: 50 }}>Valor da Categoria</td>
                                                                                    <td>R$ {inscricao.pagamento ? inscricao.valorCategoria : null}</td>
                                                                                </tr>
                                                                            )}

                                                                            {(inscricao.pagamento && (inscricao?.pagamento?.status === 'pending' || inscricao?.pagamento?.status === null)) && (
                                                                                <tr>
                                                                                    <td style={{ height: 50 }}>Valor da Categoria</td>
                                                                                    <td>{inscricao.pagamento ? inscricao.valorCategoria.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : null}</td>
                                                                                </tr>
                                                                            )}
                                                                            {(inscricao.pagamentoAbsoluto && (inscricao?.pagamentoAbsoluto?.status === 'pending' || inscricao?.pagamentoAbsoluto?.status === null)) && (
                                                                                <tr>
                                                                                    <td style={{ height: 50 }}>Valor Absoluto</td>
                                                                                    <td>{inscricao.pagamento ? inscricao.valorAbsoluto.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : null}</td>
                                                                                </tr>
                                                                            )}
                                                                            {inscricao.status !== 2 && (
                                                                                <>
                                                                                    {(inscricao?.voucher?.id && inscricao?.voucher?.tipo === "desconto") && (
                                                                                        <tr>
                                                                                            <td style={{ height: 50 }}><h6 >Voucher de {inscricao?.voucher?.tipo} aplicado</h6></td>

                                                                                            <td><h6>- {(calculaValorTotal() * parseFloat(inscricao?.voucher?.porcentagem) / 100).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })} ({inscricao?.voucher?.porcentagem}%)</h6></td>
                                                                                        </tr>
                                                                                    )}
                                                                                    {(inscricao?.voucher?.id && inscricao?.voucher?.tipo === "cortesia") && (
                                                                                        <tr>
                                                                                            <td style={{ height: 50 }}><h6 >Voucher de {inscricao?.voucher?.tipo} aplicado</h6></td>

                                                                                            <td><h6>GRÁTIS</h6></td>
                                                                                        </tr>
                                                                                    )}
                                                                                    {(inscricao?.voucher?.id && inscricao?.voucher?.tipo === "valor") && (
                                                                                        <tr>
                                                                                            <td style={{ height: 50 }}><h6 >Voucher de {inscricao?.voucher?.tipo} aplicado</h6></td>

                                                                                            <td><h6 >- {inscricao?.voucher?.valor ? parseFloat(inscricao?.voucher?.valor).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : ''}</h6></td>
                                                                                        </tr>
                                                                                    )}

                                                                                    {
                                                                                        ((exibirDescontoFederacao() && (!inscricao?.voucher?.tipo === "cortesia")) ||
                                                                                            (exibirDescontoFederacao() && (!inscricao?.voucher?.id)) ||
                                                                                            (exibirDescontoFederacao() && (['valor', 'desconto'].includes(inscricao?.voucher?.tipo))))
                                                                                        && (
                                                                                            <tr>
                                                                                                <td style={{ height: 50 }}><h6>Desconto de filiação aplicado</h6></td>
                                                                                                {inscricao?.descontoFiliacao
                                                                                                    ?.tipoDesconto === '1' ? (
                                                                                                    <td><h6>- {inscricao?.descontoFiliacao?.valorDescontoAplicado.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })} ({inscricao?.descontoFiliacao?.valorDesconto}%)</h6></td>
                                                                                                ) :
                                                                                                    (
                                                                                                        <td><h6>- {parseFloat(inscricao?.descontoFiliacao?.valorDescontoAplicado).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h6></td>
                                                                                                    )
                                                                                                }
                                                                                            </tr>
                                                                                        )}
                                                                                    {true && (
                                                                                        <tr>
                                                                                            <td style={{ height: 50 }}><h6 style={{ color: (inscricao?.voucher?.id || exibirDescontoFederacao()) ? 'green' : '' }}>{(inscricao?.voucher?.id || exibirDescontoFederacao()) ? 'Valor Total com desconto' : 'Valor Total'}</h6></td>

                                                                                            <td><h6 style={{ color: (inscricao?.voucher?.id || exibirDescontoFederacao()) ? 'green' : '' }}>{inscricao.valorTotal ? inscricao.valorTotal.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : ''}</h6></td>

                                                                                        </tr>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div >
                                                                    <div role="form" class="row ">
                                                                        <p class="contact-form-name vc_col-sm-12">
                                                                            <span class="wpcf7-form-control-wrap your-name">
                                                                                <h1>Dados Bancários</h1>
                                                                            </span>
                                                                        </p>
                                                                        <p class="contact-form-name vc_col-sm-2">
                                                                            <span class="wpcf7-form-control-wrap your-name">
                                                                                <h6>Agência</h6>
                                                                                <p>{evento.bancarios ? evento.bancarios.agencia : ""}</p>
                                                                            </span>
                                                                        </p>
                                                                        <p class="contact-form-name vc_col-sm-2">
                                                                            <span class="wpcf7-form-control-wrap your-name">
                                                                                <h6>Conta</h6>
                                                                                <p>{evento.bancarios ? evento.bancarios.conta : ""}</p>
                                                                            </span>
                                                                        </p>
                                                                        <p class="contact-form-name vc_col-sm-3">
                                                                            <span class="wpcf7-form-control-wrap your-name">
                                                                                <h6>Nome Favorecido</h6>
                                                                                <p>{evento.bancarios ? evento.bancarios.favorecido : ""}</p>
                                                                            </span>
                                                                        </p>
                                                                        <p class="contact-form-name vc_col-sm-3">
                                                                            <span class="wpcf7-form-control-wrap your-name">
                                                                                <h6>CPF/CNPJ</h6>
                                                                                <p>{evento.bancarios ? evento.bancarios.cpfCnpj : ""}</p>
                                                                            </span>
                                                                        </p>
                                                                        <p class="contact-form-name vc_col-sm-2">
                                                                            <span class="wpcf7-form-control-wrap your-name">
                                                                                <h6>Banco</h6>
                                                                                <p>{evento.bancarios ? evento.bancarios.banco : ""}</p>
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                    <div className="row col-md-12">
                                                                        <p class="contact-form-name vc_col-sm-12">
                                                                            <span class="wpcf7-form-control-wrap your-name">
                                                                                <h1>Dados PIX</h1>
                                                                            </span>
                                                                        </p>
                                                                        <p class="contact-form-name vc_col-sm-3">
                                                                            <span class="wpcf7-form-control-wrap your-name">
                                                                                <h6>Favorecido</h6>
                                                                                <p>{evento.bancarios ? evento.bancarios.favorecidoPix : ""}</p>
                                                                            </span>
                                                                        </p>
                                                                        <p class="contact-form-name vc_col-sm-3">
                                                                            <span class="wpcf7-form-control-wrap your-name">
                                                                                <h6>Tipo da Chave</h6>
                                                                                <p>{evento.bancarios ? evento.bancarios.tipoChave : ""}</p>
                                                                            </span>
                                                                        </p>
                                                                        <p class="contact-form-name vc_col-sm-2">
                                                                            <span class="wpcf7-form-control-wrap your-name">
                                                                                <h6>Número Pix</h6>
                                                                                <p>{evento.bancarios ? evento.bancarios.chavePix : ""}</p>
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                    <div className="row col-md-12">
                                                                        <p class="contact-form-name vc_col-sm-12">
                                                                            <span class="wpcf7-form-control-wrap your-name">
                                                                                <h1>Como pagar?</h1>
                                                                                <p>Para realizar o pagamento manual, realize o procedimento via PIX ou transferência bancária com os dados acima e depois envie o comprovante ao administrardor</p>
                                                                                <p>Após o procedimento é aguardar a validação do seu pagamento com o administrador.</p>
                                                                            </span>
                                                                        </p>
                                                                        <p class="contact-form-name vc_col-sm-12">
                                                                            <span class="wpcf7-form-control-wrap your-name">
                                                                                <h6>Enviar Comprovante</h6>
                                                                                <p>Imagens permitidas (png, jpeg)</p>
                                                                                <input onChange={handleArquivo} type="file" accept=".png,.jpeg, .jpg" name="name" placeholder="Escolha a imagem " />
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <a href="javascript:void(0)" onClick={() => enviaComprovante()} style={{ width: "100%", marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill " >Enviar meu comprovante</a>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>
            </div>
            <Footer />
        </div >
    );
}
