import {
    useState, useEffect,useRef
} from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import moment from 'moment'
import firebase from 'firebase'
import Swal from 'sweetalert2'
import { geraPreferencia } from '../service/MercadoPago'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'


export default function Evento() {
    const ref = useRef(null);
    const [servicos] = useState({
        eventos: firebase.firestore().collection("eventos"),
        equipes: firebase.firestore().collection("equipes"),
        usuarios: firebase.firestore().collection("usuarios"),
        inscricoes: firebase.firestore().collection('inscricoes'),
        auth: firebase.auth()
    })

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
  
    const [id] = useState(queryParams.get('e'));
    const [idInscricao] = useState(queryParams.get('i'));

    const [inscricao, setIncricao] = useState({})
    const [evento, setEvento] = useState({})
    const [usuario, setUsuario] = useState({})
    const [url, setUrl] = useState('')

    useEffect(() => {
        servicos.auth.onAuthStateChanged((user) => {
            let idUser = JSON.parse(localStorage.getItem('usuarioId'))
            if (user) {
                mensagemLoading('Carregando dados do pagamento..')
                servicos.usuarios.doc(user.uid).get().then((doc) => {
                    setUsuario(doc.data())
                    servicos.inscricoes
                        .doc(idInscricao)
                        .get().then((snap) => {
                            const data = snap.data();
                            setIncricao(data)
                            if (data.voucher.id) {
                                if (data.voucher.tipo === "desconto") {
                                    data.valorTotal = (parseFloat(data.valorAbsoluto)) - ((parseFloat(data.valorAbsoluto)) * parseFloat(data.voucher.porcentagem) / 100)
                                } else if (data.voucher.tipo === "valor") {
                                    data.valorTotal = (parseFloat(data.valorAbsoluto)) - parseFloat(data.voucher.valor)
                                }
                            }
                            servicos.eventos.doc(id).get().then((d) => {
                                setEvento(d.data())
                                if (d.data().formaPagamento === 0) {
                                    const dados = {
                                        items: [
                                            {
                                                id: data.id,
                                                title: data.categoria.absoluto.absoluto.toUpperCase() + " - (" + data.evento.nome + ")",
                                                description: "Evento realizado pela equipe Procompetidor",
                                                picture_url: data.avatar,
                                                category_id: "electronics",
                                                quantity: 1,
                                                currency_id: "R$",
                                                unit_price: parseFloat(data.valorAbsoluto),
                                            }
                                        ],
                                        payment_methods: {
                                            excluded_payment_types: [
                                                { id: "digital_wallet" },
                                                { id: "digital_currency" },
                                            ],
                                            installments: 2
                                        },
                                        payer: {
                                            name: usuario.nome,
                                            surname: usuario.sobrenome,
                                            email: usuario.email,
                                        },
                                        auto_return: "all",
                                        external_reference: data.id,
                                        back_urls: {
                                            success: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                            pending: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                            failure: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                            // success: "http://localhost:3000/minhaInscricao/" + data.id,
                                            // pending: "http://localhost:3000/minhaInscricao/" + data.id,
                                            // failure: "http://localhost:3000/minhaInscricao/" + data.id,
                                        },
                                        notification_url: "https://us-central1-procompetidor-7867b.cloudfunctions.net/app/notificacao?source_news=ipn",
                                        statement_descriptor: "PRO COMPETIDOR",
                                        
                                    }
                                    const element = ref.current;
                                    geraPreferencia(dados, d.data().chavePagamento,element.value).then((doc) => {
                                        setUrl(doc.init_point);
                                        Swal.close();
                                    })
                                } else if (d.data().formaPagamento === 1 || d.data().formaPagamento === 7) {
                                    const dados = {
                                        items: [
                                            {
                                                id: data.id,
                                                title: data.categoria.absoluto.absoluto.toUpperCase() + " - (" + data.evento.nome + ")",
                                                description: "Evento realizado pela equipe Procompetidor",
                                                picture_url: data.avatar,
                                                category_id: "electronics",
                                                quantity: 1,
                                                currency_id: "R$",
                                                unit_price: parseFloat(data.valorAbsoluto),
                                            }
                                        ],
                                        payment_methods: {
                                            excluded_payment_types: [
                                                { id: "ticket" },
                                                { id: "digital_wallet" },
                                                { id: "digital_currency" },
                                            ],
                                            installments: 2
                                        },
                                        payer: {
                                            name: usuario.nome,
                                            surname: usuario.sobrenome,
                                            email: usuario.email,
                                        },
                                        auto_return: "all",
                                        external_reference: data.id,
                                        back_urls: {
                                            success: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                            pending: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                            failure: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                            // success: "http://localhost:3000/minhaInscricao/" + data.id,
                                            // pending: "http://localhost:3000/minhaInscricao/" + data.id,
                                            // failure: "http://localhost:3000/minhaInscricao/" + data.id,
                                        },
                                        notification_url: "https://us-central1-procompetidor-7867b.cloudfunctions.net/app/notificacao?source_news=ipn",
                                        statement_descriptor: "PRO COMPETIDOR",
                                        
                                    }
                                    const element = ref.current;
                                    geraPreferencia(dados, d.data().chavePagamento,element.value).then((doc) => {
                                        setUrl(doc.init_point);
                                        Swal.close();
                                    })
                                } else if (d.data().formaPagamento === 2) {
                                    const dados = {
                                        items: [
                                            {
                                                id: data.id,
                                                title: data.categoria.absoluto.absoluto.toUpperCase() + " - (" + data.evento.nome + ")",
                                                description: "Evento realizado pela equipe Procompetidor",
                                                picture_url: data.avatar,
                                                category_id: "electronics",
                                                quantity: 1,
                                                currency_id: "R$",
                                                unit_price: parseFloat(data.valorAbsoluto),
                                            }
                                        ],
                                        payment_methods: {
                                            excluded_payment_types: [
                                                { id: "credit_card" },
                                                { id: "digital_wallet" },
                                                { id: "digital_currency" },
                                            ],
                                            installments: 2
                                        },
                                        payer: {
                                            name: usuario.nome,
                                            surname: usuario.sobrenome,
                                            email: usuario.email,
                                        },
                                        auto_return: "all",
                                        external_reference: data.id,
                                        back_urls: {
                                            success: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                            pending: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                            failure: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                            // success: "http://localhost:3000/minhaInscricao/" + data.id,
                                            // pending: "http://localhost:3000/minhaInscricao/" + data.id,
                                            // failure: "http://localhost:3000/minhaInscricao/" + data.id,
                                        },
                                        notification_url: "https://us-central1-procompetidor-7867b.cloudfunctions.net/app/notificacao?source_news=ipn",
                                        statement_descriptor: "PRO COMPETIDOR",
                                        
                                    }
                                    const element = ref.current;
                                    geraPreferencia(dados, d.data().chavePagamento,element.value).then((doc) => {
                                        setUrl(doc.init_point);
                                        Swal.close();
                                    })
                                } else if (d.data().formaPagamento === 5 || d.data().formaPagamento === 3) {
                                    const dados = {
                                        items: [
                                            {
                                                id: data.id,
                                                title: data.categoria.absoluto.absoluto.toUpperCase() + " - (" + data.evento.nome + ")",
                                                description: "Evento realizado pela equipe Procompetidor",
                                                picture_url: data.avatar,
                                                category_id: "electronics",
                                                quantity: 1,
                                                currency_id: "R$",
                                                unit_price: parseFloat(data.valorAbsoluto),
                                            }
                                        ],
                                        payment_methods: {
                                            excluded_payment_types: [
                                                { id: "ticket" },
                                                { id: "credit_card" },
                                                { id: "digital_wallet" },
                                                { id: "digital_currency" },
                                            ],
                                            installments: 2
                                        },
                                        payer: {
                                            name: usuario.nome,
                                            surname: usuario.sobrenome,
                                            email: usuario.email,
                                        },
                                        auto_return: "all",
                                        external_reference: data.id,
                                        back_urls: {
                                            success: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                            pending: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                            failure: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                            // success: "http://localhost:3000/minhaInscricao/" + data.id,
                                            // pending: "http://localhost:3000/minhaInscricao/" + data.id,
                                            // failure: "http://localhost:3000/minhaInscricao/" + data.id,
                                        },
                                        notification_url: "https://us-central1-procompetidor-7867b.cloudfunctions.net/app/notificacao?source_news=ipn",
                                        statement_descriptor: "PRO COMPETIDOR",
                                        
                                    }
                                    const element = ref.current;
                                    geraPreferencia(dados, d.data().chavePagamento,element.value).then((doc) => {
                                        setUrl(doc.init_point);
                                        Swal.close();
                                    })
                                } else if (d.data().formaPagamento === 6) {
                                    const dados = {
                                        items: [
                                            {
                                                id: data.id,
                                                title: data.categoria.absoluto.absoluto.toUpperCase() + " - (" + data.evento.nome + ")",
                                                description: "Evento realizado pela equipe Procompetidor",
                                                picture_url: data.avatar,
                                                category_id: "electronics",
                                                quantity: 1,
                                                currency_id: "R$",
                                                unit_price: parseFloat(data.valorAbsoluto),
                                            }
                                        ],
                                        payment_methods: {
                                            excluded_payment_types: [
                                                { id: "credit_card" },
                                                { id: "digital_wallet" },
                                            ],
                                            installments: 2
                                        },
                                        payer: {
                                            name: usuario.nome,
                                            surname: usuario.sobrenome,
                                            email: usuario.email,
                                        },
                                        auto_return: "all",
                                        external_reference: data.id,
                                        back_urls: {
                                            success: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                            pending: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                            failure: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                            // success: "http://localhost:3000/minhaInscricao/" + data.id,
                                            // pending: "http://localhost:3000/minhaInscricao/" + data.id,
                                            // failure: "http://localhost:3000/minhaInscricao/" + data.id,
                                        },
                                        notification_url: "https://us-central1-procompetidor-7867b.cloudfunctions.net/app/notificacao?source_news=ipn",
                                        statement_descriptor: "PRO COMPETIDOR",
                                        
                                    }
                                    const element = ref.current;
                                    geraPreferencia(dados, d.data().chavePagamento,element.value).then((doc) => {
                                        setUrl(doc.init_point);
                                        Swal.close();
                                    })
                                } else {
                                    Swal.close();
                                }
                            })
                        })
                })
            } else if (idUser) {
                mensagemLoading('Carregando dados do pagamento..')
                servicos.usuarios.doc(idUser).get().then((doc) => {
                    setUsuario(doc.data())
                    servicos.inscricoes
                        .where('evento.id', '==', id)
                        .where('usuario.id', '==', idUser)
                        .get().then((snap) => {
                            const data = snap.docs.map((d) => d.data())[0]
                            setIncricao(data)
                            servicos.eventos.doc(id).get().then((d) => {
                                setEvento(d.data())
                                if (data.voucher.id) {
                                    if (data.voucher.tipo === "desconto") {
                                        data.valorTotal = (parseFloat(data.valorAbsoluto)) - ((parseFloat(data.valorAbsoluto)) * parseFloat(data.voucher.porcentagem) / 100)
                                    } else if (data.voucher.tipo === "valor") {
                                        data.valorTotal = (parseFloat(data.valorAbsoluto)) - parseFloat(data.voucher.valor)
                                    }
                                }
                                if (d.data().formaPagamento === 0) {
                                    const dados = {
                                        items: [
                                            {
                                                id: data.id,
                                                title: data.categoria.absoluto.absoluto.toUpperCase() + " - (" + data.evento.nome + ")",
                                                description: "Evento realizado pela equipe Procompetidor",
                                                picture_url: data.avatar,
                                                category_id: "electronics",
                                                quantity: 1,
                                                currency_id: "R$",
                                                unit_price: parseFloat(data.valorAbsoluto),
                                            }
                                        ],
                                        payment_methods: {
                                            excluded_payment_types: [
                                                { id: "digital_wallet" },
                                                { id: "digital_currency" },
                                            ],
                                            installments: 2
                                        },
                                        payer: {
                                            name: usuario.nome,
                                            surname: usuario.sobrenome,
                                            email: usuario.email,
                                        },
                                        auto_return: "all",
                                        external_reference: data.id,
                                        back_urls: {
                                            success: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                            pending: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                            failure: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                            // success: "http://localhost:3000/minhaInscricao/" + data.id,
                                            // pending: "http://localhost:3000/minhaInscricao/" + data.id,
                                            // failure: "http://localhost:3000/minhaInscricao/" + data.id,
                                        },
                                        notification_url: "https://us-central1-procompetidor-7867b.cloudfunctions.net/app/notificacao?source_news=ipn",
                                        statement_descriptor: "PRO COMPETIDOR",
                                        
                                    }
                                    const element = ref.current;
                                    geraPreferencia(dados, d.data().chavePagamento,element.value).then((doc) => {
                                        setUrl(doc.init_point);
                                        Swal.close();
                                    })
                                } else if (d.data().formaPagamento === 1 || d.data().formaPagamento === 7) {
                                    const dados = {
                                        items: [
                                            {
                                                id: data.id,
                                                title: data.categoria.absoluto.absoluto.toUpperCase() + " - (" + data.evento.nome + ")",
                                                description: "Evento realizado pela equipe Procompetidor",
                                                picture_url: data.avatar,
                                                category_id: "electronics",
                                                quantity: 1,
                                                currency_id: "R$",
                                                unit_price: parseFloat(data.valorAbsoluto),
                                            }
                                        ],
                                        payment_methods: {
                                            excluded_payment_types: [
                                                { id: "ticket" },
                                                { id: "digital_wallet" },
                                                { id: "digital_currency" },
                                            ],
                                            installments: 2
                                        },
                                        payer: {
                                            name: usuario.nome,
                                            surname: usuario.sobrenome,
                                            email: usuario.email,
                                        },
                                        auto_return: "all",
                                        external_reference: data.id,
                                        back_urls: {
                                            success: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                            pending: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                            failure: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                            // success: "http://localhost:3000/minhaInscricao/" + data.id,
                                            // pending: "http://localhost:3000/minhaInscricao/" + data.id,
                                            // failure: "http://localhost:3000/minhaInscricao/" + data.id,
                                        },
                                        notification_url: "https://us-central1-procompetidor-7867b.cloudfunctions.net/app/notificacao?source_news=ipn",
                                        statement_descriptor: "PRO COMPETIDOR",
                                        
                                    }
                                    const element = ref.current;
                                    geraPreferencia(dados, d.data().chavePagamento,element.value).then((doc) => {
                                        setUrl(doc.init_point);
                                        Swal.close();
                                    })
                                } else if (d.data().formaPagamento === 2) {
                                    const dados = {
                                        items: [
                                            {
                                                id: data.id,
                                                title: data.categoria.absoluto.absoluto.toUpperCase() + " - (" + data.evento.nome + ")",
                                                description: "Evento realizado pela equipe Procompetidor",
                                                picture_url: data.avatar,
                                                category_id: "electronics",
                                                quantity: 1,
                                                currency_id: "R$",
                                                unit_price: parseFloat(data.valorAbsoluto),
                                            }
                                        ],
                                        payment_methods: {
                                            excluded_payment_types: [
                                                { id: "credit_card" },
                                                { id: "digital_wallet" },
                                                { id: "digital_currency" },
                                            ],
                                            installments: 2
                                        },
                                        payer: {
                                            name: usuario.nome,
                                            surname: usuario.sobrenome,
                                            email: usuario.email,
                                        },
                                        auto_return: "all",
                                        external_reference: data.id,
                                        back_urls: {
                                            success: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                            pending: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                            failure: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                            // success: "http://localhost:3000/minhaInscricao/" + data.id,
                                            // pending: "http://localhost:3000/minhaInscricao/" + data.id,
                                            // failure: "http://localhost:3000/minhaInscricao/" + data.id,
                                        },
                                        notification_url: "https://us-central1-procompetidor-7867b.cloudfunctions.net/app/notificacao?source_news=ipn",
                                        statement_descriptor: "PRO COMPETIDOR",
                                        
                                    }
                                    const element = ref.current;
                                    geraPreferencia(dados, d.data().chavePagamento,element.value).then((doc) => {
                                        setUrl(doc.init_point);
                                        Swal.close();
                                    })
                                } else if (d.data().formaPagamento === 5 || d.data().formaPagamento === 3) {
                                    const dados = {
                                        items: [
                                            {
                                                id: data.id,
                                                title: data.categoria.absoluto.absoluto.toUpperCase() + " - (" + data.evento.nome + ")",
                                                description: "Evento realizado pela equipe Procompetidor",
                                                picture_url: data.avatar,
                                                category_id: "electronics",
                                                quantity: 1,
                                                currency_id: "R$",
                                                unit_price: parseFloat(data.valorTotal),
                                            }
                                        ],
                                        payment_methods: {
                                            excluded_payment_types: [
                                                { id: "ticket" },
                                                { id: "credit_card" },
                                                { id: "digital_wallet" },
                                                { id: "digital_currency" },
                                            ],
                                            installments: 2
                                        },
                                        payer: {
                                            name: usuario.nome,
                                            surname: usuario.sobrenome,
                                            email: usuario.email,
                                        },
                                        auto_return: "all",
                                        external_reference: data.id,
                                        back_urls: {
                                            success: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                            pending: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                            failure: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                            // success: "http://localhost:3000/minhaInscricao/" + data.id,
                                            // pending: "http://localhost:3000/minhaInscricao/" + data.id,
                                            // failure: "http://localhost:3000/minhaInscricao/" + data.id,
                                        },
                                        notification_url: "https://us-central1-procompetidor-7867b.cloudfunctions.net/app/notificacao?source_news=ipn",
                                        statement_descriptor: "PRO COMPETIDOR",
                                        
                                    }
                                    const element = ref.current;
                                    geraPreferencia(dados, d.data().chavePagamento,element.value).then((doc) => {
                                        setUrl(doc.init_point);
                                        Swal.close();
                                    })
                                } else if (d.data().formaPagamento === 6) {
                                    const dados = {
                                        items: [
                                            {
                                                id: data.id,
                                                title: data.categoria.absoluto.absoluto.toUpperCase() + " - (" + data.evento.nome + ")",
                                                description: "Evento realizado pela equipe Procompetidor",
                                                picture_url: data.avatar,
                                                category_id: "electronics",
                                                quantity: 1,
                                                currency_id: "R$",
                                                unit_price: parseFloat(data.valorAbsoluto),
                                            }
                                        ],
                                        payment_methods: {
                                            excluded_payment_types: [
                                                { id: "credit_card" },
                                                { id: "digital_wallet" },
                                            ],
                                            installments: 2
                                        },
                                        payer: {
                                            name: usuario.nome,
                                            surname: usuario.sobrenome,
                                            email: usuario.email,
                                        },
                                        auto_return: "all",
                                        external_reference: data.id,
                                        back_urls: {
                                            success: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                            pending: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                            failure: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                            // success: "http://localhost:3000/minhaInscricao/" + data.id,
                                            // pending: "http://localhost:3000/minhaInscricao/" + data.id,
                                            // failure: "http://localhost:3000/minhaInscricao/" + data.id,
                                        },
                                        notification_url: "https://us-central1-procompetidor-7867b.cloudfunctions.net/app/notificacao?source_news=ipn",
                                        statement_descriptor: "PRO COMPETIDOR",
                                        
                                    }
                                    const element = ref.current;
                                    geraPreferencia(dados, d.data().chavePagamento,element.value).then((doc) => {
                                        setUrl(doc.init_point);
                                        Swal.close();
                                    })
                                } else {
                                    Swal.close();
                                }
                            })
                        })
                })
            }
        })
    }, [])

    function pagamento() {
        // window.location = window.location.href.replace('/pagamento/' + id, '/pagamentoManual/' + id)
        window.location = window.location.href = `/pagamentoManual?e=${id}&i=${idInscricao}`;
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {

        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    return (
        <div className="body-wrapper blank-loder">
            <input ref={ref} type="hidden" id="deviceId"></input>
            <Header />
            <div class="section_fullwidth">
                <div class="headline headline_img">
                    <div class="headline__inner">
                        <div class="headline__title">
                            <h1>Formas </h1>
                            <h1>de Pagamento</h1>
                        </div>
                        <div class="headline__description">
                            <span>Para realizar sua inscrição, escolha as formas de pagamentos.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section_default section-single-class-layout clearfix">
                <main class="section_default">
                    <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
                        <div class="wpb_column vc_column_container vc_col-sm-12">
                            <div class="vc_column-inner ">
                                <div class="wpb_wrapper">
                                    <div class="wpb_text_column wpb_content_element ">
                                        <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                        </div>
                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                            <div class="vc_column-inner ">
                                                <div class="wpb_wrapper">
                                                    <div class="wpb_widgetised_column wpb_content_element">
                                                        <div class="wpb_wrapper">
                                                            <div role="form" class="row " >
                                                                <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix" style={{ marginTop: 20 }}>
                                                                    <figure class="wpb_wrapper vc_figure" style={{ width: '100%' }}>
                                                                        <div class="package-style-1__img">
                                                                            <img src={evento.avatar}
                                                                                alt="" title="price-img" /></div>
                                                                    </figure>
                                                                </div>
                                                                <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                                                    <h3 class="package-style-1__title" style={{ color: "#121212" }}>{evento.nome} </h3>
                                                                    {evento.inicioEvento === evento.fimEvento ?
                                                                        <div class="package-style-1__text"><i class="fa  fa-calendar" aria-hidden="true"
                                                                            style={{ color: "#c21111" }}></i> Dia {moment(evento.inicioEvento).format("DD/MM/YYYY")}</div>
                                                                        :
                                                                        <div class="package-style-1__text"><i class="fa  fa-calendar" aria-hidden="true"
                                                                            style={{ color: "#c21111" }}></i> De {moment(evento.inicioEvento).format("DD/MM/YYYY")} à {moment(evento.fimEvento).format("DD/MM/YYYY")}</div>
                                                                    }
                                                                    <div class="package-style-1__text"><i class="fa  fa-map-marker" aria-hidden="true"
                                                                        style={{ color: "#c21111" }}></i> {evento.endereco ? evento.endereco.cidade : null} / {evento.endereco ? evento.endereco.uf : null}</div>
                                                                    {/* {inscricao.id && inscricao.voucher.id ?
                                                                        <>
                                                                            {inscricao.voucher.tipo === "desconto" ?
                                                                                <div class="package-style-1__text" style={{ marginTop: 20, fontSize: 18, color: 'green' }}><i class="fa fa-money" aria-hidden="true"></i> <b>R$ {(parseFloat(inscricao.valorAbsoluto)) - ((parseFloat(inscricao.valorAbsoluto)) * parseFloat(inscricao.voucher.porcentagem) / 100)}</b></div>
                                                                                // <td><h6 style={{ color: "green" }}>R$ {(parseFloat(inscricao.valorAbsoluto)) - ((parseFloat(inscricao.valorAbsoluto)) * parseFloat(inscricao.voucher.porcentagem) / 100)}</h6></td>
                                                                                : inscricao.voucher.tipo === "valor" ?
                                                                                    <div class="package-style-1__text" style={{ marginTop: 20, fontSize: 18, color: 'green' }}><i class="fa fa-money" aria-hidden="true"></i> <b>R$ {(parseFloat(inscricao.valorAbsoluto)) - parseFloat(inscricao.voucher.valor)}</b></div>
                                                                                    // <td><h6 style={{ color: "green" }}>R$ {(parseFloat(inscricao.valorAbsoluto)) - parseFloat(inscricao.voucher.valor)}</h6></td>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </>
                                                                        :
            null                                                        } */}

     <td><b>R$ {inscricao.valorAbsoluto}</b></td>

                                                                </div>
                                                                <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                                                    <div class="package-style-1__img">
                                                                        <img src={usuario.avatar}
                                                                            alt="" title="price-img" /></div>
                                                                    <h3 class="package-style-1__title" style={{ color: "#121212" }}>{usuario.nome} {usuario.sobrenome}</h3>
                                                                    <div class="package-style-1__text">
                                                                        <div class="package-style-1__text"><i class="fa  fa-file" aria-hidden="true"
                                                                            style={{ color: "#c21111" }}></i> CPF {usuario.cpf} </div>
                                                                        <div class="package-style-1__text"><i class="fa  fa-user" aria-hidden="true"
                                                                            style={{ color: "#c21111" }}></i> {usuario.sexo}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="wpb_column vc_column_container vc_col-sm-8">
                                            <div class="vc_column-inner ">
                                                <div class="wpb_wrapper">
                                                    <div class="wpb_widgetised_column wpb_content_element">
                                                        <div class="wpb_wrapper">
                                                            <div role="form" class="row " >
                                                                <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix" style={{ marginTop: 20 }}>
                                                                    <h3>Categorias</h3>
                                                                    {inscricao.categoria ?
                                                                        <table style={{ width: "100%" }}>
                                                                            <thead>
                                                                                <tr >
                                                                                    <th class="schedule-table__day">Categoria</th>
                                                                                    <th class="schedule-table__day">Peso</th>
                                                                                    <th class="schedule-table__day">Faixa</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td style={{ height: 50 }}><h6>{inscricao.categoria.categoria.categoria}</h6></td>
                                                                                    <td><h6>{inscricao.categoria.peso.tipoPeso === 0 ? "Até " + inscricao.categoria.peso.peso + " Kg" : "Acima " + inscricao.categoria.peso.peso + " Kg"}</h6></td>
                                                                                    <td><h6>{inscricao.categoria.faixa.faixa}</h6></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        :
                                                                        null
                                                                    }
                                                                    {inscricao.categoria && inscricao.categoria.absoluto.id ?
                                                                        <table style={{ width: "100%", marginTop: 20 }}>
                                                                            <thead>
                                                                                <tr >
                                                                                    <th class="schedule-table__day">Absoluto</th>
                                                                                    <th class="schedule-table__day">Idade</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td style={{ height: 50 }}><h6>{inscricao.categoria.absoluto.absoluto}</h6></td>
                                                                                    <td><h6>{inscricao.categoria.categoria.tipoIdade === 0 ? "Até " + inscricao.categoria.categoria.idade + " anos" : inscricao.categoria.categoria.tipoIdade === 1 ? "De " + inscricao.categoria.categoria.minIdade + " até " + inscricao.categoria.categoria.maxIdade + " anos" : "Acima de " + inscricao.categoria.categoria.idade}</h6></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        :
                                                                        null
                                                                    }
                                                                    <table style={{ width: "100%", marginTop: 20 }}>
                                                                        <thead>
                                                                            <tr >
                                                                                <th class="schedule-table__day">Extrato</th>
                                                                            </tr>
                                                                        </thead>
                                                                    </table>
                                                                    <table style={{ width: "100%" }}>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td style={{ height: 50 }}>Valor Absoluto</td>
                                                                                <td><b>R$ {inscricao.pagamento ? inscricao.valorAbsoluto : null}</b></td>
                                                                            </tr>
                      {/* Obs: o trecho abaixo foi removido dia 31/03/2024 pois o disconto não se aplica a absoluto incluído. Se após varios anos ninguem reclamar, pode remover completamente*/}
                                                                            {/* {inscricao.voucher && inscricao.voucher.id ?
                                                                                <>
                                                                                    <tr>
                                                                                        <td style={{ height: 50 }}><h6>Valor Categoria</h6></td>
                                                                                        <td><h6>R$ {parseFloat(inscricao.valorAbsoluto)}</h6></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td style={{ height: 50 }}><h6 >Voucher de {inscricao.voucher.tipo} aplicado</h6></td>
                                                                                        {inscricao.voucher.tipo === "desconto" ?
                                                                                            <td><h6>{inscricao.voucher.porcentagem} %</h6></td>
                                                                                            : inscricao.voucher.tipo === "valor" ?
                                                                                                <td><h6>-R$ {inscricao.voucher.valor}</h6></td>
                                                                                                :
                                                                                                <td><h6>GRÁTIS</h6></td>
                                                                                        }
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td style={{ height: 50 }}><h6 style={{ color: "green" }}>Valor Total com desconto</h6></td>
                                                                                        {inscricao.voucher.tipo === "desconto" ?
                                                                                            <td><h6 style={{ color: "green" }}>R$ {(parseFloat(inscricao.valorAbsoluto)) - ((parseFloat(inscricao.valorAbsoluto)) * parseFloat(inscricao.voucher.porcentagem) / 100)}</h6></td>
                                                                                            : inscricao.voucher.tipo === "valor" ?
                                                                                                <td><h6 style={{ color: "green" }}>R$ {(parseFloat(inscricao.valorAbsoluto)) - parseFloat(inscricao.voucher.valor)}</h6></td>
                                                                                                :
                                                                                                <td><h6 style={{ color: "green" }}>INSCRIÇÃO GRATUITA</h6></td>
                                                                                        }
                                                                                    </tr>
                                                                                </>
                                                                                :
                                                                                <tr>
                                                                                    <td style={{ height: 50 }}><h6>Valor Total</h6></td>
                                                                                    <td><h6>R$ {inscricao.valorAbsoluto ? inscricao.valorAbsoluto : null}</h6></td>
                                                                                </tr>
                                                                            } */}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                {/* <div>
                                                                    <h1>Importante</h1>
                                                                    <h3>Pagamentos via Boleto</h3>
                                                                    <p>Após gerar o boleto de pagamento , clique no botão de <b>"Voltar para o site"</b> ou aguarde<br /> 5 segundos para ser redirecionado a nossa<br /> tela de configuração na tela do<br /> <b>MERCADO PAGO</b> para gerar suas<br /> credenciais de pagamento no <b>Procompetidor.</b></p>
                                                                    <p>Aguarde a mensagem <b>"Aguarde.. Verificando Pagamento"</b> finalizar para confirmar suas<br /> credenciais  </p>
                                                                    <h3>Pagamentos via PIX</h3>
                                                                    <p>Após gerar o código PIX de pagamento ,<br /> clique no botão de <b>"Voltar para o site"</b> ser redirecionado a nossa tela de configuração na tela do <b>MERCADO PAGO</b> para gerar suas<br /> credenciais de pagamento no <b>Procompetidor.</b></p>
                                                                    <p>Aguarde a mensagem <b>"Aguarde.. Verificando Pagamento"</b> finalizar para confirmar suas<br /> credenciais  </p>
                                                                    <h3>Pagamentos via Cartão</h3>
                                                                    <p>Após confirmar o pagamento ,<br /> você ser redirecionado após 5 segundos a nossa tela de configuração para gerar suas<br /> credenciais de pagamento no <b>Procompetidor.</b></p>
                                                                    <p>Aguarde a mensagem <b>"Aguarde.. Finalizando Inscrição"</b> finalizar para confirmar suas<br /> credenciais  </p>
                                                                </div> */}
                                                                {url ?
                                                                    <>
                                                                        {evento && evento.id && evento.formaPagamento === 0 || evento.formaPagamento === 7 || evento.formaPagamento === 5 ?
                                                                            <>
                                                                                <a href={url} style={{ width: "100%", marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill " >Pagamento Online</a>
                                                                                <a href="javascript:void(0)" onClick={() => pagamento()} style={{ width: "100%", marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill " >Pagamento direto ao Administrador</a>
                                                                            </>
                                                                            :
                                                                            <a href={url} style={{ width: "100%", marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill " >Pagamento Online</a>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {evento && evento.id && evento.formaPagamento === 3 || evento.formaPagamento === 4 || evento.formaPagamento === 5 || evento.formaPagamento === 7 ?
                                                                            <a href="javascript:void(0)" onClick={() => pagamento()} style={{ width: "100%", marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill " >Pagamento direto ao Administrador</a>
                                                                            :
                                                                            null
                                                                        }
                                                                    </>
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>
            </div>
            <Footer />
        </div >
    );
}
