import { useState, useEffect } from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import firebase from 'firebase'

const mediaQuery = window.matchMedia('(max-width: 450px)');

export default function Federacoes() {
    // const queryParams = new URLSearchParams(window.location.search);
    // const [type] = useState(queryParams.get('p'))
    const [servicos] = useState({
        usuarios: firebase.firestore().collection("usuarios"),
        eventos: firebase.firestore().collection("eventos"),
        federacoes: firebase.firestore().collection("federacoes"),
        auth: firebase.auth(),
        configuracoes: firebase.firestore().collection('configuracoes')
    })
    const [loading, setLoading] = useState(true)
    const [federacoes, setFederacoes] = useState([])
    const [minhasFiliacoes, setMinhasFiliacoes] = useState([])
    const [tipo, setTipo] = useState("Jiu Jitsu")

    const [usuario, setUsuario] = useState({})
    const [modalidades, setModalidades] = useState([])


    useEffect(() => {

        servicos.auth.onAuthStateChanged(async (user) => {
            let idUser = JSON.parse(localStorage.getItem('usuarioId'))

            if (user || idUser) {

                servicos.usuarios.doc(user?.uid ? user.uid : idUser).get().then((snap) => {
                    setUsuario(snap.data())

                    if (snap.data()?.filiacoes?.length > 0) {

                    }
                })

            }

        })

        // carregar Federação, Modalidade e Graduações

        servicos.configuracoes.get().then(snap => {
            const idConfiguracoes = snap.docs.map(d => d.id)[0];

            servicos.configuracoes.doc(idConfiguracoes).collection('modalidades').get().then(snap => {
                const data = snap.docs.map(d => d.data())
                setModalidades(data)
            })

        })


        servicos.federacoes.where('status', '==', 1).get().then(snap => {
            if (snap.size > 0) {
                let data = snap.docs.map(d => d.data());

                setFederacoes(data);
                setLoading(false);
            }
        })



        setLoading(false);

    }, [])

    useEffect(() => {
        if (usuario.id && usuario?.filiacoes?.length > 0) {
            setMinhasFiliacoes(federacoes.filter(federacao => usuario.filiacoes.includes(federacao.id)))
        }
    }, [usuario])


    return (
        <div className="body-wrapper blank-loder">
            <Header />
            <div class="section_fullwidth">
                <div class="headline headline_img">
                    <div class="headline__inner">
                        <div class="headline__title">
                            <h1>Federações & Agremiações</h1>
                        </div>
                        <div class="headline__description">
                            <span>Procure pela Federação / Agremiação que deseja se filiar</span>

                        </div>
                    </div>
                </div>
            </div>

            <main className="section_default">

                {/* Minhas Filiações - Início */}
                {minhasFiliacoes.length > 0 && (
                    <div className="vc_row wpb_row vc_row-fluid vc_column-gap-30">
                        <div className="wpb_column vc_column_container vc_col-sm-4 vc_col-lg-offset-0 vc_col-lg-12 vc_col-md-offset-2 vc_col-md-12 vc_col-sm-offset-2 vc_col-xs-12">
                            <div className="vc_column-inner ">
                                <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper" style={{ marginTop: "10px" }}>
                                            <h3 className='title-section_with-sep' style={{ fontWeight: "bold" }}>Minhas Filiações</h3>
                                        </div>
                                    </div>
                                    <div className="vc_empty_space" style={{ height: 24 }}>
                                        <span className="vc_empty_space_inner"></span>
                                    </div>
                                    <div className="row" id='card-mini'>
                                        {minhasFiliacoes.map((federacao) =>
                                            <>
                                                <a style={{ cursor: 'pointer' }} href={`filiacao/${federacao.id}`} onClick={() => 'detalheEvento(evento.id)'}>

                                                    <div key={federacao.id} className="ratings-widget__item  vc_col-lg-4">
                                                        <div className="ratings-widget__img js-hover-veil"><img
                                                            src={federacao.urlImagemFederacao}
                                                            alt="" title="image here (2)" />
                                                        </div>

                                                        <div className="ratings-widget__details ">
                                                            <div className="ratings-widget__details-title" title={federacao.nome}><i className="fa  fa-shield" aria-hidden="true"> </i>
                                                                {mediaQuery.matches ?
                                                                    ` ${federacao.nome}`
                                                                    :
                                                                    ` ${federacao.nome.substr(0, 45)} ${federacao.nome.substr(0, 45).length < 45 ? '' : '...'}`
                                                                }


                                                            </div>
                                                            <div className="ratings-widget__details-cat"><i className="fa  fa-bookmark" aria-hidden="true"
                                                            ></i> {`${modalidades.find(md => md.id === federacao.modalidade)?.descricao}`}</div>
                                                            <div className="ratings-widget__details-cat"><i className="fa  fa-map-marker" aria-hidden="true"
                                                            
                                                            > </i>
                                                                {` ${federacao.cidade} / ${federacao.uf}`.substr(0, 20)}{`${federacao.cidade} / ${federacao.uf}`.substr(0, 18).length < 18 ? '' : '...'}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* Minhas Filiações - Fim */}

                <div className="vc_row wpb_row vc_row-fluid">
                    <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                                <div className="js-carusel-wrapp clearfix">
                                    <div className="carusel-empty-space" style={{ height: 24 }}></div>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper" style={{ marginTop: "10px" }}>
                                            <h3 className='title-section_with-sep' style={{ fontWeight: "bold", marginBottom: '40px' }}>Federações & Agremiações</h3>
                                        </div>
                                    </div>
                                    {federacoes.length > 0 ?
                                        <div className="pnchrOwlCarousel_5ff0a67aea27d  row">
                                            {federacoes.map((federacao) =>
                                                <div id='card-destaque' key={federacao.id} className="vc_col-sm-3 ">
                                                    <a
                                                        href='#'

                                                        onClick={() => {
                                                            if (usuario.id) {
                                                                window.location = `/filiacao/${federacao.id}`
                                                            } else {
                                                                window.location = `/verificaPerfil?ref=filiacao&idref=${federacao.id}`
                                                            }
                                                        }}

                                                    >

                                                        <div className="vizit-cart-item" style={{ height: '380px' }}>
                                                            <div className="vizit-cart-item__img">
                                                                <div className="vizit-cart-item__img-overlay"></div>
                                                                <div className="vizit-cart-item__img-overlay-share">
                                                                    <p style={{ color: "#ffffff" }}>Clique aqui para saber mais sobre esta federação/agremiação</p>
                                                                </div><img
                                                                    src={federacao.urlImagemFederacao}
                                                                    alt="" title="image-here" />
                                                            </div>

                                                            <div className="vizit-cart-item__descr">
                                                                <div
                                                                    title={federacao.nome} className="vizit-cart-item__title" style={{ color: "#121212" }}>
                                                                    <i className="fa  fa-shield" aria-hidden="true"></i>
                                                                    {mediaQuery.matches ?
                                                                        federacao.nome
                                                                        :
                                                                        `${federacao.nome.substr(0, 32)}${federacao.nome.substr(0, 32).length < 32 ? '' : '...'}`
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                        :
                                        <>
                                            {loading ?
                                                <h2><i className="fa  fa-spinner" aria-hidden="true"
                                                    style={{ color: "#c21111" }}></i> Aguarde... Carregando Federações e Agremiações </h2>
                                                :
                                                <h2>Sem federações disponíveis no momento </h2>
                                            }

                                        </>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
}
